@charset "UTF-8";
/* ==========================================================================
 *
 * Utility: Extend - layout
 *
mediaの外で作る必要がある
.base{
	@extend %center
}
========================================================================== */
/*
 * RESET
*/
body {
  margin: 0;
  padding: 0;
  font-size: 62.5%; }

div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, blockquote, pre, table, caption, th, td, address, form, fieldset, legend, object, header, footer, section, article, nav, figure, canvas {
  margin: 0;
  padding: 0; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

fieldset, img {
  border: none;
  vertical-align: top; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

ol, ul, li {
  list-style: none; }

caption, th, td {
  vertical-align: top;
  text-align: left; }

h1, h2, h3, h4, h5, h6, small {
  font-weight: normal;
  font-size: 100%; }

i {
  font-style: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: none; }

sup {
  vertical-align: top; }

sub {
  vertical-align: baseline; }

a, object, embed {
  outline: none; }

a:hover, a:active, a:focus {
  outline: none; }

header, footer, section, article, nav, figure, canvas, main {
  display: block; }

html {
  background: #F7F2EC; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-family: "Noto Sans JP", -apple-system, "Segoe UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", メイリオ, meiryo, sans-serif;
  font-weight: bold; }

main {
  position: relative;
  width: 100%; }

*, *::before, *::after {
  box-sizing: border-box; }

.will-change {
  will-change: transform; }

a {
  text-decoration: none;
  color: inherit; }

.-font {
  font-family: 'Inter', 'Noto Sans JP',sans-serif; }

.-ttl {
  font-family: 'myfont', sans-serif; }

@font-face {
  font-family: 'myfont';
  src: url("../font/font.woff") format("woff"), url("../font/font.otf") format("truetype"); }

.prlx {
  transform: translate(0, 2vw);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.2s linear; }
  .prlx.footer-content {
    transition: all 0.8s 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.1s 0.6s linear;
    transform: translate(0, 10vh); }
  .prlx.header {
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s linear;
    transform: none !important;
    opacity: 0; }
  .prlx.-show {
    transform: translate(0, 0);
    opacity: 1; }

#container:after {
  content: '';
  display: block;
  width: 100%;
  height: 20vw;
  background: #F7F2EC;
  position: absolute;
  bottom: 0vw;
  left: 0;
  z-index: -1; }

.prlxf {
  display: inline-block;
  overflow-x: visible;
  overflow-y: hidden;
  line-height: 1; }
  .prlxf span {
    opacity: 1;
    display: inline-block;
    transform: translateY(100%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); }
  .prlxf.-show span {
    opacity: 1;
    transform: translateX(-0.2vw); }

/*
LANGUAGE or DEVICE
*/
.lang-jp [data-lang="en"] {
  display: none !important; }

.lang-en [data-lang="jp"] {
  display: none !important; }

@media (min-width: 751px) {
  [data-device="sp"] {
    display: none !important; } }

@media (max-width: 750px) {
  [data-device="pc"] {
    display: none !important; } }

/*
@keyframes xxx {
  0%   {}

  50%  {}

  100% {}
  AAA
}
*/
@keyframes circle {
  0% {
    stroke-dasharray: 0 366; }
  99.9%, to {
    stroke-dasharray: 366 366; } }

@keyframes bgloop {
  0% {
    background-position: 0 bottom; }
  100% {
    background-position: 1000% bottom; } }

@keyframes lineanim0 {
  0% {
    top: 63%; }
  100% {
    top: 100%; } }

@keyframes lineanim1 {
  0% {
    top: 96.3%; }
  9.99999% {
    top: 100%; }
  10% {
    top: 63%; }
  100% {
    top: 96.3%; } }

@keyframes lineanim2 {
  0% {
    top: 92.6%; }
  19.99999% {
    top: 100%; }
  20% {
    top: 63%; }
  100% {
    top: 92.6%; } }

@keyframes lineanim3 {
  0% {
    top: 88.9%; }
  29.99999% {
    top: 100%; }
  30% {
    top: 63%; }
  100% {
    top: 88.9%; } }

@keyframes lineanim4 {
  0% {
    top: 85.2%; }
  39.99999% {
    top: 100%; }
  40% {
    top: 63%; }
  100% {
    top: 85.2%; } }

@keyframes lineanim5 {
  0% {
    top: 81.5%; }
  49.99999% {
    top: 100%; }
  50% {
    top: 63%; }
  100% {
    top: 81.5%; } }

@keyframes lineanim6 {
  0% {
    top: 77.8%; }
  59.99999% {
    top: 100%; }
  60% {
    top: 63%; }
  100% {
    top: 77.8%; } }

@keyframes lineanim7 {
  0% {
    top: 74.1%; }
  69.99999% {
    top: 100%; }
  70% {
    top: 63%; }
  100% {
    top: 74.1%; } }

@keyframes lineanim8 {
  0% {
    top: 70.4%; }
  79.99999% {
    top: 100%; }
  80% {
    top: 63%; }
  100% {
    top: 70.4%; } }

@keyframes lineanim9 {
  0% {
    top: 66.7%; }
  89.99999% {
    top: 100%; }
  90% {
    top: 63%; }
  100% {
    top: 66.7%; } }

@keyframes lineanim_sp1 {
  0% {
    transform: translateY(-35.5vw); }
  100% {
    transform: translateY(0); } }

@keyframes lineanim_sp2 {
  0% {
    transform: translateY(-3.55vw); }
  90% {
    transform: translateY(-35.5vw); }
  90.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-3.55vw); } }

@keyframes lineanim_sp3 {
  0% {
    transform: translateY(-7.1vw); }
  80% {
    transform: translateY(-35.5vw); }
  80.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-7.1vw); } }

@keyframes lineanim_sp4 {
  0% {
    transform: translateY(-10.65vw); }
  70% {
    transform: translateY(-35.5vw); }
  70.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-10.65vw); } }

@keyframes lineanim_sp5 {
  0% {
    transform: translateY(-14.2vw); }
  60% {
    transform: translateY(-35.5vw); }
  60.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-14.2vw); } }

@keyframes lineanim_sp6 {
  0% {
    transform: translateY(-17.75vw); }
  50% {
    transform: translateY(-35.5vw); }
  50.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-17.75vw); } }

@keyframes lineanim_sp7 {
  0% {
    transform: translateY(-21.3vw); }
  40% {
    transform: translateY(-35.5vw); }
  40.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-21.3vw); } }

@keyframes lineanim_sp8 {
  0% {
    transform: translateY(-24.85vw); }
  30% {
    transform: translateY(-35.5vw); }
  30.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-24.85vw); } }

@keyframes lineanim_sp9 {
  0% {
    transform: translateY(-28.4vw); }
  20% {
    transform: translateY(-35.5vw); }
  20.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-28.4vw); } }

@keyframes lineanim_sp10 {
  0% {
    transform: translateY(-31.95vw); }
  10% {
    transform: translateY(-35.5vw); }
  10.0001% {
    transform: translateY(0); }
  100% {
    transform: translateY(-31.95vw); } }

@keyframes cloudanim {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-100vw); }
  50.001% {
    transform: translateX(100vw); }
  100% {
    transform: translateX(0); } }

@keyframes sunanim {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bgloop {
  0% {
    background-position: 0 bottom; }
  100% {
    background-position: 1000% bottom; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes simplebeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@media (min-width: 751px) {
  .about-top {
    padding-top: 12.63889vw;
    text-align: center;
    padding-bottom: 6.25vw;
    position: relative;
    height: 100vh;
    background: url(../img/about-top-bg.png) bottom center/cover;
    margin-bottom: 4.30556vw; }
    .about-top h1 {
      margin-bottom: -1.38889vw;
      position: relative;
      z-index: 1;
      font-size: 11.52778vw;
      line-height: 0.8; }
      .about-top h1 img {
        width: 33.75vw; }
    .about-top p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.11111vw;
      z-index: 1; }
      .about-top p:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-top:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: url(../img/about-top.png) bottom center/cover;
      animation: bgloop 160s linear infinite; } }

@media (max-width: 750px) {
  .about-top {
    padding-top: 60vw;
    text-align: center;
    padding-bottom: 6.25vw;
    position: relative;
    height: 153.84615vw;
    background: url(../img/about-top-bg.png) bottom center/cover;
    margin-bottom: 5.12821vw; }
    .about-top h1 {
      margin-bottom: -2.5641vw;
      position: relative;
      z-index: 1;
      font-size: 21.02564vw;
      line-height: 0.8; }
      .about-top h1 img {
        width: 61.79487vw; }
    .about-top p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5641vw;
      position: relative;
      z-index: 1; }
      .about-top p:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-top:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: url(../img/about-top.png) bottom center/cover;
      animation: bgloop 160s linear infinite;
      transform: translateZ(0); } }

@media (min-width: 751px) {
  .about-vision {
    width: 94.44444vw;
    margin: 0 auto;
    margin-bottom: 12.77778vw;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .about-vision .about-vision-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 2.77778vw; }
      .about-vision .about-vision-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-vision .about-vision-content {
      display: flex; }
      .about-vision .about-vision-content .about-vision-content-ttl {
        width: 47.43056vw; }
        .about-vision .about-vision-content .about-vision-content-ttl h1 {
          font-size: 0vw;
          margin-left: 8.81944vw; }
          .about-vision .about-vision-content .about-vision-content-ttl h1 span {
            font-size: 5.27778vw;
            line-height: 1.1; }
      .about-vision .about-vision-content .about-vision-content-text p {
        font-size: 0.97222vw;
        line-height: 2;
        width: 32.77778vw; } }

@media (max-width: 750px) {
  .about-vision {
    width: 91.79487vw;
    margin: 0 auto;
    margin-bottom: 17.94872vw;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB; }
    .about-vision .about-vision-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-bottom: 5.12821vw; }
      .about-vision .about-vision-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-vision .about-vision-content .about-vision-content-ttl h1 {
      font-size: 0vw;
      white-space: nowrap;
      margin-bottom: 19.48718vw; }
      .about-vision .about-vision-content .about-vision-content-ttl h1 span {
        line-height: 1.1;
        font-size: 13.33333vw; }
    .about-vision .about-vision-content .about-vision-content-text p {
      font-size: 3.07692vw;
      line-height: 5.64103vw; } }

@media (min-width: 751px) {
  .about-mind {
    width: 94.44444vw;
    margin: 0 auto;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB;
    position: relative; }
    .about-mind .about-mind-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 2.77778vw; }
      .about-mind .about-mind-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-mind .about-mind-container {
      display: flex; }
    .about-mind .about-mind-content {
      width: 34.79167vw;
      margin-left: 9.93056vw; }
      .about-mind .about-mind-content h1 {
        margin-bottom: 0.83333vw;
        position: relative; }
        .about-mind .about-mind-content h1 span.-no {
          opacity: 0.3;
          position: absolute;
          top: -1.25vw;
          left: -1.52778vw;
          font-size: 1.11111vw; }
      .about-mind .about-mind-content h4 {
        font-size: 1.66667vw;
        line-height: 2; }
      .about-mind .about-mind-content p {
        font-size: 1.04167vw;
        line-height: 2; }
    .about-mind #stage {
      margin-top: 35vh;
      margin-bottom: 0vh; }
      .about-mind #stage canvas {
        position: absolute;
        top: 0;
        transform: translate(0, calc(-50% + 50vh)); }
      .about-mind #stage ul {
        position: sticky;
        top: 50%;
        margin-left: 48.40278vw;
        transform: translate(0, -50%); }
        .about-mind #stage ul li {
          width: 0.55556vw;
          height: 0.55556vw;
          opacity: 0.2;
          background: #000;
          display: block;
          margin-bottom: 1.38889vw;
          border-radius: 50%; }
          .about-mind #stage ul li img {
            opacity: 0;
            position: relative;
            top: -0.06944vw;
            left: -0.13889vw;
            width: 0.83333vw; }
          .about-mind #stage ul li.-current {
            opacity: 1; }
            .about-mind #stage ul li.-current img {
              opacity: 1; }
    .about-mind .about-mind-1 {
      height: 100vh;
      display: flex;
      align-items: center; }
      .about-mind .about-mind-1 .about-mind-content h1 {
        font-size: 6.38889vw;
        line-height: 0.5; }
        .about-mind .about-mind-1 .about-mind-content h1 img {
          width: 15.27778vw; }
    .about-mind .about-mind-2 {
      height: 100vh;
      display: flex;
      align-items: center; }
      .about-mind .about-mind-2 .about-mind-content h1 {
        font-size: 6.38889vw;
        line-height: 0.5; }
        .about-mind .about-mind-2 .about-mind-content h1 img {
          width: 19.02778vw; }
    .about-mind .about-mind-3 {
      height: 100vh;
      display: flex;
      align-items: center; }
      .about-mind .about-mind-3 .about-mind-content h1 {
        font-size: 6.38889vw;
        line-height: 0.5; }
        .about-mind .about-mind-3 .about-mind-content h1 img {
          width: 16.66667vw; }
    .about-mind .about-mind-4 {
      height: 100vh;
      display: flex;
      align-items: center; }
      .about-mind .about-mind-4 .about-mind-content h1 {
        font-size: 6.38889vw;
        line-height: 0.5; }
        .about-mind .about-mind-4 .about-mind-content h1 img {
          width: 16.875vw; } }

@media (max-width: 750px) {
  .about-mind {
    width: 91.79487vw;
    margin: 0 auto;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB;
    position: relative; }
    .about-mind .about-mind-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-left: 2.5641vw;
      margin-bottom: 25.64103vw; }
      .about-mind .about-mind-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-mind .about-mind-content {
      width: 91.79487vw;
      margin-left: 5.12821vw; }
      .about-mind .about-mind-content h1 {
        margin-bottom: 3.58974vw;
        position: relative; }
        .about-mind .about-mind-content h1 span.-no {
          opacity: 0.3;
          position: absolute;
          top: -4.10256vw;
          left: -4.10256vw;
          font-size: 2.5641vw; }
        .about-mind .about-mind-content h1 span {
          margin-bottom: -4.10256vw; }
      .about-mind .about-mind-content h4 {
        font-size: 6.15385vw;
        line-height: 2;
        margin-bottom: 1.28205vw; }
      .about-mind .about-mind-content p {
        font-size: 3.07692vw;
        line-height: 2;
        width: 76.92308vw; }
    .about-mind #stage {
      position: absolute;
      height: calc(100%);
      top: 0; }
      .about-mind #stage canvas {
        position: sticky;
        top: 100vw; }
      .about-mind #stage ul {
        position: sticky;
        top: 50%;
        margin-left: 87.17949vw;
        transform: translate(0, -50%);
        margin-bottom: 51.28205vw; }
        .about-mind #stage ul li {
          width: 2.05128vw;
          height: 2.05128vw;
          opacity: 0.2;
          background: #000;
          display: block;
          margin-bottom: 5.12821vw;
          border-radius: 50%; }
          .about-mind #stage ul li img {
            opacity: 0;
            position: relative;
            top: -0.25641vw;
            left: -0.51282vw;
            width: 3.07692vw; }
          .about-mind #stage ul li.-current {
            opacity: 1; }
            .about-mind #stage ul li.-current img {
              opacity: 1; }
    .about-mind .about-mind-container-data {
      position: relative;
      z-index: 1; }
    .about-mind .about-mind-1 {
      height: 100vh;
      display: flex; }
      .about-mind .about-mind-1 .about-mind-content h1 {
        font-size: 13.84615vw;
        line-height: 0.9; }
        .about-mind .about-mind-1 .about-mind-content h1 img {
          width: 33.07692vw; }
      .about-mind .about-mind-1 .about-mind-content h4 {
        font-size: 3.07692vw;
        line-height: 2; }
    .about-mind .about-mind-2 {
      height: 100vh;
      display: flex; }
      .about-mind .about-mind-2 .about-mind-content h1 {
        font-size: 13.84615vw;
        line-height: 0.9; }
        .about-mind .about-mind-2 .about-mind-content h1 img {
          width: 68.97436vw; }
    .about-mind .about-mind-3 {
      height: 100vh;
      display: flex; }
      .about-mind .about-mind-3 .about-mind-content h1 {
        font-size: 13.84615vw;
        line-height: 0.9; }
        .about-mind .about-mind-3 .about-mind-content h1 img {
          width: 65.64103vw; }
    .about-mind .about-mind-4 {
      height: 100vh;
      display: flex; }
      .about-mind .about-mind-4 .about-mind-content h1 {
        font-size: 13.84615vw;
        line-height: 0.9; }
        .about-mind .about-mind-4 .about-mind-content h1 img {
          width: 63.33333vw; } }

@media (min-width: 751px) {
  .about-service {
    width: 94.44444vw;
    margin: 0 auto;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .about-service .about-service-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 1.38889vw; }
      .about-service .about-service-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-service .about-service-head {
      display: flex;
      margin-bottom: 5.27778vw; }
      .about-service .about-service-head h1 {
        width: 47.63889vw;
        font-size: 7.36111vw;
        line-height: 0.8; }
        .about-service .about-service-head h1 img {
          width: 26.52778vw; }
      .about-service .about-service-head p {
        font-size: 1.04167vw;
        line-height: 2;
        width: 40.20833vw; }
    .about-service .about-service-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 19.16667vw; }
      .about-service .about-service-list .about-service-card {
        display: flex;
        flex-flow: column;
        background: #000;
        border-radius: 2.08333vw;
        width: 31.38889vw;
        height: 46.38889vw;
        padding: 1.94444vw;
        color: #fff;
        cursor: pointer; }
        .about-service .about-service-list .about-service-card h6 {
          text-align: right;
          font-size: 1.11111vw;
          margin-bottom: 5.41667vw;
          opacity: 0.8;
          transition: all 0.1s linear; }
        .about-service .about-service-list .about-service-card h2 {
          font-size: 3.88889vw;
          line-height: 1;
          margin-bottom: 0.69444vw;
          opacity: 0.8;
          transition: all 0.1s linear; }
          .about-service .about-service-list .about-service-card h2 img {
            height: 7.63889vw; }
        .about-service .about-service-list .about-service-card h5 {
          font-size: 0.97222vw;
          line-height: 1.4;
          flex-grow: 1;
          opacity: 0.8;
          transition: all 0.1s linear; }
        .about-service .about-service-list .about-service-card p {
          font-size: 0.97222vw;
          line-height: 2;
          margin-bottom: 2.22222vw;
          opacity: 0.8;
          transition: all 0.1s linear; }
        .about-service .about-service-list .about-service-card div {
          border-top: 1px solid rgba(255, 255, 255, 0.8);
          padding-top: 0.69444vw;
          text-align: right;
          display: flex;
          justify-content: right;
          align-items: center;
          font-size: 1.11111vw;
          opacity: 0.8;
          transition: all 0.1s linear; }
          .about-service .about-service-list .about-service-card div:after {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-left: 0.97222vw; }
        .about-service .about-service-list .about-service-card:hover * {
          opacity: 1; } }

@media (max-width: 750px) {
  .about-service {
    width: 91.79487vw;
    margin: 0 auto;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB; }
    .about-service .about-service-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-left: 2.5641vw;
      margin-bottom: 5.12821vw; }
      .about-service .about-service-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-service .about-service-head h1 {
      width: 64.61538vw;
      font-size: 16.92308vw;
      line-height: 0.8;
      margin-bottom: 3.07692vw; }
      .about-service .about-service-head h1 img {
        width: 64.61538vw; }
    .about-service .about-service-head p {
      font-size: 3.07692vw;
      line-height: 5.64103vw;
      width: 85.12821vw;
      margin-bottom: 7.69231vw; }
    .about-service .about-service-list {
      margin-bottom: 35.12821vw; }
      .about-service .about-service-list .about-service-card {
        display: flex;
        flex-flow: column;
        background: #000;
        border-radius: 7.69231vw;
        width: 91.79487vw;
        padding: 6.41026vw 5.12821vw;
        margin-bottom: 4.10256vw;
        color: #fff; }
        .about-service .about-service-list .about-service-card h6 {
          text-align: right;
          font-size: 2.82051vw;
          margin-bottom: 14.61538vw;
          opacity: 0.8; }
        .about-service .about-service-list .about-service-card h2 {
          font-size: 11.79487vw;
          line-height: 0.9;
          margin-bottom: 3.84615vw;
          opacity: 0.8; }
          .about-service .about-service-list .about-service-card h2 img {
            height: 19.74359vw; }
          .about-service .about-service-list .about-service-card h2 span {
            margin-bottom: -2.5641vw; }
        .about-service .about-service-list .about-service-card h5 {
          font-size: 3.07692vw;
          line-height: 1.4;
          margin-bottom: 5.12821vw;
          opacity: 0.8; }
        .about-service .about-service-list .about-service-card p.about-service-card-text {
          font-size: 2.82051vw;
          line-height: 5.38462vw;
          margin-bottom: 3.33333vw;
          opacity: 0.8;
          display: none; }
        .about-service .about-service-list .about-service-card p.about-service-card-btn {
          border-top: 1px solid white;
          text-align: center;
          font-size: 3.58974vw;
          line-height: 5.38462vw;
          padding-top: 2.30769vw;
          margin-bottom: 2.82051vw;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center; }
          .about-service .about-service-list .about-service-card p.about-service-card-btn img {
            margin-right: 2.05128vw;
            width: 2.05128vw; }
        .about-service .about-service-list .about-service-card div {
          border-top: 1px solid white;
          opacity: 0.8;
          padding-top: 3.58974vw;
          text-align: right;
          display: flex;
          justify-content: right;
          align-items: center;
          font-size: 3.58974vw; }
          .about-service .about-service-list .about-service-card div:after {
            content: '';
            width: 2.05128vw;
            height: 2.05128vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-left: 2.05128vw; } }

@media (min-width: 751px) {
  .about-project {
    width: 94.44444vw;
    margin: 0 auto 20.20833vw;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .about-project .about-project-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 1.38889vw; }
      .about-project .about-project-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-project .about-project-head {
      display: flex;
      margin-bottom: 4.30556vw; }
      .about-project .about-project-head h1 {
        width: 47.63889vw;
        font-size: 7.36111vw;
        line-height: 0.8; }
        .about-project .about-project-head h1 img {
          width: 25.625vw; }
      .about-project .about-project-head p {
        font-size: 1.04167vw;
        line-height: 2;
        width: 40.20833vw; }
    .about-project .about-project-card {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 2.08333vw;
      overflow: hidden;
      margin-bottom: 2.08333vw;
      position: relative;
      border-radius: 2.08333vw;
      overflow: hidden; }
      .about-project .about-project-card:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        transition: 0.2s ease-out all;
        border: 0vw solid #000;
        border-radius: 2.08333vw; }
      .about-project .about-project-card:hover:after {
        border: 0.27778vw solid #B1AAA2; }
      .about-project .about-project-card:hover .about-project-card-img img {
        transform: scale(1.05); }
      .about-project .about-project-card .about-project-card-img {
        overflow: hidden; }
        .about-project .about-project-card .about-project-card-img img {
          transition: 0.2s all ease-out;
          width: 44.44444vw; }
      .about-project .about-project-card .about-project-card-data {
        margin-left: 3.33333vw; }
        .about-project .about-project-card .about-project-card-data h2 {
          font-size: 2.91667vw;
          margin-bottom: 0.34722vw; }
          .about-project .about-project-card .about-project-card-data h2 img {
            height: 2.22222vw; }
        .about-project .about-project-card .about-project-card-data .about-project-card-data-url {
          opacity: 0.6;
          font-size: 1.11111vw;
          line-height: 1.4;
          margin-bottom: 1.73611vw; }
        .about-project .about-project-card .about-project-card-data .about-project-card-data-text {
          font-size: 0.97222vw;
          line-height: 1.80556vw;
          width: 40.20833vw; } }

@media (max-width: 750px) {
  .about-project {
    width: 91.79487vw;
    margin: 0 auto;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB; }
    .about-project .about-project-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-left: 2.5641vw;
      margin-bottom: 5.12821vw; }
      .about-project .about-project-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-project .about-project-head {
      margin-left: 2.5641vw; }
      .about-project .about-project-head h1 {
        width: 64.61538vw;
        margin-bottom: 2.5641vw;
        font-size: 16.92308vw;
        line-height: 0.8; }
        .about-project .about-project-head h1 img {
          width: 64.61538vw; }
      .about-project .about-project-head p {
        font-size: 3.07692vw;
        line-height: 5.64103vw;
        width: 85.12821vw;
        margin-bottom: 7.69231vw; }
    .about-project .about-project-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border-radius: 5.12821vw;
      padding-bottom: 5.12821vw;
      overflow: hidden;
      margin-bottom: 4.10256vw; }
      .about-project .about-project-card .about-project-card-img {
        margin-bottom: 6.41026vw; }
        .about-project .about-project-card .about-project-card-img img {
          width: 91.79487vw; }
      .about-project .about-project-card .about-project-card-data h2 {
        font-size: 10.76923vw;
        margin-bottom: 3.84615vw; }
        .about-project .about-project-card .about-project-card-data h2 img {
          height: 8.20513vw; }
      .about-project .about-project-card .about-project-card-data .about-project-card-data-url {
        opacity: 0.6;
        font-size: 4.10256vw;
        line-height: 1.4;
        margin-bottom: 6.41026vw; }
      .about-project .about-project-card .about-project-card-data .about-project-card-data-text {
        font-size: 3.07692vw;
        line-height: 5.64103vw;
        width: 82.5641vw; } }

@media (min-width: 751px) {
  .about-group {
    width: 94.44444vw;
    margin: 0 auto;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .about-group .about-group-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 1.38889vw; }
      .about-group .about-group-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-group .about-group-head {
      display: flex;
      margin-bottom: 3.81944vw; }
      .about-group .about-group-head h1 {
        font-size: 7.36111vw;
        line-height: 0.8; }
        .about-group .about-group-head h1 img {
          width: 59.30556vw; }
    .about-group .about-group-list {
      display: flex;
      margin-bottom: 19.16667vw; }
      .about-group .about-group-list .about-group-card {
        width: 20.13889vw;
        height: 26.25vw;
        border: 1px solid #CBCBCB;
        border-radius: 2.08333vw;
        text-align: center;
        display: flex;
        flex-flow: column; }
        .about-group .about-group-list .about-group-card .about-group-card-img {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center; }
          .about-group .about-group-list .about-group-card .about-group-card-img img {
            width: 13.54167vw; }
        .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-name {
          font-size: 1.25vw;
          margin-bottom: 1.38889vw; }
        .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-more {
          font-size: 1.11111vw;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17.63889vw;
          margin: 0 auto;
          height: 3.05556vw;
          border-top: 1px solid #CBCBCB; }
          .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-more:after {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #000;
            display: block;
            margin-left: 1.11111vw;
            position: relative;
            top: 0.13889vw; } }

@media (max-width: 750px) {
  .about-group {
    width: 91.79487vw;
    margin: 0 auto;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB; }
    .about-group .about-group-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-left: 2.5641vw;
      margin-bottom: 5.12821vw; }
      .about-group .about-group-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-group .about-group-head {
      display: flex;
      margin-left: 2.5641vw;
      margin-bottom: 12.82051vw; }
      .about-group .about-group-head h1 {
        font-size: 16.92308vw;
        line-height: 0.2; }
        .about-group .about-group-head h1 img {
          width: 82.5641vw; }
        .about-group .about-group-head h1 span {
          margin-bottom: -1.28205vw; }
    .about-group .about-group-list {
      display: flex;
      margin-bottom: 28.97436vw; }
      .about-group .about-group-list .about-group-card {
        width: 44.87179vw;
        height: 59.74359vw;
        border: 1px solid #CBCBCB;
        border-radius: 5.12821vw;
        text-align: center;
        display: flex;
        flex-flow: column; }
        .about-group .about-group-list .about-group-card .about-group-card-img {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center; }
          .about-group .about-group-list .about-group-card .about-group-card-img img {
            width: 30.25641vw; }
        .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-name {
          font-size: 2.82051vw;
          margin-bottom: 3.84615vw; }
        .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-more {
          font-size: 2.5641vw;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 39.23077vw;
          margin: 0 auto;
          height: 7.17949vw;
          border-top: 1px solid #CBCBCB; }
          .about-group .about-group-list .about-group-card .about-group-card-data .about-group-card-data-more:after {
            content: '';
            width: 1.28205vw;
            height: 1.28205vw;
            border-radius: 50%;
            background: #000;
            display: block;
            margin-left: 3.58974vw; } }

@media (min-width: 751px) {
  .about-member {
    width: 94.44444vw;
    margin: 0 auto 16.66667vw;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .about-member .about-member-ttl {
      display: flex;
      align-items: center;
      font-size: 1.11111vw;
      margin-bottom: 1.38889vw; }
      .about-member .about-member-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; }
    .about-member .about-member-head {
      display: flex;
      margin-bottom: 2.77778vw; }
      .about-member .about-member-head h1 {
        font-size: 7.36111vw;
        line-height: 0.8; }
        .about-member .about-member-head h1 img {
          width: 30vw; }
    .about-member .about-member-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1.66667vw 1.11111vw; }
      .about-member .about-member-list .about-member-card {
        position: relative;
        cursor: pointer;
        transition: all 0.2s linear;
        border-radius: 2.08333vw;
        overflow: hidden; }
        .about-member .about-member-list .about-member-card:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          content: '';
          transition: 0.2s ease-out all;
          border: 0vw solid #B1AAA2;
          border-radius: 2.08333vw;
          background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
          z-index: 1; }
        .about-member .about-member-list .about-member-card:hover:before {
          border: 0.27778vw solid #B1AAA2; }
        .about-member .about-member-list .about-member-card:hover .about-member-card-img img {
          transform: scale(1.06); }
        .about-member .about-member-list .about-member-card .about-member-card-img {
          height: 35.625vw;
          width: 22.77778vw;
          border-radius: 2.08333vw;
          overflow: hidden; }
          .about-member .about-member-list .about-member-card .about-member-card-img img {
            height: 35.625vw;
            position: relative;
            left: -6.94444vw;
            transition: 0.3s ease-out all;
            transform: scale(1.02); }
        .about-member .about-member-list .about-member-card .about-member-card-data {
          position: absolute;
          bottom: 1.73611vw;
          margin: 0 1.73611vw;
          color: #fff;
          z-index: 1; }
          .about-member .about-member-list .about-member-card .about-member-card-data h4 {
            font-size: 0.97222vw;
            margin-bottom: 0.41667vw; }
          .about-member .about-member-list .about-member-card .about-member-card-data p {
            font-size: 1.38889vw; }
        .about-member .about-member-list .about-member-card:after {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #fff;
          display: block;
          margin-left: 3.58974vw;
          position: absolute;
          right: 1.73611vw;
          bottom: 1.73611vw; }
    .about-member .about-member-transform {
      height: 35.625vw;
      width: 22.77778vw;
      border-radius: 2.08333vw;
      overflow: hidden;
      transition: 0.4s ease-in width,0.4s ease-in height;
      position: absolute;
      opacity: 0;
      z-index: 3;
      pointer-events: none;
      transform: translate(-50%, -50%); }
      .about-member .about-member-transform img {
        width: 36.66667vw;
        border-radius: 2.08333vw;
        overflow: hidden;
        transition: 0.4s ease-out width,0.4s ease-out height,0.4s ease-out left;
        height: 35.625vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
      .about-member .about-member-transform.-show {
        border-radius: 2.08333vw;
        overflow: hidden;
        width: 51.25vw;
        height: 49.72222vw; }
        .about-member .about-member-transform.-show img {
          width: 51.25vw;
          height: 49.72222vw; } }

@media (max-width: 750px) {
  .about-member {
    width: 91.79487vw;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 auto 51.28205vw;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB; }
    .about-member .about-member-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-left: 2.5641vw;
      margin-bottom: 5.12821vw; }
      .about-member .about-member-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .about-member .about-member-head {
      display: flex;
      margin-left: 2.5641vw;
      margin-bottom: 10.25641vw; }
      .about-member .about-member-head h1 {
        font-size: 16.92308vw;
        line-height: 0.9; }
        .about-member .about-member-head h1 img {
          width: 70.25641vw;
          position: relative; }
    .about-member .about-member-list {
      display: flex;
      flex-wrap: wrap;
      gap: 3.58974vw 2.05128vw; }
      .about-member .about-member-list .about-member-card {
        position: relative; }
        .about-member .about-member-list .about-member-card .about-member-card-img {
          width: 44.87179vw;
          height: 69.23077vw;
          border-radius: 5.12821vw;
          overflow: hidden; }
          .about-member .about-member-list .about-member-card .about-member-card-img img {
            height: 69.23077vw;
            position: relative;
            left: -12.82051vw; }
        .about-member .about-member-list .about-member-card .about-member-card-data {
          position: absolute;
          bottom: 2.5641vw;
          margin: 0 2.5641vw;
          color: #fff;
          z-index: 1; }
          .about-member .about-member-list .about-member-card .about-member-card-data h4 {
            font-size: 2.82051vw;
            margin-bottom: 1.02564vw; }
          .about-member .about-member-list .about-member-card .about-member-card-data p {
            font-size: 3.33333vw; }
        .about-member .about-member-list .about-member-card:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          content: '';
          transition: 0.2s ease-out all;
          border: 0vw solid #000;
          border-radius: 2.08333vw;
          background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
          z-index: 1; }
        .about-member .about-member-list .about-member-card:after {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #fff;
          display: block;
          margin-left: 3.58974vw;
          position: absolute;
          right: 3.33333vw;
          bottom: 3.33333vw; }
    .about-member .about-member-transform {
      width: 44.87179vw;
      height: 69.23077vw;
      border-radius: 5.12821vw;
      overflow: hidden;
      transition: 0.4s 0.1s ease-in width,0.4s 0.1s ease-in height;
      position: absolute;
      opacity: 0;
      z-index: 3;
      pointer-events: none;
      transform: translate(-50%, -50%); }
      .about-member .about-member-transform img {
        width: 71.02564vw;
        border-radius: 5.12821vw;
        overflow: hidden;
        transition: 0.4s ease-out width,0.4s ease-out height,0.4s ease-out left;
        height: 69.23077vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
      .about-member .about-member-transform.-show {
        border-radius: 2.08333vw;
        overflow: hidden;
        width: 91.79487vw;
        height: 89.23077vw; }
        .about-member .about-member-transform.-show img {
          width: 91.79487vw;
          height: 89.23077vw; } }

/*
<div class="">
			<div class="about-member-card-img">
				<img src="/assets/img/member1.png">
			</div>
			<div class="about-member-card-data">
				<h4>Architect / Designer</h4>
				<p class="about-member-card-data-more"><span>Yusuke Daimon</span></p>
			</div>
		</div>
*/
@media (min-width: 751px) {
  .base {
    transform: rotate(45deg); }
    .base::after {
      content: "";
      clear: both;
      display: block; } }

@media (min-width: 751px) {
  .detail-btn {
    background: #F6F2ED; }
    .detail-btn p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.11111vw;
      padding-top: 5.27778vw;
      padding-bottom: 7.91667vw; }
      .detail-btn p:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; } }

@media (max-width: 750px) {
  .detail-btn {
    display: none; } }

@media (min-width: 751px) {
  .detail-head {
    display: flex;
    padding-left: 3.47222vw;
    padding-right: 3.47222vw;
    background: #F6F2ED;
    border-radius: 0 0 2.77778vw 2.77778vw; }
    .detail-head .detail-head-ttl {
      width: 54.16667vw; }
      .detail-head .detail-head-ttl h2 {
        display: flex;
        align-items: center;
        font-size: 1.11111vw;
        margin-bottom: 0.97222vw;
        opacity: 0.5; }
        .detail-head .detail-head-ttl h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 1.31944vw; }
      .detail-head .detail-head-ttl h3 {
        font-size: 0vw;
        line-height: 1.2; }
        .detail-head .detail-head-ttl h3 span {
          font-size: 5vw;
          display: inline-block;
          min-width: 1.25vw;
          line-height: 1.2; }
    .detail-head .detail-head-data {
      width: 38.88889vw;
      margin-top: 1.875vw;
      margin-bottom: 6.25vw; }
      .detail-head .detail-head-data dl {
        display: flex;
        flex-wrap: wrap;
        font-size: 0.97222vw;
        border-top: 1px solid #CBCBCB;
        border-bottom: 1px solid #CBCBCB;
        padding-top: 1.94444vw;
        padding-bottom: 0.55556vw;
        margin-bottom: 1.94444vw; }
        .detail-head .detail-head-data dl dt {
          width: 8.33333vw;
          opacity: 0.3; }
        .detail-head .detail-head-data dl dd {
          width: 30.55556vw;
          margin-bottom: 1.66667vw; }
      .detail-head .detail-head-data p {
        font-size: 0.97222vw;
        line-height: 2; } }

@media (max-width: 750px) {
  .detail-head {
    width: 100vw;
    padding-top: 43.07692vw;
    padding-left: 4.10256vw;
    padding-right: 4.10256vw;
    background: #F6F2ED;
    border-radius: 0 0 5.12821vw 5.12821vw; }
    .detail-head .detail-head-ttl {
      width: 91.79487vw;
      margin-bottom: 14.87179vw; }
      .detail-head .detail-head-ttl h2 {
        display: flex;
        align-items: center;
        font-size: 2.5641vw;
        margin-bottom: 5.12821vw; }
        .detail-head .detail-head-ttl h2:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 3.58974vw; }
      .detail-head .detail-head-ttl h3 {
        font-size: 8.20513vw;
        line-height: 1.2; }
        .detail-head .detail-head-ttl h3 span {
          display: inline-block;
          min-width: 2.05128vw;
          line-height: 1.2; }
    .detail-head .detail-head-data {
      padding-bottom: 10.25641vw; }
      .detail-head .detail-head-data dl {
        display: flex;
        flex-wrap: wrap;
        font-size: 3.07692vw;
        line-height: 5.64103vw;
        border-top: 1px solid #CBCBCB;
        border-bottom: 1px solid #CBCBCB;
        padding-top: 5.12821vw;
        padding-bottom: 0vw;
        margin-bottom: 4.10256vw; }
        .detail-head .detail-head-data dl dt {
          width: 23.07692vw;
          opacity: 0.3; }
        .detail-head .detail-head-data dl dd {
          width: 66.66667vw;
          margin-bottom: 3.84615vw;
          word-break: break-all; }
      .detail-head .detail-head-data p {
        font-size: 3.07692vw;
        line-height: 5.64103vw; } }

@media (min-width: 751px) {
  .detail-container {
    background: #F7F2EC;
    border-radius: 2.77778vw 2.77778vw 0 0; }
    .detail-container:after {
      position: absolute;
      position: absolute;
      content: "";
      background: #F7F2EC;
      bottom: -20vw;
      height: 20vw;
      width: 100%; } }

@media (max-width: 750px) {
  .detail-container {
    background: #F7F2EC;
    border-radius: 5.12821vw 5.12821vw 0 0; }
    .detail-container:after {
      position: absolute;
      position: absolute;
      content: "";
      background: #F7F2EC;
      bottom: -80vw;
      height: 80vw;
      width: 100%; } }

@media (min-width: 751px) {
  .detail-content {
    margin-top: 53.47222vw;
    padding-top: 2.77778vw; }
    .detail-content .detail-wide {
      margin-bottom: 5.69444vw; }
      .detail-content .detail-wide img {
        width: 100%; }
    .detail-content .detail-small {
      display: flex;
      justify-content: space-between;
      width: 94.44444vw;
      margin: 0 auto;
      margin-bottom: 1.11111vw; }
      .detail-content .detail-small img {
        width: 46.66667vw;
        border-radius: 2.08333vw;
        overflow: hidden; }
    .detail-content .detail-split {
      display: flex;
      justify-content: space-between;
      width: 94.44444vw;
      margin: 0 auto 5.69444vw; }
      .detail-content .detail-split img {
        width: 46.66667vw;
        border-radius: 2.08333vw;
        overflow: hidden; }
      .detail-content .detail-split div {
        width: 46.66667vw;
        font-size: 0.97222vw;
        line-height: 2; }
    .detail-content .detail-large {
      width: 94.44444vw;
      margin: 0 auto 2.91667vw; }
      .detail-content .detail-large img {
        width: 94.44444vw;
        border-radius: 2.08333vw;
        overflow: hidden; } }

@media (max-width: 750px) {
  .detail-content {
    margin-top: 94.10256vw;
    padding-top: 4.10256vw; }
    .detail-content .detail-wide {
      margin-bottom: 8.20513vw; }
      .detail-content .detail-wide img {
        width: 100%; }
    .detail-content .detail-small {
      display: flex;
      justify-content: space-between;
      width: 91.79487vw;
      margin: 0 auto;
      margin-bottom: 3.07692vw; }
      .detail-content .detail-small img {
        width: 44.87179vw;
        border-radius: 5.12821vw;
        overflow: hidden; }
    .detail-content .detail-split {
      width: 91.79487vw;
      margin: 0 auto 8.20513vw; }
      .detail-content .detail-split img {
        width: 91.79487vw;
        margin-bottom: 5.12821vw;
        border-radius: 5.12821vw;
        overflow: hidden; }
      .detail-content .detail-split div {
        width: 91.79487vw;
        font-size: 3.07692vw;
        line-height: 5.64103vw; }
    .detail-content .detail-large {
      width: 91.79487vw;
      margin: 0 auto 5.12821vw; }
      .detail-content .detail-large img {
        width: 91.79487vw;
        border-radius: 5.12821vw;
        overflow: hidden; } }

@media (min-width: 751px) {
  .detail-data {
    display: flex;
    justify-content: right;
    width: 94.44444vw;
    margin: 0 auto 5.69444vw; }
    .detail-data .detail-data-content {
      width: 47.91667vw; }
      .detail-data .detail-data-content p {
        font-size: 0.97222vw;
        line-height: 2;
        margin-bottom: 5.34722vw;
        margin-left: 3.47222vw; }
      .detail-data .detail-data-content h4 {
        font-size: 1.66667vw;
        line-height: 3.75vw;
        padding-left: 3.47222vw; }
      .detail-data .detail-data-content dl {
        padding-top: 2.77778vw;
        padding-bottom: 1.04167vw;
        border-top: 1px solid #CBCBCB;
        border-bottom: 1px solid #CBCBCB;
        padding-left: 3.47222vw;
        font-size: 0.97222vw;
        display: flex;
        flex-wrap: wrap; }
        .detail-data .detail-data-content dl dt {
          width: 16.25vw;
          opacity: 0.5; }
        .detail-data .detail-data-content dl dd {
          width: 28.05556vw;
          margin-bottom: 1.73611vw; } }

@media (max-width: 750px) {
  .detail-data {
    width: 91.79487vw;
    margin: 0 auto 10.76923vw; }
    .detail-data .detail-data-content p {
      font-size: 3.07692vw;
      line-height: 5.64103vw;
      margin-bottom: 2.77778vw; }
    .detail-data .detail-data-content h4 {
      font-size: 4.10256vw;
      line-height: 12.30769vw; }
    .detail-data .detail-data-content dl {
      padding-top: 5.12821vw;
      padding-bottom: 1.53846vw;
      border-top: 1px solid #CBCBCB;
      border-bottom: 1px solid #CBCBCB;
      font-size: 3.07692vw;
      display: flex;
      flex-wrap: wrap; }
      .detail-data .detail-data-content dl dt {
        width: 47.94872vw;
        opacity: 0.5; }
      .detail-data .detail-data-content dl dd {
        width: 43.33333vw;
        margin-bottom: 3.58974vw;
        opacity: 0.8; } }

@media (min-width: 751px) {
  .detail-nav {
    display: flex;
    justify-content: space-between;
    width: 94.30556vw;
    margin: 0 auto 15.27778vw; }
    .detail-nav .detail-nav-back {
      position: relative; }
      .detail-nav .detail-nav-back img {
        width: 14.23611vw;
        height: 8.88889vw;
        border-radius: 2.08333vw;
        overflow: hidden; }
      .detail-nav .detail-nav-back span {
        font-size: 0.97222vw;
        position: absolute;
        right: -2.22222vw;
        top: 0;
        color: #999; }
    .detail-nav .detail-nav-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 2.08333vw;
      width: 55.83333vw;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.94444vw;
      position: relative;
      transition: all 0.2s ease-out; }
      .detail-nav .detail-nav-btn:hover {
        color: #fff;
        background: black; }
    .detail-nav .detail-nav-next {
      position: relative; }
      .detail-nav .detail-nav-next img {
        width: 14.23611vw;
        height: 8.88889vw;
        border-radius: 2.08333vw;
        overflow: hidden; }
      .detail-nav .detail-nav-next span {
        font-size: 0.97222vw;
        position: absolute;
        left: -2.22222vw;
        top: 0;
        color: #999; } }

@media (max-width: 750px) {
  .detail-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 91.79487vw;
    margin: 0 auto 61.53846vw; }
    .detail-nav .detail-nav-back {
      order: 2;
      position: relative; }
      .detail-nav .detail-nav-back img {
        width: 44.87179vw;
        height: 27.94872vw;
        border-radius: 5.12821vw;
        overflow: hidden; }
      .detail-nav .detail-nav-back span {
        font-size: 2.82051vw;
        position: absolute;
        left: 0;
        bottom: -4.10256vw;
        color: #999; }
    .detail-nav .detail-nav-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5.12821vw;
      width: 91.79487vw;
      line-height: 16.92308vw;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4.10256vw;
      order: 1;
      margin-bottom: 5.12821vw; }
    .detail-nav .detail-nav-next {
      order: 3;
      position: relative; }
      .detail-nav .detail-nav-next img {
        width: 44.87179vw;
        height: 27.94872vw;
        border-radius: 5.12821vw;
        overflow: hidden; }
      .detail-nav .detail-nav-next span {
        font-size: 2.82051vw;
        position: absolute;
        right: 0;
        bottom: -4.10256vw;
        color: #999; } }

.footer {
  position: relative;
  overflow: hidden;
  background: #F7F2EC; }
  @media (min-width: 751px) {
    .footer .footer-content {
      background: #000000;
      color: #F7F2EC;
      border-radius: 6.25vw 6.25vw 0 0;
      padding-top: 4.86111vw;
      padding-left: 3.19444vw;
      padding-right: 3.19444vw;
      position: fixed;
      bottom: 0;
      width: 100%;
      transform: translateY(100%); }
      .footer .footer-content h2 {
        display: flex;
        align-items: center;
        font-size: 1.11111vw;
        opacity: 0.3;
        margin-bottom: 1.25vw; }
        .footer .footer-content h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #F7F2EC;
          display: block;
          margin-right: 1.875vw; }
      .footer .footer-content .footer-mail {
        font-size: 7.36111vw;
        font-weight: normal;
        opacity: 0.6;
        transition: 0.1s linear all;
        margin-bottom: 9.375vw; }
        .footer .footer-content .footer-mail img {
          width: 65.48611vw; }
        .footer .footer-content .footer-mail:hover {
          opacity: 1; }
      .footer .footer-content .footer-data {
        display: flex;
        align-items: center;
        padding-bottom: 2.98611vw; }
        .footer .footer-content .footer-data .footer-data-logo {
          opacity: 0.3;
          width: 6.25vw;
          transition: 0.1s linear all; }
          .footer .footer-content .footer-data .footer-data-logo img {
            width: 4.16667vw; }
          .footer .footer-content .footer-data .footer-data-logo:hover {
            opacity: 1; }
        .footer .footer-content .footer-data .footer-data-info {
          color: #fff;
          line-height: 1.2; }
          .footer .footer-content .footer-data .footer-data-info h3 {
            font-size: 0.97222vw;
            opacity: 0.3;
            margin-bottom: 0.90278vw; }
          .footer .footer-content .footer-data .footer-data-info h4 {
            font-size: 0.97222vw;
            opacity: 0.3; }
      .footer .footer-content .footer-sns {
        position: absolute;
        right: 3.19444vw;
        bottom: 2.98611vw;
        text-align: right; }
        .footer .footer-content .footer-sns .footer-sns-ttl {
          line-height: 2.08333vw;
          font-size: 1.11111vw;
          opacity: 0.3; }
          .footer .footer-content .footer-sns .footer-sns-ttl:hover {
            opacity: 0.3; }
        .footer .footer-content .footer-sns li {
          line-height: 2.08333vw;
          font-size: 1.11111vw;
          opacity: 0.5;
          transition: 0.1s linear all; }
          .footer .footer-content .footer-sns li:hover {
            opacity: 1; } }
  @media (max-width: 750px) {
    .footer .footer-content {
      background: #000000;
      color: #F7F2EC;
      border-radius: 12.82051vw 12.82051vw 0 0;
      padding-top: 10.25641vw;
      padding-left: 5.12821vw;
      padding-right: 5.12821vw;
      padding-bottom: 5.12821vw;
      width: 100%; }
      .footer .footer-content h2 {
        display: flex;
        align-items: center;
        font-size: 3.07692vw;
        opacity: 0.3;
        margin-bottom: 4.61538vw; }
        .footer .footer-content h2:before {
          content: '';
          width: 1.53846vw;
          height: 1.53846vw;
          border-radius: 50%;
          background: #F7F2EC;
          display: block;
          margin-right: 3.07692vw; }
      .footer .footer-content .footer-mail {
        font-size: 13.07692vw;
        font-weight: normal;
        opacity: 0.6;
        margin-bottom: 17.94872vw; }
        .footer .footer-content .footer-mail img {
          width: 86.41026vw; }
      .footer .footer-content .footer-data {
        display: flex;
        align-items: center;
        padding-bottom: 15.38462vw; }
        .footer .footer-content .footer-data .footer-data-logo {
          opacity: 0.3; }
          .footer .footer-content .footer-data .footer-data-logo img {
            width: 15.12821vw; }
        .footer .footer-content .footer-data .footer-data-info {
          color: #fff;
          line-height: 1.2;
          margin-left: 7.69231vw; }
          .footer .footer-content .footer-data .footer-data-info h3 {
            font-size: 3.58974vw;
            opacity: 0.3;
            margin-bottom: 3.33333vw; }
          .footer .footer-content .footer-data .footer-data-info h4 {
            font-size: 3.58974vw;
            opacity: 0.3; }
      .footer .footer-content .footer-sns {
        display: flex;
        justify-content: space-between; }
        .footer .footer-content .footer-sns .footer-sns-ttl {
          line-height: 7.69231vw;
          font-size: 4.10256vw;
          opacity: 0.3; }
        .footer .footer-content .footer-sns li {
          line-height: 7.69231vw;
          font-size: 4.10256vw;
          opacity: 0.3; } }

@media (min-width: 751px) {
  .header {
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .header .header-ttl {
      position: absolute;
      left: 2.77778vw;
      top: 2.22222vw;
      pointer-events: auto;
      z-index: 2; }
      .header .header-ttl img {
        width: 7.22222vw;
        height: 7.5vw; }
      .header .header-ttl:hover {
        animation: simplebeat 1.0s ease-in-out 1 both; }
    .header .header-menubtn {
      position: fixed;
      z-index: 11;
      right: 2.77778vw;
      top: 2.22222vw;
      width: 7.5vw;
      height: 7.5vw;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.3);
      mix-blend-mode: difference;
      color: #fff;
      text-align: center;
      line-height: 7.5vw;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.97222vw;
      transition: 0.1s linear all;
      pointer-events: auto; }
      .header .header-menubtn img {
        width: 2.70833vw; }
      .header .header-menubtn svg {
        width: 100%;
        height: 100%;
        transform: scale(1.06) rotate(-90deg);
        position: absolute;
        top: 0;
        left: 0; }
      .header .header-menubtn circle {
        fill: transparent;
        stroke: white;
        stroke-width: 1;
        stroke-dasharray: 0 366;
        transition: 0.2s ease-out all; }
      .header .header-menubtn:hover circle {
        animation: circle 1s 1 forwards; } }

@media (max-width: 750px) {
  .header {
    z-index: 11;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6.92308vw;
    padding-left: 4.35897vw;
    padding-right: 4.35897vw; }
    .header .header-ttl {
      position: absolute;
      top: 6.92308vw;
      left: 4.35897vw;
      z-index: 2; }
      .header .header-ttl img {
        width: 22.5641vw;
        height: 23.33333vw; }
    .header .header-menubtn {
      position: fixed;
      top: 6.92308vw;
      right: 4.35897vw;
      width: 22.5641vw;
      height: 22.5641vw;
      border-radius: 50%;
      border: 1px solid #000;
      text-align: center;
      line-height: 22.5641vw;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.82051vw; }
      .header .header-menubtn:hover {
        opacity: 0.6; } }

@media (min-width: 751px) {
  .index-top {
    position: relative;
    margin: 0 auto;
    width: 94.44444vw; }
    .index-top h1 {
      bottom: 2.22222vw;
      width: 100%;
      padding-top: 1.52778vw;
      font-size: 13.61111vw;
      letter-spacing: -0.27778vw;
      white-space: nowrap;
      display: flex;
      justify-content: space-between; }
      .index-top h1 img {
        width: 100%; }
      .index-top h1 p {
        display: inline-block; }
    .index-top .index-content {
      position: relative;
      width: 100%;
      height: 100%;
      background: url(../img/index-top.png) center 63%;
      background-size: 94.44444vw 45.34722vw;
      transform: scale(1.3);
      opacity: 0;
      transition: all 1.6s ease-out; }
      .index-top .index-content.-show {
        transform: scale(1);
        opacity: 1; }
    .index-top .stage {
      position: relative;
      margin-top: 10.90278vw;
      width: 94.44444vw;
      height: 45.34722vw;
      position: relative;
      overflow: hidden;
      border: 2px solid #000;
      border-radius: 16.66667vw 16.66667vw 0 0;
      /*
			.line1{
				top: 63%;
				animation:lineanim0 12.0s linear infinite;
			}
			.line2{
				top:96.3%;
				animation:lineanim1 12.0s linear infinite;
			}
			.line3{
				top:92.6%;
				animation:lineanim2 12.0s linear infinite;
				
			}
			.line4{
				top:88.9%;
				animation:lineanim3 12.0s linear infinite;
			}
			.line5{
				top:85.2%;
				animation:lineanim4 12.0s linear infinite;
				
			}
			.line6{
				top:81.5%;
				animation:lineanim5 12.0s linear infinite;
				
			}
			.line7{
				top:77.8%;
				animation:lineanim6 12.0s linear infinite;
				
			}
			.line8{
				top:74.1%;
				animation:lineanim7 12.0s linear infinite;
				
			}
			.line9{
				top:70.4%;
				animation:lineanim8 12.0s linear infinite;
				
			}
			.line10{
				top:66.7%;
				animation:lineanim9 12.0s linear infinite;
			}
			*/ }
      .index-top .stage canvas {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .index-top .stage .line {
        width: 100%;
        position: absolute;
        top: 63%;
        height: 0.13889vw;
        background: #000;
        transform: translate3d(0, 0, 0); }
      .index-top .stage .line1 {
        top: 63%;
        animation: lineanim0 20.0s ease-in infinite; }
      .index-top .stage .line2 {
        top: 63%;
        animation: lineanim0 20.0s -2s ease-in infinite; }
      .index-top .stage .line3 {
        top: 63%;
        animation: lineanim0 20.0s -4s ease-in infinite; }
      .index-top .stage .line4 {
        top: 63%;
        animation: lineanim0 20.0s -6s ease-in infinite; }
      .index-top .stage .line5 {
        top: 63%;
        animation: lineanim0 20.0s -8s ease-in infinite; }
      .index-top .stage .line6 {
        top: 63%;
        animation: lineanim0 20.0s -10s ease-in infinite; }
      .index-top .stage .line7 {
        top: 63%;
        animation: lineanim0 20.0s -12s ease-in infinite; }
      .index-top .stage .line8 {
        top: 63%;
        animation: lineanim0 20.0s -14s ease-in infinite; }
      .index-top .stage .line9 {
        top: 63%;
        animation: lineanim0 20.0s -16s ease-in infinite; }
      .index-top .stage .line10 {
        top: 63%;
        animation: lineanim0 20.0s -18s ease-in infinite; }
      .index-top .stage .cloud1 {
        position: absolute;
        top: 6.94444vw;
        left: -4.16667vw;
        width: 28.40278vw;
        display: block;
        animation: cloudanim 48.0s linear infinite; }
      .index-top .stage .cloud2 {
        position: absolute;
        top: 9.72222vw;
        left: 11.11111vw;
        width: 18.95833vw;
        display: block;
        animation: cloudanim 45.0s linear infinite; }
      .index-top .stage .cloud3 {
        position: absolute;
        top: 5.55556vw;
        left: 59.72222vw;
        width: 19.02778vw;
        display: block;
        animation: cloudanim 51.0s linear infinite; }
      .index-top .stage .cloud4 {
        position: absolute;
        top: 11.11111vw;
        left: 77.77778vw;
        width: 10.76389vw;
        display: block;
        animation: cloudanim 54.0s linear infinite; }
      .index-top .stage .sun {
        position: absolute;
        top: -8.33333vw;
        left: 38.19444vw;
        width: 15.90278vw;
        animation: sunanim 18.0s linear infinite; } }

@media (max-width: 750px) {
  .index-top {
    position: relative;
    margin: 0 auto 19.74359vw;
    padding-top: 32.5641vw;
    width: 91.79487vw; }
    .index-top .index-content {
      height: 86.41026vw;
      margin-bottom: 2.5641vw;
      transform: scale(1);
      background: url(../img/index-top-sp.png) center center/contain;
      border-radius: 30.76923vw 30.76923vw 0 0;
      opacity: 0;
      transition: all 1.6s 0.6s ease-out; }
      .index-top .index-content.-show {
        transform: scale(1);
        opacity: 1; }
    .index-top .stage {
      margin-top: 14.61538vw;
      width: 91.02564vw;
      height: 86.41026vw;
      position: relative;
      overflow: hidden;
      border: 1px solid #000;
      border-radius: 30.76923vw 30.76923vw 0 0;
      /*
			.line1{
				animation:lineanim_sp1 12.0s linear infinite;
			}
			
			.line2{
				animation:lineanim_sp2 12.0s linear infinite;
			}
			
			.line3{
				animation:lineanim_sp3 12.0s linear infinite;
				
			}

			.line4{
				animation:lineanim_sp4 12.0s linear infinite;
			}
			.line5{
				animation:lineanim_sp5 12.0s linear infinite;
				
			}

			.line6{
				animation:lineanim_sp6 12.0s linear infinite;
				
			}
			.line7{
				animation:lineanim_sp7 12.0s linear infinite;
				
			}

			.line8{
				animation:lineanim_sp8 12.0s linear infinite;
				
			}
			.line9{
				animation:lineanim_sp9 12.0s linear infinite;
				
			}
			.line10{
				animation:lineanim_sp10 12.0s linear infinite;
			}
			*/ }
      .index-top .stage #stage {
        position: relative;
        overflow: hidden;
        border-radius: 30.76923vw 30.76923vw 0 0;
        transform: translateZ(1); }
      .index-top .stage canvas {
        width: 91.02564vw;
        height: 86.41026vw;
        position: relative;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%); }
      .index-top .stage .line {
        width: 100%;
        position: absolute;
        top: 87vw;
        height: 0.13889vw;
        background: #000; }
      .index-top .stage .line1 {
        animation: lineanim_sp1 20.0s ease-in infinite; }
      .index-top .stage .line2 {
        animation: lineanim_sp1 20.0s -2s ease-in infinite; }
      .index-top .stage .line3 {
        animation: lineanim_sp1 20.0s -4s ease-in infinite; }
      .index-top .stage .line4 {
        animation: lineanim_sp1 20.0s -6s ease-in infinite; }
      .index-top .stage .line5 {
        animation: lineanim_sp1 20.0s -8s ease-in infinite; }
      .index-top .stage .line6 {
        animation: lineanim_sp1 20.0s -10s ease-in infinite; }
      .index-top .stage .line7 {
        animation: lineanim_sp1 20.0s -12s ease-in infinite; }
      .index-top .stage .line8 {
        animation: lineanim_sp1 20.0s -14s ease-in infinite; }
      .index-top .stage .line9 {
        animation: lineanim_sp1 20.0s -16s ease-in infinite; }
      .index-top .stage .line10 {
        animation: lineanim_sp1 20.0s -18s ease-in infinite; }
      .index-top .stage .cloud1 {
        position: absolute;
        top: 25.64103vw;
        left: -5.12821vw;
        width: 51.28205vw;
        display: block;
        animation: cloudanim 48.0s linear infinite; }
      .index-top .stage .cloud2 {
        position: absolute;
        top: 17.94872vw;
        left: 20.51282vw;
        width: 41.02564vw;
        display: block;
        animation: cloudanim 45.0s linear infinite; }
      .index-top .stage .cloud3 {
        position: absolute;
        top: 20.51282vw;
        left: 46.15385vw;
        width: 41.02564vw;
        display: block;
        animation: cloudanim 51.0s linear infinite; }
      .index-top .stage .cloud4 {
        position: absolute;
        top: 20.51282vw;
        left: 82.05128vw;
        width: 20.51282vw;
        display: block;
        animation: cloudanim 54.0s linear infinite; }
      .index-top .stage .sun {
        position: absolute;
        top: -15.38462vw;
        left: 30.76923vw;
        width: 30.76923vw;
        animation: sunanim 18.0s linear infinite; }
    .index-top h1 {
      width: 100%;
      font-size: 23.84615vw;
      letter-spacing: -0.25641vw;
      white-space: nowrap;
      display: flex;
      flex-wrap: wrap;
      line-height: 1; }
      .index-top h1 img {
        width: 100%; }
      .index-top h1 p {
        margin-bottom: -9.23077vw; }
        .index-top h1 p:first-child {
          margin-right: 7.17949vw; } }

@media (min-width: 751px) {
  .index-about {
    margin: 4.16667vw auto 0;
    width: 94.44444vw;
    display: flex;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 12.70833vw;
    display: flex; }
    .index-about .index-about-data {
      width: 46.875vw; }
      .index-about .index-about-data h2 {
        display: flex;
        align-items: center;
        margin-bottom: 1.52778vw; }
        .index-about .index-about-data h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 1.31944vw; }
      .index-about .index-about-data h3 {
        position: relative;
        font-size: 7.5vw;
        line-height: 0.8;
        margin-top: 0.69444vw;
        margin-bottom: 2.77778vw; }
        .index-about .index-about-data h3 img {
          width: 21.52778vw;
          position: absolute; }
      .index-about .index-about-data a {
        font-size: 1.38889vw;
        border-bottom: 1px solid #CBCBCB;
        display: inline-block;
        padding-bottom: 0.41667vw;
        padding-left: 0.27778vw;
        /*
				width: w(104);
				height:w(104);
				border:1px solid #CBCBCB;
				display: flex;
				justify-content:center;
				align-items:center;
				border-radius: 50%;
				font-size: w(14);
				position: relative;
				a:after{
					display: block;
					content:"";
					position: absolute;
					top:0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				svg{
					width: 100%;
					height: 100%;
					transform:scale(1.06) rotate(-90deg);
			          position: absolute;
			          top: 0;
			          left: 0;
			          pointer-events:none;
				}
		        circle {
		          fill: transparent;
		          stroke: rgba(0,0,0,1);
		          stroke-width: 1;
		          stroke-dasharray: 0 366;
		        }
		        
				transition:0.1s linear all;
				&:hover{
					circle{
						animation: circle 2s 1 forwards;
					}
				}
				*/ }
        .index-about .index-about-data a:after {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: inline-block;
          margin-left: 4.16667vw;
          position: relative;
          top: -0.20833vw;
          left: -0.41667vw; }
    .index-about .index-about-content {
      padding-top: 2.36111vw; }
      .index-about .index-about-content p {
        font-size: 1.66667vw;
        line-height: 3.61111vw;
        font-weight: bold; } }

@media (max-width: 750px) {
  .index-about {
    margin: 0 auto;
    width: 91.79487vw;
    padding-top: 5.12821vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 23.07692vw; }
    .index-about .index-about-data h2 {
      margin-left: 2.05128vw;
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      margin-bottom: 4.61538vw; }
      .index-about .index-about-data h2:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; }
    .index-about .index-about-data h3 {
      margin-left: 2.05128vw;
      font-size: 16.92308vw;
      line-height: 0.8;
      margin-bottom: 9.74359vw; }
      .index-about .index-about-data h3 img {
        width: 52.05128vw; }
    .index-about .index-about-content p {
      margin-left: 2.05128vw;
      font-size: 4.10256vw;
      line-height: 8.97436vw;
      font-weight: bold;
      margin-bottom: 10.25641vw; }
    .index-about .index-about-content .index-about-more {
      font-size: 3.58974vw;
      border-bottom: 1px solid #CBCBCB;
      display: inline-block;
      padding-bottom: 1.53846vw;
      padding-left: 1.02564vw;
      line-height: 1; }
      .index-about .index-about-content .index-about-more:after {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: inline-block;
        margin-left: 9.48718vw;
        position: relative;
        left: -1.02564vw; } }

@media (min-width: 751px) {
  .index-works {
    padding-top: 1.38889vw;
    margin: 0 auto;
    width: 94.44444vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 5vw;
    position: relative; }
    .index-works .index-works-data {
      width: 46.875vw; }
      .index-works .index-works-data h2 {
        display: flex;
        align-items: center;
        margin-bottom: 1.66667vw; }
        .index-works .index-works-data h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 1.31944vw; }
      .index-works .index-works-data h3 {
        font-size: 7.5vw;
        line-height: 0.8; }
        .index-works .index-works-data h3 img {
          width: 22.63889vw; }
    .index-works .index-works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .index-works .index-works-content .index-works-large {
        width: 100%;
        margin-bottom: 3.33333vw; }
        .index-works .index-works-content .index-works-large:hover .index-works-img:after {
          border: 0.27778vw solid #B1AAA2; }
        .index-works .index-works-content .index-works-large:hover .index-works-img img {
          transform: scale(1); }
        .index-works .index-works-content .index-works-large .index-works-img {
          position: relative;
          border-radius: 2.08333vw;
          overflow: hidden; }
          .index-works .index-works-content .index-works-large .index-works-img:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            transition: 0.2s ease-out all;
            border: 0vw solid #B1AAA2;
            border-radius: 2.08333vw; }
        .index-works .index-works-content .index-works-large img {
          width: 100%;
          border-radius: 2.08333vw;
          overflow: hidden;
          transition: all 0.4s ease-out;
          transform: scale(1.05); }
      .index-works .index-works-content .index-works-small {
        width: 46.66667vw;
        margin-bottom: 3.47222vw; }
        .index-works .index-works-content .index-works-small:hover .index-works-img:after {
          border: 0.27778vw solid #B1AAA2; }
        .index-works .index-works-content .index-works-small:hover .index-works-img img {
          transform: scale(1.05); }
        .index-works .index-works-content .index-works-small .index-works-img {
          position: relative;
          border-radius: 2.08333vw;
          overflow: hidden; }
          .index-works .index-works-content .index-works-small .index-works-img:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            transition: 0.2s ease-out all;
            border: 0vw solid #B1AAA2;
            border-radius: 2.08333vw; }
        .index-works .index-works-content .index-works-small img {
          width: 46.66667vw;
          border-radius: 2.08333vw;
          overflow: hidden;
          transition: all 0.4s ease-out; }
      .index-works .index-works-content .index-works-info {
        padding-top: 1.66667vw;
        padding-left: 0.69444vw; }
        .index-works .index-works-content .index-works-info .index-works-info-head {
          display: flex;
          margin-bottom: 1.25vw; }
          .index-works .index-works-content .index-works-info .index-works-info-head .index-works-info-num {
            font-size: 0.97222vw;
            color: #999;
            margin-right: 1.25vw; }
          .index-works .index-works-content .index-works-info .index-works-info-head .index-works-info-ttl {
            font-size: 1.52778vw;
            color: #000;
            font-weight: bold; }
        .index-works .index-works-content .index-works-info .index-works-info-data {
          display: flex;
          align-items: center; }
          .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-category {
            font-size: 0.97222vw;
            color: #999; }
          .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-year {
            font-size: 0.97222vw;
            color: #999;
            display: flex;
            align-items: center; }
            .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-year:before {
              content: '';
              width: 0.55556vw;
              height: 0.55556vw;
              border-radius: 50%;
              background: #999;
              display: block;
              margin-left: 0.97222vw;
              margin-right: 0.97222vw; }
    .index-works .index-works-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 8.88889vw;
      border-radius: 2.08333vw;
      margin-bottom: 2.5vw;
      display: block;
      transition: all 0.2s ease-out; }
      .index-works .index-works-btn:hover {
        color: #fff;
        background: black; }
      .index-works .index-works-btn p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8.88889vw;
        font-size: 1.94444vw; }
        .index-works .index-works-btn p img {
          width: 9.09722vw; }
    .index-works .index-works-btn2 {
      position: absolute;
      top: 6.94444vw;
      right: 0;
      text-align: center;
      display: block;
      transition: all 0.2s ease-out;
      border-bottom: 1px solid #CBCBCB; }
      .index-works .index-works-btn2 p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.38889vw;
        line-height: 2.08333vw; }
        .index-works .index-works-btn2 p:after {
          content: '';
          height: 0.55556vw;
          width: 0.55556vw;
          background: #000;
          display: block;
          margin-left: 1.11111vw;
          border-radius: 50%; } }

@media (max-width: 750px) {
  .index-works {
    padding-top: 4.10256vw;
    margin: 0 auto;
    width: 91.79487vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 9.23077vw;
    position: relative; }
    .index-works .index-works-data {
      width: 91.79487vw; }
      .index-works .index-works-data h2 {
        margin-left: 2.05128vw;
        display: flex;
        align-items: center;
        font-size: 2.5641vw;
        margin-bottom: 6.15385vw; }
        .index-works .index-works-data h2:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 3.58974vw; }
      .index-works .index-works-data h3 {
        margin-left: 2.05128vw;
        font-size: 16.92308vw;
        line-height: 0.8;
        margin-bottom: 8.20513vw; }
        .index-works .index-works-data h3 img {
          width: 54.35897vw; }
    .index-works .index-works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .index-works .index-works-content .index-works-large {
        width: 100%;
        margin-bottom: 11.02564vw; }
        .index-works .index-works-content .index-works-large img {
          width: 100%;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .index-works .index-works-content .index-works-small {
        width: 91.79487vw;
        margin-bottom: 11.02564vw; }
        .index-works .index-works-content .index-works-small img {
          width: 91.79487vw;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .index-works .index-works-content .index-works-info {
        padding-top: 4.10256vw;
        padding-left: 2.5641vw; }
        .index-works .index-works-content .index-works-info .index-works-info-head {
          display: flex;
          margin-bottom: 3.07692vw; }
          .index-works .index-works-content .index-works-info .index-works-info-head .index-works-info-num {
            font-size: 3.58974vw;
            color: #999;
            margin-right: 4.61538vw; }
          .index-works .index-works-content .index-works-info .index-works-info-head .index-works-info-ttl {
            font-size: 4.10256vw;
            color: #000;
            font-weight: bold; }
        .index-works .index-works-content .index-works-info .index-works-info-data {
          display: flex;
          align-items: center; }
          .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-category {
            font-size: 2.82051vw;
            color: #999; }
          .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-year {
            font-size: 2.82051vw;
            color: #999;
            display: flex;
            align-items: center; }
            .index-works .index-works-content .index-works-info .index-works-info-data .index-works-info-year:before {
              content: '';
              width: 1.53846vw;
              height: 1.53846vw;
              border-radius: 50%;
              background: #999;
              display: block;
              margin-left: 2.82051vw;
              margin-right: 2.82051vw; }
    .index-works .index-works-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 16.92308vw;
      border-radius: 5.12821vw;
      margin-bottom: 6.15385vw;
      display: block; }
      .index-works .index-works-btn p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16.92308vw;
        font-size: 4.10256vw; }
    .index-works .index-works-btn2 {
      position: absolute;
      top: 19.23077vw;
      right: 0;
      text-align: center;
      display: block;
      transition: all 0.2s ease-out;
      border-bottom: 1px solid #CBCBCB; }
      .index-works .index-works-btn2 p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.58974vw;
        line-height: 6.15385vw;
        padding-left: 2.05128vw;
        padding-right: 1.02564vw; }
        .index-works .index-works-btn2 p:after {
          content: '';
          height: 2.05128vw;
          width: 2.05128vw;
          background: #000;
          display: block;
          margin-left: 2.05128vw;
          border-radius: 50%; } }

@media (min-width: 751px) {
  .index-company {
    margin: 0 auto;
    width: 94.44444vw;
    display: flex;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .index-company .index-company-data {
      width: 40.97222vw; }
      .index-company .index-company-data h2 {
        display: flex;
        align-items: center;
        margin-bottom: 1.52778vw; }
        .index-company .index-company-data h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 1.31944vw; }
      .index-company .index-company-data h3 {
        letter-spacing: -0.13889vw;
        font-size: 7.5vw;
        line-height: 0.8; }
        .index-company .index-company-data h3 img {
          width: 34.72222vw; }
    .index-company .index-company-content {
      width: 53.61111vw;
      padding-bottom: 10.69444vw;
      padding-top: 3.33333vw; }
      .index-company .index-company-content dl {
        width: 53.61111vw;
        border-bottom: 1px solid #CBCBCB;
        display: flex;
        line-height: 5.69444vw;
        font-size: 0.97222vw; }
        .index-company .index-company-content dl dt {
          padding-left: 1.38889vw;
          width: 10.76389vw;
          padding-top: 1.11111vw;
          padding-bottom: 1.11111vw;
          line-height: 3.26389vw; }
        .index-company .index-company-content dl dd {
          line-height: 3.26389vw;
          padding-top: 1.11111vw;
          padding-bottom: 1.11111vw; } }

@media (max-width: 750px) {
  .index-company {
    padding-top: 4.10256vw;
    margin: 0 auto;
    width: 91.79487vw;
    border-top: 1px solid #CBCBCB; }
    .index-company .index-company-data {
      width: 91.79487vw; }
      .index-company .index-company-data h2 {
        margin-left: 2.05128vw;
        display: flex;
        align-items: center;
        font-size: 2.5641vw;
        margin-bottom: 6.15385vw; }
        .index-company .index-company-data h2:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 3.58974vw; }
      .index-company .index-company-data h3 {
        margin-left: 2.05128vw;
        font-size: 16.92308vw;
        line-height: 0.8;
        margin-bottom: 16.66667vw; }
        .index-company .index-company-data h3 img {
          width: 83.33333vw; }
    .index-company .index-company-content {
      padding-bottom: 39.48718vw;
      border-top: 1px solid #CBCBCB; }
      .index-company .index-company-content dl {
        border-bottom: 1px solid #CBCBCB;
        line-height: 11.79487vw;
        font-size: 3.07692vw;
        padding-top: 5.12821vw;
        padding-bottom: 5.12821vw; }
        .index-company .index-company-content dl dt {
          padding-left: 5.12821vw;
          width: 39.74359vw;
          line-height: 12.82051vw; }
        .index-company .index-company-content dl dd {
          padding-left: 5.12821vw;
          line-height: 10.76923vw; } }

@media (min-width: 751px) {
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #F7F2EC;
    pointer-events: auto;
    opacity: 1;
    transition: all 0.4s 0.8s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .loader.-hide {
      opacity: 0;
      pointer-events: none; }
    .loader .loader-content {
      position: relative;
      width: 108px;
      height: 108px; }
      .loader .loader-content img {
        width: 18px;
        height: 15px;
        margin-bottom: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .loader .loader-content svg {
        width: 108px;
        height: 108px;
        transform: scale(1.06) rotate(-90deg);
        position: absolute;
        top: 0;
        left: 0; }
      .loader .loader-content circle.-progress {
        fill: transparent;
        stroke: black;
        stroke-width: 1;
        stroke-dasharray: 0 366;
        transition: 0.6s ease-out all; }
        .loader .loader-content circle.-progress.-end {
          stroke-dasharray: 366 366; }
      .loader .loader-content circle.-base {
        fill: transparent;
        stroke: rgba(0, 0, 0, 0.3);
        stroke-width: 1;
        stroke-dasharray: 366 366; }
    .loader p {
      margin-top: 20px;
      font-size: 20px; }
    .loader .loader-content-2 {
      display: flex;
      gap: 28px; }
      .loader .loader-content-2 img {
        width: 18px;
        height: 15px;
        opacity: 0.1; }
        .loader .loader-content-2 img.-end {
          opacity: 1; }
          .loader .loader-content-2 img.-end:nth-child(1) {
            transition: all 0.2s 0.0s ease-out; }
          .loader .loader-content-2 img.-end:nth-child(2) {
            transition: all 0.2s 0.2s ease-out; }
          .loader .loader-content-2 img.-end:nth-child(3) {
            transition: all 0.2s 0.4s ease-out; } }

@media (max-width: 750px) {
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #F7F2EC;
    pointer-events: auto;
    opacity: 1;
    transition: all 0.4s 1.6s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .loader.-hide {
      opacity: 0;
      pointer-events: none; }
    .loader .loader-content {
      position: relative;
      width: 27.69231vw;
      height: 27.69231vw; }
      .loader .loader-content img {
        width: 4.61538vw;
        height: 3.84615vw;
        margin-bottom: 5.12821vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .loader .loader-content svg {
        width: 27.69231vw;
        height: 27.69231vw;
        transform: scale(1.06) rotate(-90deg);
        position: absolute;
        top: 0;
        left: 0; }
      .loader .loader-content circle.-progress {
        fill: transparent;
        stroke: black;
        stroke-width: 1;
        stroke-dasharray: 0 366;
        transition: 0.6s ease-out all; }
        .loader .loader-content circle.-progress.-end {
          stroke-dasharray: 366 366; }
      .loader .loader-content circle.-base {
        fill: transparent;
        stroke: rgba(0, 0, 0, 0.3);
        stroke-width: 1;
        stroke-dasharray: 366 366; }
    .loader p {
      margin-top: 5.12821vw;
      font-size: 5.12821vw; }
    .loader .loader-content-2 {
      display: flex;
      gap: 7.17949vw; }
      .loader .loader-content-2 img {
        width: 4.61538vw;
        height: 3.84615vw;
        opacity: 0.1; }
        .loader .loader-content-2 img.-end {
          opacity: 1; }
          .loader .loader-content-2 img.-end:nth-child(1) {
            transition: all 0.2s 0.0s ease-out; }
          .loader .loader-content-2 img.-end:nth-child(2) {
            transition: all 0.2s 0.2s ease-out; }
          .loader .loader-content-2 img.-end:nth-child(3) {
            transition: all 0.2s 0.4s ease-out; } }

@media (min-width: 751px) {
  .member {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 120;
    background: #000;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s linear;
    opacity: 0;
    transition: ease-out 0.4s all;
    pointer-events: none;
    transform: translateY(1vh);
    filter: blur(1vh);
    /*
        &.-show{
            opacity: 1;
            pointer-events:auto;
            .member-header .member-header-menubtn{
                pointer-events:auto;
            }
        }
        */ }
    .member.-show {
      opacity: 1;
      transition: ease-out 0.4s 0.2s all;
      transform: translateY(0vh);
      filter: blur(0vh);
      pointer-events: auto; }
      .member.-show .member-header .member-header-menubtn {
        pointer-events: auto; } }

@media (max-width: 750px) {
  .member {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 120;
    background: #000;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s linear;
    opacity: 0;
    transition: ease-out 0.4s all;
    pointer-events: none;
    transform: translateY(1vh);
    filter: blur(1vh);
    /*
        &.-show{
            opacity: 1;
            pointer-events:auto;
            .member-header-menubtn {
                pointer-events:auto;
            }
        }
        */ }
    .member.-show {
      opacity: 1;
      transition: ease-out 0.4s 0.2s all;
      transform: translateY(0vh);
      filter: blur(0vh);
      pointer-events: auto; }
      .member.-show .member-header .member-header-menubtn {
        pointer-events: auto; } }

.memberlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: #000;
  z-index: 119;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.4s all; }
  .memberlayer.-on {
    height: 100%; }

@media (min-width: 751px) {
  .member-header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: right;
    padding-top: 2.22222vw;
    padding-left: 2.77778vw;
    padding-right: 2.91667vw;
    z-index: 3;
    pointer-events: none; }
    .member-header .member-header-menubtn {
      width: 7.22222vw;
      height: 7.22222vw;
      border-radius: 50%;
      border: 1px solid #F7F2EC;
      text-align: center;
      line-height: 7.22222vw;
      color: #F7F2EC;
      z-index: 3;
      cursor: pointer; }
      .member-header .member-header-menubtn span {
        font-size: 0.97222vw;
        font-weight: bold; }
      .member-header .member-header-menubtn:hover {
        opacity: 0.6; } }

@media (max-width: 750px) {
  .member-header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: right;
    padding-top: 32.30769vw;
    padding-top: 6.66667vw;
    padding-left: 4.10256vw;
    padding-right: 4.10256vw;
    z-index: 3;
    pointer-events: none; }
    .member-header .member-header-menubtn {
      width: 22.5641vw;
      height: 22.5641vw;
      border-radius: 50%;
      border: 1px solid #F7F2EC;
      text-align: center;
      line-height: 22.5641vw;
      color: #F7F2EC;
      z-index: 3; }
      .member-header .member-header-menubtn span {
        font-size: 2.82051vw;
        font-weight: bold; } }

@media (min-width: 751px) {
  .member-content {
    padding-top: 2.22222vw;
    width: 94.44444vw;
    margin: 0 auto 13.33333vw;
    display: flex; }
    .member-content .member-content-img div {
      border-radius: 2.08333vw;
      overflow: hidden;
      width: 51.25vw;
      position: sticky;
      top: 2.22222vw; }
      .member-content .member-content-img div img {
        width: 51.25vw; }
    .member-content .member-content-data {
      width: 36.11111vw;
      color: #fff;
      padding-top: 2.29167vw;
      margin-left: 3.40278vw; }
      .member-content .member-content-data .member-content-data-nav {
        opacity: 0.8;
        margin-bottom: 8.54167vw; }
        .member-content .member-content-data .member-content-data-nav .member-content-data-nav-prev {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 1.11111vw;
          cursor: pointer;
          transition: 0.1s linear all;
          margin-bottom: 0.69444vw; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-prev:after {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-left: 1.11111vw; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-prev:hover {
            opacity: 0.4; }
        .member-content .member-content-data .member-content-data-nav .member-content-data-nav-next {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 1.11111vw;
          cursor: pointer;
          transition: 0.1s linear all; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-next:hover {
            opacity: 0.4; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-next:before {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-right: 1.11111vw; }
      .member-content .member-content-data h1 {
        font-size: 6.25vw;
        opacity: 0.8;
        line-height: 1.0;
        margin-bottom: 1.11111vw; }
      .member-content .member-content-data h3 {
        font-size: 1.38889vw;
        opacity: 0.8;
        margin-bottom: 1.52778vw; }
      .member-content .member-content-data h4 {
        opacity: 0.8;
        font-size: 1.11111vw;
        margin-bottom: 3.61111vw; }
        .member-content .member-content-data h4:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #fff;
          display: block;
          margin-bottom: 1.38889vw; }
      .member-content .member-content-data .member-content-data-sns {
        margin-bottom: 6.80556vw; }
        .member-content .member-content-data .member-content-data-sns li {
          opacity: 0.8;
          font-size: 1.11111vw;
          margin-bottom: 1.11111vw; }
          .member-content .member-content-data .member-content-data-sns li img {
            position: relative;
            top: 0.06944vw;
            margin-left: 0.83333vw;
            width: 1.11111vw; }
      .member-content .member-content-data h2 {
        opacity: 0.8;
        font-size: 1.59722vw;
        line-height: 1.4;
        margin-bottom: 2.08333vw; }
      .member-content .member-content-data p {
        opacity: 0.8;
        font-size: 0.97222vw;
        line-height: 1.80556vw; } }

@media (max-width: 750px) {
  .member-content {
    padding-top: 37.17949vw;
    width: 91.79487vw;
    margin: 0 auto 13.33333vw; }
    .member-content .member-content-img div {
      border-radius: 5.12821vw;
      overflow: hidden;
      width: 91.79487vw; }
      .member-content .member-content-img div img {
        width: 91.79487vw; }
    .member-content .member-content-data {
      width: 91.79487vw;
      color: #fff;
      padding-top: 8.46154vw; }
      .member-content .member-content-data .member-content-data-nav {
        position: absolute;
        top: 12.82051vw;
        left: 4.35897vw;
        opacity: 0.8; }
        .member-content .member-content-data .member-content-data-nav .member-content-data-nav-prev {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 3.33333vw;
          margin-bottom: 3.33333vw; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-prev:after {
            content: '';
            width: 2.05128vw;
            height: 2.05128vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-left: 4.10256vw; }
        .member-content .member-content-data .member-content-data-nav .member-content-data-nav-next {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 3.33333vw; }
          .member-content .member-content-data .member-content-data-nav .member-content-data-nav-next:before {
            content: '';
            width: 2.05128vw;
            height: 2.05128vw;
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-right: 0.90278vw; }
      .member-content .member-content-data h1 {
        font-size: 14.35897vw;
        opacity: 0.8;
        line-height: 1.0;
        margin-bottom: 3.84615vw; }
      .member-content .member-content-data h3 {
        font-size: 4.10256vw;
        opacity: 0.8;
        margin-bottom: 5.12821vw; }
      .member-content .member-content-data h4 {
        opacity: 0.8;
        font-size: 4.10256vw;
        margin-bottom: 6.15385vw; }
        .member-content .member-content-data h4:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #fff;
          display: block;
          margin-bottom: 5.12821vw; }
      .member-content .member-content-data .member-content-data-sns {
        margin-bottom: 19.74359vw; }
        .member-content .member-content-data .member-content-data-sns li {
          opacity: 0.8;
          font-size: 5.12821vw;
          margin-bottom: 3.07692vw; }
          .member-content .member-content-data .member-content-data-sns li img {
            position: relative;
            top: 0.76923vw;
            margin-left: 1.53846vw;
            width: 4.61538vw; }
      .member-content .member-content-data h2 {
        opacity: 0.8;
        font-size: 5.89744vw;
        line-height: 1.2;
        margin-bottom: 8.20513vw; }
      .member-content .member-content-data p {
        opacity: 0.8;
        font-size: 3.58974vw;
        line-height: 6.66667vw; } }

@media (min-width: 751px) {
  .member-works {
    width: 94.44444vw;
    margin: 0 auto;
    margin-bottom: 15.34722vw;
    padding-top: 1.38889vw;
    border-top: 1px solid #CBCBCB; }
    .member-works .member-works-ttl {
      display: flex;
      align-items: center;
      font-size: 0.97222vw;
      color: #fff;
      margin-bottom: 3.88889vw; }
      .member-works .member-works-ttl:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #fff;
        display: block;
        margin-right: 1.31944vw; }
    .member-works .member-works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .member-works .member-works-content .member-works-large {
        width: 100%;
        margin-bottom: 3.33333vw; }
        .member-works .member-works-content .member-works-large img {
          width: 100%;
          border-radius: 2.08333vw;
          overflow: hidden; }
      .member-works .member-works-content .member-works-small {
        width: 46.66667vw;
        margin-bottom: 3.33333vw; }
        .member-works .member-works-content .member-works-small img {
          width: 46.66667vw;
          border-radius: 2.08333vw;
          overflow: hidden; }
      .member-works .member-works-content .member-works-info {
        padding-top: 1.66667vw;
        padding-left: 0.69444vw; }
        .member-works .member-works-content .member-works-info .member-works-info-head {
          display: flex;
          margin-bottom: 0.83333vw; }
          .member-works .member-works-content .member-works-info .member-works-info-head .member-works-info-num {
            font-size: 0.97222vw;
            color: #fff;
            margin-right: 1.25vw;
            opacity: 0.4; }
          .member-works .member-works-content .member-works-info .member-works-info-head .member-works-info-ttl {
            font-size: 1.52778vw;
            color: #fff;
            font-weight: bold; }
        .member-works .member-works-content .member-works-info .member-works-info-data {
          display: flex;
          align-items: center;
          opacity: 0.4; }
          .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-category {
            font-size: 0.97222vw;
            color: #fff; }
          .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-year {
            font-size: 0.97222vw;
            color: #fff;
            display: flex;
            align-items: center; }
            .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-year:before {
              content: '';
              width: 0.55556vw;
              height: 0.55556vw;
              border-radius: 50%;
              background: #fff;
              display: block;
              margin-left: 0.97222vw;
              margin-right: 0.97222vw; } }

@media (max-width: 750px) {
  .member-works {
    width: 91.79487vw;
    margin: 0 auto;
    margin-bottom: 56.66667vw;
    padding-top: 5.12821vw;
    border-top: 1px solid #fff; }
    .member-works .member-works-ttl {
      display: flex;
      align-items: center;
      font-size: 2.5641vw;
      color: #fff;
      margin-left: 2.5641vw;
      margin-bottom: 7.69231vw; }
      .member-works .member-works-ttl:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #fff;
        display: block;
        margin-right: 3.58974vw; }
    .member-works .member-works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .member-works .member-works-content .member-works-large {
        width: 100%;
        margin-bottom: 11.02564vw; }
        .member-works .member-works-content .member-works-large img {
          width: 100%;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .member-works .member-works-content .member-works-small {
        width: 91.79487vw;
        margin-bottom: 11.02564vw; }
        .member-works .member-works-content .member-works-small img {
          width: 91.79487vw;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .member-works .member-works-content .member-works-info {
        padding-top: 4.10256vw;
        padding-left: 2.5641vw; }
        .member-works .member-works-content .member-works-info .member-works-info-head {
          display: flex;
          margin-bottom: 3.07692vw; }
          .member-works .member-works-content .member-works-info .member-works-info-head .member-works-info-num {
            font-size: 3.58974vw;
            color: #999;
            margin-right: 4.61538vw; }
          .member-works .member-works-content .member-works-info .member-works-info-head .member-works-info-ttl {
            font-size: 4.10256vw;
            color: #fff;
            opacity: 0.8;
            font-weight: bold; }
        .member-works .member-works-content .member-works-info .member-works-info-data {
          display: flex;
          align-items: center; }
          .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-category {
            font-size: 2.82051vw;
            color: #999; }
          .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-year {
            font-size: 2.82051vw;
            color: #999;
            display: flex;
            align-items: center; }
            .member-works .member-works-content .member-works-info .member-works-info-data .member-works-info-year:before {
              content: '';
              width: 1.53846vw;
              height: 1.53846vw;
              border-radius: 50%;
              background: #999;
              display: block;
              margin-left: 2.82051vw;
              margin-right: 2.82051vw; } }

.menu-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: #000;
  z-index: 101;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.4s all; }
  .menu-layer.-on {
    height: 100%; }

@media (min-width: 751px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 102;
    opacity: 0;
    transition: ease-out 0.4s all;
    pointer-events: none;
    transform: translateY(1vh);
    filter: blur(1vh); }
    .menu.-on {
      opacity: 1;
      transition: ease-out 0.4s 0.2s all;
      transform: translateY(0vh);
      filter: blur(0vh);
      pointer-events: auto; }
    .menu .menu-header {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: right;
      padding-top: 2.22222vw;
      padding-left: 2.77778vw;
      padding-right: 2.77778vw; }
      .menu .menu-header .menu-header-menubtn {
        width: 7.5vw;
        height: 7.5vw;
        border-radius: 50%;
        border: 1px solid #F7F2EC;
        text-align: center;
        line-height: 7.5vw;
        color: #F7F2EC;
        position: relative;
        cursor: pointer;
        transition: 0.1s linear all;
        transition: 0.1s linear all; }
        .menu .menu-header .menu-header-menubtn span {
          font-size: 0.97222vw;
          font-weight: bold; }
        .menu .menu-header .menu-header-menubtn svg {
          width: 100%;
          height: 100%;
          transform: scale(1.06) rotate(-90deg);
          position: absolute;
          top: 0;
          left: 0; }
        .menu .menu-header .menu-header-menubtn circle {
          fill: transparent;
          stroke: white;
          stroke-width: 1;
          stroke-dasharray: 0 366; }
        .menu .menu-header .menu-header-menubtn:hover circle {
          animation: circle 2s 1 forwards; }
    .menu .menu-content {
      width: 73.61111vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      line-height: 0.8;
      color: #F7F2EC;
      /*
			.menu-content-ttl{
				font-size:w(106);
				font-weight: bold;
			}*/ }
      .menu .menu-content a {
        color: #F7F2EC; }
      .menu .menu-content .menu-content-left {
        width: 45.83333vw; }
      .menu .menu-content .menu-content-right {
        width: 27.77778vw;
        display: flex;
        flex-flow: column; }
      .menu .menu-content .menu-content-ttl-about {
        opacity: 0.5;
        transition: 0.1s linear all; }
        .menu .menu-content .menu-content-ttl-about img {
          width: 21.52778vw; }
        .menu .menu-content .menu-content-ttl-about:hover {
          opacity: 1; }
        .menu .menu-content .menu-content-ttl-about.-current {
          opacity: 1;
          position: relative; }
          .menu .menu-content .menu-content-ttl-about.-current:before {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #F7F2EC;
            display: inline-block;
            position: absolute;
            left: -2.08333vw;
            top: 0.20833vw; }
      .menu .menu-content .menu-content-ttl-works {
        opacity: 0.5;
        transition: 0.1s linear all; }
        .menu .menu-content .menu-content-ttl-works img {
          width: 22.63889vw; }
        .menu .menu-content .menu-content-ttl-works:hover {
          opacity: 1; }
        .menu .menu-content .menu-content-ttl-works.-current {
          opacity: 1;
          position: relative; }
          .menu .menu-content .menu-content-ttl-works.-current:before {
            content: '';
            width: 0.55556vw;
            height: 0.55556vw;
            border-radius: 50%;
            background: #F7F2EC;
            display: inline-block;
            position: absolute;
            left: -2.08333vw;
            top: 0.20833vw; }
      .menu .menu-content .menu-content-topics {
        padding-top: 3.125vw;
        padding-bottom: 4.16667vw; }
        .menu .menu-content .menu-content-topics li {
          padding-bottom: 1.38889vw;
          opacity: 0.5;
          transition: 0.1s linear all; }
          .menu .menu-content .menu-content-topics li a {
            font-size: 1.59722vw;
            font-weight: bold; }
          .menu .menu-content .menu-content-topics li:hover {
            opacity: 1; }
      .menu .menu-content .menu-content-logo {
        width: 7.77778vw;
        height: 8.19444vw;
        flex-grow: 1; }
        .menu .menu-content .menu-content-logo a {
          opacity: 0.5;
          transition: 0.1s linear all; }
          .menu .menu-content .menu-content-logo a:hover {
            opacity: 1; }
      .menu .menu-content .menu-content-contact {
        font-weight: bold;
        font-size: 1.11111vw;
        padding-left: 2.08333vw;
        position: relative;
        margin-bottom: 1.73611vw;
        opacity: 0.3; }
        .menu .menu-content .menu-content-contact:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #F7F2EC;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0.20833vw; }
      .menu .menu-content .menu-content-mail {
        font-size: 3.54167vw;
        font-weight: normal;
        opacity: 0.6;
        transition: 0.1s linear all;
        margin-bottom: 2.08333vw; }
        .menu .menu-content .menu-content-mail img {
          width: 27.77778vw; }
        .menu .menu-content .menu-content-mail:hover {
          opacity: 1; }
      .menu .menu-content .menu-content-address {
        font-size: 0.97222vw;
        font-weight: bold;
        opacity: 0.3;
        white-space: nowrap;
        line-height: 1.4;
        letter-spacing: -0.02778vw;
        margin-bottom: 1.66667vw; }
      .menu .menu-content .menu-content-sns {
        display: flex;
        margin-bottom: 2.43056vw; }
        .menu .menu-content .menu-content-sns .menu-content-sns-ttl {
          font-size: 0.97222vw;
          font-weight: bold;
          opacity: 0.3;
          margin-right: 1.59722vw; }
        .menu .menu-content .menu-content-sns .menu-content-sns-list {
          font-size: 0.97222vw;
          font-weight: bold;
          display: flex; }
          .menu .menu-content .menu-content-sns .menu-content-sns-list li {
            margin-right: 0.69444vw; }
            .menu .menu-content .menu-content-sns .menu-content-sns-list li a {
              opacity: 0.5;
              transition: 0.1s linear all; }
              .menu .menu-content .menu-content-sns .menu-content-sns-list li a:hover {
                opacity: 1; }
      .menu .menu-content .menu-content-copy {
        font-size: 0.97222vw;
        font-weight: bold;
        opacity: 0.3; } }

@media (max-width: 750px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    overflow: auto;
    z-index: 102;
    opacity: 0;
    transition: ease-out 0.4s all;
    pointer-events: none;
    transform: translateY(1vh);
    filter: blur(1vh); }
    .menu.-on {
      opacity: 1;
      transition: ease-out 0.4s 0.2s all;
      transform: translateY(0vh);
      filter: blur(0vh);
      pointer-events: auto; }
    .menu .menu-header {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: right;
      padding-top: 6.92308vw;
      padding-left: 4.35897vw;
      padding-right: 4.35897vw; }
      .menu .menu-header .menu-header-menubtn {
        width: 22.5641vw;
        height: 22.5641vw;
        border-radius: 50%;
        border: 1px solid #F7F2EC;
        text-align: center;
        line-height: 22.5641vw;
        color: #F7F2EC; }
        .menu .menu-header .menu-header-menubtn span {
          font-size: 2.82051vw;
          font-weight: bold; }
    .menu .menu-content {
      margin-top: 46.92308vw;
      line-height: 0.8;
      color: #F7F2EC;
      /*
			.menu-content-left{
				width: w(660);
			}
			.menu-content-right{
				width: w(400);
			}*/ }
      .menu .menu-content a {
        color: #F7F2EC; }
      .menu .menu-content .menu-content-ttl-about {
        margin-left: 10.25641vw;
        opacity: 0.5; }
        .menu .menu-content .menu-content-ttl-about img {
          width: 65.89744vw; }
        .menu .menu-content .menu-content-ttl-about.-current {
          opacity: 1;
          position: relative; }
          .menu .menu-content .menu-content-ttl-about.-current:before {
            content: '';
            width: 2.05128vw;
            height: 2.05128vw;
            border-radius: 50%;
            background: #F7F2EC;
            display: inline-block;
            position: absolute;
            left: -5.12821vw;
            top: 0.76923vw; }
      .menu .menu-content .menu-content-ttl-works {
        margin-left: 10.25641vw;
        margin-bottom: 25.64103vw;
        opacity: 0.5; }
        .menu .menu-content .menu-content-ttl-works img {
          width: 69.23077vw; }
        .menu .menu-content .menu-content-ttl-works.-current {
          opacity: 1;
          position: relative; }
          .menu .menu-content .menu-content-ttl-works.-current:before {
            content: '';
            width: 2.05128vw;
            height: 2.05128vw;
            border-radius: 50%;
            background: #F7F2EC;
            display: inline-block;
            position: absolute;
            left: -5.12821vw;
            top: 0.76923vw; }
      .menu .menu-content .menu-content-topics {
        padding-top: 10.25641vw;
        margin-left: 14.61538vw;
        padding-bottom: 15.38462vw; }
        .menu .menu-content .menu-content-topics li {
          padding-bottom: 4.61538vw;
          opacity: 0.5; }
          .menu .menu-content .menu-content-topics li a {
            font-size: 4.83077vw;
            font-weight: bold; }
      .menu .menu-content .menu-content-logo {
        width: 22.5641vw;
        height: 23.33333vw;
        opacity: 0.5;
        position: absolute;
        top: 6.92308vw;
        left: 4.35897vw; }
        .menu .menu-content .menu-content-logo img {
          width: 22.5641vw;
          height: 23.33333vw; }
      .menu .menu-content .menu-content-contact {
        font-weight: bold;
        font-size: 4.10256vw;
        margin-left: 5.64103vw;
        padding-left: 5.12821vw;
        position: relative;
        margin-bottom: 5.12821vw;
        opacity: 0.3; }
        .menu .menu-content .menu-content-contact:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #F7F2EC;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0.76923vw; }
      .menu .menu-content .menu-content-mail {
        margin-left: 5.64103vw;
        font-size: 13.07692vw;
        font-weight: normal;
        opacity: 0.6;
        margin-bottom: 12.82051vw; }
        .menu .menu-content .menu-content-mail img {
          width: 86.41026vw; }
      .menu .menu-content .menu-content-address {
        color: #D9D9D9;
        margin-left: 5.64103vw;
        font-size: 3.58974vw;
        font-weight: bold;
        display: inline-block;
        opacity: 0.5;
        white-space: nowrap;
        line-height: 1.4;
        margin-bottom: 10.25641vw; }
      .menu .menu-content .menu-content-sns {
        display: flex;
        margin-bottom: 3.84615vw;
        margin-left: 5.64103vw; }
        .menu .menu-content .menu-content-sns .menu-content-sns-ttl {
          font-size: 4.10256vw;
          font-weight: bold;
          color: #D9D9D9;
          opacity: 0.5;
          margin-right: 7.69231vw; }
        .menu .menu-content .menu-content-sns .menu-content-sns-list {
          font-size: 4.10256vw;
          font-weight: bold;
          display: flex;
          width: 61.53846vw;
          justify-content: space-between; }
          .menu .menu-content .menu-content-sns .menu-content-sns-list li {
            opacity: 0.3;
            margin-right: 0.69444vw; }
      .menu .menu-content .menu-content-copy {
        font-size: 3.58974vw;
        margin-left: 5.64103vw;
        font-weight: bold;
        opacity: 0.3;
        padding-bottom: 12.82051vw; } }

@media (min-width: 751px) {
  .base {
    transform: rotate(45deg);
    display: flex; }
    .base::after {
      content: "";
      clear: both;
      display: block; } }

@media (min-width: 751px) {
  .works-top {
    padding-top: 12.77778vw;
    text-align: center;
    padding-bottom: 6.45833vw; }
    .works-top h1 {
      font-size: 11.52778vw;
      line-height: 0.8;
      margin-bottom: -1.66667vw; }
      .works-top h1 img {
        width: 35.48611vw; }
    .works-top p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.11111vw; }
      .works-top p:before {
        content: '';
        width: 0.55556vw;
        height: 0.55556vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 1.31944vw; } }

@media (max-width: 750px) {
  .works-top {
    padding-top: 51.28205vw;
    text-align: center;
    padding-bottom: 12.82051vw; }
    .works-top h1 {
      margin-bottom: -2.5641vw;
      font-size: 21.02564vw;
      line-height: 0.8; }
      .works-top h1 img {
        width: 64.87179vw; }
    .works-top p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5641vw; }
      .works-top p:before {
        content: '';
        width: 2.05128vw;
        height: 2.05128vw;
        border-radius: 50%;
        background: #000;
        display: block;
        margin-right: 3.58974vw; } }

@media (min-width: 751px) {
  .works {
    padding-top: 1.38889vw;
    margin: 0 auto;
    width: 94.44444vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 12.5vw; }
    .works .works-header {
      display: flex; }
    .works .works-category {
      padding-top: 1.38889vw;
      display: flex;
      margin-bottom: 1.11111vw; }
      .works .works-category li {
        font-size: 1.11111vw;
        line-height: 2.63889vw;
        border-radius: 1.31944vw;
        background: #D9D9D9;
        margin-right: 0.55556vw;
        color: #fff;
        padding: 0 1.18056vw; }
        .works .works-category li.-current {
          background: #000; }
    .works .works-tags {
      display: flex;
      flex-wrap: wrap; }
      .works .works-tags li {
        font-size: 1.11111vw;
        line-height: 1.94444vw;
        color: #B3B3B3; }
        .works .works-tags li:after {
          content: '/';
          display: inline-block;
          margin: 0 0.41667vw; }
        .works .works-tags li:last-child:after {
          display: none; }
        .works .works-tags li.-current {
          color: #000;
          text-decoration: underline; }
    .works .works-data {
      width: 46.875vw;
      margin-bottom: 2.08333vw; }
      .works .works-data h2 {
        display: flex;
        align-items: center;
        font-size: 1.11111vw;
        margin-bottom: 1.52778vw; }
        .works .works-data h2:before {
          content: '';
          width: 0.55556vw;
          height: 0.55556vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 1.31944vw; }
      .works .works-data h3 {
        font-size: 7.36111vw; }
    .works .works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .works .works-content .works-large {
        width: 100%;
        margin-bottom: 3.33333vw; }
        .works .works-content .works-large img {
          width: 100%;
          border-radius: 2.08333vw;
          overflow: hidden;
          transition: all 0.4s ease-out; }
        .works .works-content .works-large:hover .works-img:after {
          border: 0.27778vw solid #B1AAA2; }
        .works .works-content .works-large:hover .works-img img {
          transform: scale(1.05); }
        .works .works-content .works-large .works-img {
          position: relative;
          border-radius: 2.08333vw;
          overflow: hidden; }
          .works .works-content .works-large .works-img:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            transition: 0.2s ease-out all;
            border: 0vw solid #000;
            border-radius: 2.08333vw; }
      .works .works-content .works-small {
        width: 46.66667vw;
        margin-bottom: 3.33333vw; }
        .works .works-content .works-small img {
          width: 46.66667vw;
          border-radius: 2.08333vw;
          overflow: hidden;
          transition: all 0.4s ease-out; }
        .works .works-content .works-small:hover .works-img:after {
          border: 0.27778vw solid #B1AAA2; }
        .works .works-content .works-small:hover .works-img img {
          transform: scale(1.05); }
        .works .works-content .works-small .works-img {
          position: relative;
          border-radius: 2.08333vw;
          overflow: hidden; }
          .works .works-content .works-small .works-img:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            transition: 0.2s ease-out all;
            border: 0vw solid #000;
            border-radius: 2.08333vw; }
      .works .works-content .works-info {
        padding-top: 1.66667vw;
        padding-left: 0.69444vw; }
        .works .works-content .works-info .works-info-head {
          display: flex;
          margin-bottom: 1.25vw; }
          .works .works-content .works-info .works-info-head .works-info-num {
            font-size: 0.97222vw;
            color: #999;
            margin-right: 1.25vw; }
          .works .works-content .works-info .works-info-head .works-info-ttl {
            font-size: 1.52778vw;
            color: #000;
            font-weight: bold; }
        .works .works-content .works-info .works-info-data {
          display: flex;
          align-items: center; }
          .works .works-content .works-info .works-info-data .works-info-category {
            font-size: 0.97222vw;
            color: #999; }
          .works .works-content .works-info .works-info-data .works-info-year {
            font-size: 0.97222vw;
            color: #999;
            display: flex;
            align-items: center; }
            .works .works-content .works-info .works-info-data .works-info-year:before {
              content: '';
              width: 0.55556vw;
              height: 0.55556vw;
              border-radius: 50%;
              background: #999;
              display: block;
              margin-left: 0.97222vw;
              margin-right: 0.97222vw; }
    .works .works-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 8.88889vw;
      border-radius: 2.08333vw;
      transition: all 0.2s ease-out; }
      .works .works-btn:hover {
        color: #fff;
        background: black; }
      .works .works-btn p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.94444vw;
        height: 8.88889vw; }
        .works .works-btn p img {
          width: 4.79167vw; } }

@media (max-width: 750px) {
  .works {
    padding-top: 5.12821vw;
    margin: 0 auto;
    width: 91.79487vw;
    border-top: 1px solid #CBCBCB;
    padding-bottom: 41.02564vw; }
    .works .works-category {
      padding-top: 1.53846vw;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 3.07692vw; }
      .works .works-category li {
        font-size: 3.58974vw;
        line-height: 8.46154vw;
        border-radius: 4.23077vw;
        background: #D9D9D9;
        margin-right: 2.05128vw;
        color: #fff;
        padding: 0 3.84615vw;
        margin-bottom: 2.5641vw; }
        .works .works-category li.-current {
          background: #000; }
    .works .works-tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 17.94872vw; }
      .works .works-tags li {
        font-size: 3.33333vw;
        line-height: 7.17949vw;
        color: #B3B3B3; }
        .works .works-tags li:after {
          content: '/';
          display: inline-block;
          margin: 0 1.53846vw; }
        .works .works-tags li:last-child:after {
          display: none; }
        .works .works-tags li.-current {
          color: #000;
          text-decoration: underline; }
    .works .works-data {
      width: 91.79487vw;
      margin-bottom: 8.97436vw; }
      .works .works-data h2 {
        display: flex;
        align-items: center;
        font-size: 2.5641vw;
        margin-bottom: 5.64103vw; }
        .works .works-data h2:before {
          content: '';
          width: 2.05128vw;
          height: 2.05128vw;
          border-radius: 50%;
          background: #000;
          display: block;
          margin-right: 3.58974vw; }
      .works .works-data h3 {
        font-size: 12.30769vw; }
        .works .works-data h3 img {
          width: 21.53846vw; }
    .works .works-content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .works .works-content .works-large {
        width: 91.79487vw;
        margin-bottom: 11.02564vw; }
        .works .works-content .works-large img {
          width: 91.79487vw;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .works .works-content .works-small {
        width: 91.79487vw;
        margin-bottom: 11.02564vw; }
        .works .works-content .works-small img {
          width: 91.79487vw;
          border-radius: 5.12821vw;
          overflow: hidden; }
      .works .works-content .works-info {
        padding-top: 4.10256vw;
        padding-left: 2.5641vw; }
        .works .works-content .works-info .works-info-head {
          display: flex;
          margin-bottom: 3.07692vw; }
          .works .works-content .works-info .works-info-head .works-info-num {
            font-size: 3.58974vw;
            color: #999;
            margin-right: 4.61538vw; }
          .works .works-content .works-info .works-info-head .works-info-ttl {
            font-size: 4.10256vw;
            color: #000;
            font-weight: bold; }
        .works .works-content .works-info .works-info-data {
          display: flex;
          align-items: center; }
          .works .works-content .works-info .works-info-data .works-info-category {
            font-size: 2.82051vw;
            color: #999; }
          .works .works-content .works-info .works-info-data .works-info-year {
            font-size: 2.82051vw;
            color: #999;
            display: flex;
            align-items: center; }
            .works .works-content .works-info .works-info-data .works-info-year:before {
              content: '';
              width: 1.53846vw;
              height: 1.53846vw;
              border-radius: 50%;
              background: #999;
              display: block;
              margin-left: 2.82051vw;
              margin-right: 2.82051vw; }
    .works .works-btn {
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 16.92308vw;
      border-radius: 5.12821vw; }
      .works .works-btn p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16.92308vw;
        font-size: 4.10256vw; } }
