
.footer{
	position: relative;
	overflow: hidden;
	background: #F7F2EC;
	@media #{$mq-pc} {
		//margin-top: w(-150);
		.footer-content{
		background: #000000;
		color:#F7F2EC;
		border-radius: w(90) w(90) 0 0;
		padding-top: w(70);
		padding-left: w(46);
		padding-right: w(46);
		position: fixed;
		bottom: 0;
		width: 100%;
		transform:translateY(100%);
		h2{
			display: flex;
			align-items:center;
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #F7F2EC;
				display: block;
				margin-right: w(27);

			}
			font-size: w(16);
			opacity: 0.3;
			margin-bottom: w(18);
		}
		.footer-mail{
			img{
				width: w(943);
			}
			font-size: w(106);
			font-weight: normal;
			opacity: 0.6;
			transition:0.1s linear all;
			&:hover{
				opacity: 1;
			}

			margin-bottom: w(135);
		}
		.footer-data{
			display: flex;
			align-items:center;
			.footer-data-logo{
				opacity: 0.3;
				width:w(90);
				img{
					width:w(60);
				}
				transition:0.1s linear all;
				&:hover{
					opacity: 1;
				}
			}
			.footer-data-info{
				color: #fff;
				line-height: 1.2;
				h3{
					//width:w(418);
					font-size: w(14);
					opacity: 0.3;
					margin-bottom: w(13);
				}
				h4{
					//width:w(219);
					font-size: w(14);
					opacity: 0.3;
				}
			}
			padding-bottom: w(43);
		}
		.footer-sns{
			position: absolute;
			right:w(46);
			bottom:w(43);
			text-align: right;
			.footer-sns-ttl{
				line-height: w(30);
				font-size: w(16);
				opacity: 0.3;
				&:hover{
					opacity: 0.3;
				}
			}
			li{
				line-height: w(30);
				font-size: w(16);
				opacity: 0.5;
				transition:0.1s linear all;
				&:hover{
					opacity: 1;
				}
			}
		}
		}

	}
	@media #{$mq-sp} {
		//margin-top: sw(-175);
		.footer-content{
		background: #000000;
		color:#F7F2EC;
		border-radius: sw(50) sw(50) 0 0;
		padding-top: sw(40);
		padding-left: sw(20);
		padding-right: sw(20);
		padding-bottom: sw(20);
		
		//position: fixed;
		//bottom: 0;
		width: 100%;
		//transform:translateY(100%);
		h2{
			display: flex;
			align-items:center;
			&:before{
				content:'';
				width:sw(6);
				height:sw(6);
				border-radius: 50%;
				background: #F7F2EC;
				display: block;
				margin-right: sw(12);
				

			}
			font-size: sw(12);
			opacity: 0.3;
			margin-bottom: sw(18);
		}
		.footer-mail{
			img{
				width: sw(337);
			}
			font-size: sw(51);
			font-weight: normal;
			opacity: 0.6;
			margin-bottom: sw(70);
		}
		.footer-data{
			display: flex;
			align-items:center;
			.footer-data-logo{
				opacity: 0.3;
				img{
					width:sw(59);
				}
			}
			.footer-data-info{
				color: #fff;
				line-height: 1.2;
				margin-left: sw(30);
				h3{
					//width:w(418);
					font-size: sw(14);
					opacity: 0.3;
					margin-bottom: sw(13);
				}
				h4{
					//width:w(219);
					font-size: sw(14);
					opacity: 0.3;
				}
			}
			padding-bottom: sw(60);
		}
		.footer-sns{
			//position: absolute;
			//right:sw(46);
			//bottom:sw(43);
			display: flex;
			justify-content:space-between;

			.footer-sns-ttl{
				line-height: sw(30);
				font-size: sw(16);
				opacity: 0.3;
			}
			li{
				line-height: sw(30);
				font-size: sw(16);
				opacity: 0.3;
			}
		}
		}
	}
}
