@mixin font-smoothing ($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}
