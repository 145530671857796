.detail-btn{
	@media #{$mq-pc} {
		p{
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: w(16);
			padding-top: w(76);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);
			}
			padding-bottom: w(114);
		}
		background: #F6F2ED;
	}
	@media #{$mq-sp} {
		display: none;
	}
}
.detail-head{
	@media #{$mq-pc} {
		display: flex;
		//width:w(1340);
		//margin:0 auto;
		padding-left: w(50);
		padding-right: w(50);

		.detail-head-ttl{
			width:w(780);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: w(19);

				}
				font-size: w(16);
				margin-bottom: w(14);
				opacity: 0.5;
			}
			h3{
				font-size: w(0);
				line-height: 1.2;
				span{
					font-size: w(72);
					display: inline-block;
					min-width: w(18);
					line-height: 1.2;
				}
			}
		}
		.detail-head-data{
			width:w(560);
			dl{
				display: flex;
				flex-wrap:wrap;
				font-size:w(14);
				dt{
					width:w(120);
					opacity: 0.3;
				}
				dd{
					width:w(440);
					margin-bottom: w(24);
				}
				border-top:1px solid #CBCBCB;
				border-bottom:1px solid #CBCBCB;
				padding-top:w(28);
				padding-bottom:w(8);

				margin-bottom: w(28);
			}
			p{
				font-size:w(14);
				line-height: 2;
			}
			margin-top: w(27);
			margin-bottom: w(90);

		}
		background:#F6F2ED;
		border-radius:0 0 w(40) w(40);
	}
	@media #{$mq-sp} {
		//display: flex;
		width:sw(390);
		padding-top: sw(168);
		padding-left: sw(16);
		padding-right: sw(16);
		
		//margin:0 auto;
		.detail-head-ttl{
			width:sw(358);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: sw(14);

				}
				font-size: sw(10);
				margin-bottom: sw(20);
			}
			h3{
				font-size: sw(32);
				line-height: 1.2;
				span{
					display: inline-block;
					min-width: sw(8);
					line-height: 1.2;
				}
			}
			margin-bottom: sw(58);
		}
		.detail-head-data{
			
			dl{
				display: flex;
				flex-wrap:wrap;
				font-size:sw(12);
				line-height: sw(22);
				dt{
					width:sw(90);
					opacity: 0.3;
				}
				dd{
					width:sw(260);
					margin-bottom: sw(15);
					    word-break: break-all;
				}
				border-top:1px solid #CBCBCB;
				border-bottom:1px solid #CBCBCB;
				padding-top:sw(20);
				padding-bottom:sw(0);

				margin-bottom: sw(16);
			}
			p{
				font-size:sw(12);
				line-height: sw(22);
			}
			padding-bottom: sw(40);

		}
		background:#F6F2ED;
		border-radius:0 0 sw(20) sw(20);
	}
}

.detail-container{
	@media #{$mq-pc} {
		background:#F7F2EC;
		border-radius:w(40) w(40) 0 0;
		&:after{
			position: absolute;
			position: absolute;
		    content: "";
		    background: #F7F2EC;
		    bottom: -20vw;
		    height: 20vw;
		    width: 100%;

		}
	}
	@media #{$mq-sp} {

		background:#F7F2EC;
		border-radius:sw(20) sw(20) 0 0;
		&:after{
			position: absolute;
			position: absolute;
		    content: "";
		    background: #F7F2EC;
		    bottom: -80vw;
		    height: 80vw;
		    width: 100%;

		}
	}
	
}

.detail-content{
	@media #{$mq-pc} {
		margin-top: w(770);
		padding-top: w(40);
		
		.detail-wide{
			img{
				width: 100%;

			}
			margin-bottom: w(82);
		}
		.detail-small{
			display: flex;
			justify-content:space-between;
			width:w(1360);
			margin:0 auto;
			img{
				width: w(672);

					border-radius: w(30);
					overflow: hidden;
			}
			margin-bottom: w(16);
		}
		.detail-split{
			display: flex;
			justify-content:space-between;
			width:w(1360);
			margin:0 auto w(82);
			img{
				width: w(672);
					border-radius: w(30);
					overflow: hidden;
			}
			div{
				width: w(672);
				font-size:w(14);
				line-height: 2;
			}
		}
		.detail-large{
			width:w(1360);
			margin:0 auto w(42);
			img{
				width:w(1360);
					border-radius: w(30);
					overflow: hidden;
			}
		}
	}
	@media #{$mq-sp} {
		margin-top: sw(367);
		padding-top: sw(16);
		.detail-wide{
			img{
				width: 100%;
			}
			margin-bottom: sw(32);
		}
		.detail-small{
			display: flex;
			justify-content:space-between;
			width:sw(358);
			margin:0 auto;
			img{
				width: sw(175);
				border-radius: sw(20);
				overflow: hidden;
			}
			margin-bottom: sw(12);
		}
		.detail-split{
			width:sw(358);
			margin:0 auto sw(32);
			img{
				width:sw(358);
				margin-bottom: sw(20);
				border-radius: sw(20);
				overflow: hidden;
			}
			div{
				width:sw(358);
				font-size:sw(12);
				line-height: sw(22);
			}
		}
		.detail-large{
			width:sw(358);
			margin:0 auto sw(20);
			img{
				width:sw(358);
				border-radius: sw(20);
				overflow: hidden;
			}
		}
	}
}
.detail-data{
	@media #{$mq-pc} {
		display: flex;
	    justify-content: right;
	    width:w(1360);
		margin:0 auto w(82);
		.detail-data-content{
			width:w(690);

			p{
				font-size: w(14);
				line-height: 2;
				margin-bottom: w(77);
				margin-left: w(50)
			}
			h4{
				font-size: w(24);
				line-height: w(54);
				padding-left: w(50);
			}
			dl{

				padding-top: w(40);
				padding-bottom: w(15);
				border-top:1px solid #CBCBCB;
				border-bottom:1px solid #CBCBCB;
				padding-left: w(50);
				font-size:w(14);
				display: flex;
				flex-wrap:wrap;
				dt{
					width:w(234);
					opacity: 0.5;
				}
				dd{
					width:w(404);
					margin-bottom: w(25);
				}
			}
		}
	}
	@media #{$mq-sp} {
	    width:sw(358);
		margin:0 auto sw(42);
		.detail-data-content{
			p{
				font-size: sw(12);
				line-height: sw(22);
				margin-bottom: w(40);
			}
			h4{
				font-size: sw(16);
				line-height: sw(48);
			}
			dl{

				padding-top: sw(20);
				padding-bottom: sw(6);
				border-top:1px solid #CBCBCB;
				border-bottom:1px solid #CBCBCB;
				font-size:sw(12);
				display: flex;
				flex-wrap:wrap;
				dt{
					width:sw(187);
					opacity: 0.5;
				}
				dd{
					width:sw(169);
					margin-bottom: sw(14);
					opacity: 0.8;
				}
			}
		}
	}
}
.detail-nav{
	@media #{$mq-pc} {
		display: flex;
	    justify-content: space-between;
	    width:w(1358);
		margin:0 auto w(220);
		.detail-nav-back{
			img{
				width: w(205);
				height:w(128);
				border-radius: w(30);
				overflow: hidden;
			}
			position: relative;
			span{
				font-size: w(14);
				position: absolute;
				right:w(-32);
				top:0;
				color:#999;
			}
		}
		.detail-nav-btn{
			border:1px solid rgba(0,0,0,0.3);
			border-radius: w(30);
			width:w(804);
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: w(28);
			position: relative;
			transition:all 0.2s ease-out;
			&:hover{
				color:#fff;
				background:rgba(0,0,0,1);
			}
		}
		.detail-nav-next{
			img{
				width: w(205);
				height:w(128);
				border-radius: w(30);
				overflow: hidden;
			}
			position: relative;
			span{
				font-size: w(14);
				position: absolute;
				left:w(-32);
				top:0;
				color:#999;
			}
		}
	}
	@media #{$mq-sp} {
		display: flex;
		flex-wrap:wrap;
	    justify-content: space-between;
	    width:sw(358);
		margin:0 auto sw(240);
		.detail-nav-back{
			img{
				width: sw(175);
				height:sw(109);
				border-radius: sw(20);
				overflow: hidden;
			}
			order: 2;


			position: relative;
			span{
				font-size: sw(11);
				position: absolute;
				left:0;
				bottom:sw(-16);
				color:#999;
			}
		}
		.detail-nav-btn{
			border:1px solid rgba(0,0,0,0.3);
			border-radius: sw(20);
			width:sw(358);
			line-height: sw(66);
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: sw(16);
			order: 1;
			margin-bottom: sw(20);
		}
		.detail-nav-next{
			img{
				width: sw(175);
				height:sw(109);
				border-radius: sw(20);
				overflow: hidden;
			}
			order: 3;


			position: relative;
			span{
				font-size: sw(11);
				position: absolute;
				right:0;
				bottom:sw(-16);
				color:#999;
			}
		}
	}

}