@function s($_size, $_base-width: 1440px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: strip-unit($size) * 1px;
  }
  @return $size;
}

@function w($_size, $_base-width: 1440px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: strip-unit($size) / strip-unit($_base-width) * 100vw;
  }
  @return $size;
}

@function sw($_size, $_base-width: 390px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: strip-unit($size) / strip-unit($_base-width) * 100vw;
  }
  @return $size;
}

