/*
@keyframes xxx {
  0%   {}

  50%  {}

  100% {}
  AAA
}
*/
 @keyframes circle {
          0% { stroke-dasharray: 0 366; }
          99.9%,to { stroke-dasharray: 366 366; }
        }
@keyframes bgloop {
  0%   {background-position: 0 bottom;}


  100% {background-position: 1000% bottom;}
}

@keyframes lineanim0 {
  0%   {top: 63%;}
  100% {top: 100%;}
}
@keyframes lineanim1 {
  0%{top:96.3%}
  9.99999%{top:100%}
  10%   {top: 63%;}
  100% {top: 96.3%;}
}
@keyframes lineanim2 {
  0%{top:92.6%}
  19.99999%{top:100%}
  20%   {top: 63%;}
  100% {top: 92.6%;}
}
@keyframes lineanim3 {
  0%{top:88.9%}
  29.99999%{top:100%}
  30%   {top: 63%;}
  100% {top: 88.9%;}
}
@keyframes lineanim4 {
  0%{top:85.2%}
  39.99999%{top:100%}
  40%   {top: 63%;}
  100% {top: 85.2%;}
}
@keyframes lineanim5 {
  0%{top:81.5%}
  49.99999%{top:100%}
  50%   {top: 63%;}
  100% {top: 81.5%;}
}
@keyframes lineanim6 {
  0%{top:77.8%}
  59.99999%{top:100%}
  60%   {top: 63%;}
  100% {top: 77.8%;}
}
@keyframes lineanim7 {
  0%{top:74.1%}
  69.99999%{top:100%}
  70%   {top: 63%;}
  100% {top: 74.1%;}
}
@keyframes lineanim8 {
  0%{top:70.4%}
  79.99999%{top:100%}
  80%   {top: 63%;}
  100% {top: 70.4%;}
}
@keyframes lineanim9 {
  0%{top:66.7%}
  89.99999%{top:100%}
  90%   {top: 63%;}
  100% {top: 66.7%;}
}

@keyframes lineanim_sp1 {
  0%   {transform: translateY(-35.5vw);}
  100% {transform: translateY(0);}
}
@keyframes lineanim_sp2 {
  0%   {transform: translateY(-3.55vw);}
  90% {transform: translateY(-35.5vw);}
  90.0001%{transform: translateY(0);}
  100% {transform: translateY(-3.55vw);}
}
@keyframes lineanim_sp3 {
  0%   {transform: translateY(-7.1vw);}
  80% {transform: translateY(-35.5vw);}
  80.0001%{transform: translateY(0);}
  100%   {transform: translateY(-7.1vw);}

}
@keyframes lineanim_sp4 {
  0%   {transform: translateY(-10.65vw);}
  70% {transform: translateY(-35.5vw);}
  70.0001%{transform: translateY(0);}
  100%   {transform: translateY(-10.65vw);}
}
@keyframes lineanim_sp5 {
  0%   {transform: translateY(-14.2vw);}
  60% {transform: translateY(-35.5vw);}
  60.0001%{transform: translateY(0);}
  100%   {transform: translateY(-14.2vw);}
}
@keyframes lineanim_sp6 {
  0%   {transform: translateY(-17.75vw);}
  50% {transform: translateY(-35.5vw);}
  50.0001%{transform: translateY(0);}
  100%   {transform: translateY(-17.75vw);}
}
@keyframes lineanim_sp7 {
  0%   {transform: translateY(-21.3vw);}
  40% {transform: translateY(-35.5vw);}
  40.0001%{transform: translateY(0);}
  100%   {transform: translateY(-21.3vw);}
}
@keyframes lineanim_sp8 {
  0%   {transform: translateY(-24.85vw);}
  30% {transform: translateY(-35.5vw);}
  30.0001%{transform: translateY(0);}
  100%   {transform: translateY(-24.85vw);}
}
@keyframes lineanim_sp9 {
  0%   {transform: translateY(-28.4vw);}
  20% {transform: translateY(-35.5vw);}
  20.0001%{transform: translateY(0);}
  100%   {transform: translateY(-28.4vw);}
}
@keyframes lineanim_sp10 {
  0%   {transform: translateY(-31.95vw);}
  10% {transform: translateY(-35.5vw);}
  10.0001%{transform: translateY(0);}
  100% {transform: translateY(-31.95vw);}
}

@keyframes cloudanim {
  0%   {transform: translateX(0);}
  50%   {transform: translateX(-100vw);}
  50.001%   {transform: translateX(100vw);}
  100% {transform: translateX(0);}
}
@keyframes sunanim {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes bgloop {
  0%   {background-position: 0 bottom;}
  100% {background-position: 1000% bottom;}
}


@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}


@keyframes simplebeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
