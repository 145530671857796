.member {
    @media #{$mq-pc} {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 120;
        background: #000;
        overflow: auto;
        opacity: 0;
        pointer-events:none;
        transition:all 0.4s linear;

        opacity: 0;
        transition:ease-out 0.4s all;
        pointer-events:none;
        transform:translateY(1vh);
        filter:blur(1vh);
        &.-show{
            opacity: 1;
            transition:ease-out 0.4s 0.2s all;
            transform:translateY(0vh);
            filter:blur(0vh);
            pointer-events:auto;
            .member-header .member-header-menubtn{
                pointer-events:auto;
            }
        }

        /*
        &.-show{
            opacity: 1;
            pointer-events:auto;
            .member-header .member-header-menubtn{
                pointer-events:auto;
            }
        }
        */
    }
    @media #{$mq-sp} {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 120;
        background: #000;
        overflow: auto;
        opacity: 0;
        pointer-events:none;
        transition:all 0.4s linear;

        opacity: 0;
        transition:ease-out 0.4s all;
        pointer-events:none;
        transform:translateY(1vh);
        filter:blur(1vh);
        &.-show{
            opacity: 1;
            transition:ease-out 0.4s 0.2s all;
            transform:translateY(0vh);
            filter:blur(0vh);
            pointer-events:auto;
            .member-header .member-header-menubtn{
                pointer-events:auto;
            }
        }
        /*
        &.-show{
            opacity: 1;
            pointer-events:auto;
            .member-header-menubtn {
                pointer-events:auto;
            }
        }
        */
    }
}
.memberlayer{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 0%;
    background: #000;
    z-index: 119;
    transition:cubic-bezier(0.23, 1, 0.32, 1) 0.4s all;
    &.-on{
        height: 100%;
    }
}

.member-header {
    @media #{$mq-pc} {
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: right;
        padding-top: w(32);
        padding-left: w(40);
        padding-right: w(42);
        z-index: 3;
        pointer-events:none;
        .member-header-menubtn {
            width: w(104);
            height: w(104);
            border-radius: 50%;
            border: 1px solid #F7F2EC;
            text-align: center;
            line-height: w(104);
            color: #F7F2EC;
            z-index: 3;
            span {
                font-size: w(14);
                font-weight: bold;
            }
            cursor: pointer;
            &:hover {
                opacity: 0.6;
            }
        }
    }
    @media #{$mq-sp} {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: right;
        padding-top: sw(126);
        padding-top: sw(26);
        padding-left: sw(16);
        padding-right: sw(16);
            z-index: 3;

            pointer-events:none;
        .member-header-menubtn {
            width: sw(88);
            height:sw(88);
            border-radius: 50%;
            border:1px solid #F7F2EC;
            text-align: center;
            line-height: sw(88);
            color:#F7F2EC;
            z-index: 3;
            span{
                font-size: sw(11);
                font-weight: bold;
            }
        }
    }
}

.member-content {
    @media #{$mq-pc} {
        padding-top: w(32);
        width: w(1360);
        margin: 0 auto w(192);
        display: flex;
        .member-content-img div {
            border-radius: w(30);
            overflow: hidden;
            width: w(738);
            position: sticky;
            top: w(32);
            img {
                width: w(738);
            }
        }
        .member-content-data {
            width: w(520);
            color: #fff;
            padding-top: w(33);
            margin-left: w(49);
            .member-content-data-nav {
                opacity: 0.8;
                margin-bottom: w(123);
                .member-content-data-nav-prev {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    font-size: w(16);
                    &:after {
                        content: '';
                        width: w(8);
                        height: w(8);
                        border-radius: 50%;
                        background: #fff;
                        display: block;
                        margin-left: w(16);
                    }
                    cursor:pointer;
                    &:hover{
                        opacity: 0.4;
                    }
                    transition:0.1s linear all;
                    margin-bottom: w(10);
                }
                .member-content-data-nav-next {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    font-size: w(16);

                    cursor:pointer;
                    &:hover{
                        opacity: 0.4;
                    }
                    transition:0.1s linear all;
                    &:before {
                        content: '';
                        width: w(8);
                        height: w(8);
                        border-radius: 50%;
                        background: #fff;
                        display: block;
                        margin-right: w(16);
                    }
                }
            }
            h1 {
                font-size: w(90);
                opacity: 0.8;
                line-height: 1.0;
                margin-bottom: w(16);
            }
            h3 {
                font-size: w(20);
                opacity: 0.8;
                margin-bottom: w(22);
            }
            h4 {
                opacity: 0.8;
                font-size: w(16);
                &:before {
                    content: '';
                    width: w(8);
                    height: w(8);
                    border-radius: 50%;
                    background: #fff;
                    display: block;
                    margin-bottom: w(20);
                }
                margin-bottom: w(52);
            }
            .member-content-data-sns {
                //display: flex;
                li {
                    opacity: 0.8;
                    font-size: w(16);
                    margin-bottom: w(16);
                    img{
                        position: relative;
                        top:w(1);
                        margin-left: w(12);
                        width: w(16);
                    }
                }
                margin-bottom: w(98);
            }
            h2 {
                opacity: 0.8;
                font-size: w(23);
                line-height: 1.4;
                margin-bottom: w(30);
            }
            p {
                opacity: 0.8;
                font-size: w(14);
                line-height: w(26);
            }
        }
    }
    @media #{$mq-sp} {
        //padding-top: sw(249);
        padding-top: sw(145);
        width: sw(358);
        margin: 0 auto w(192);
        .member-content-img div {
            border-radius: sw(20);
            overflow: hidden;
            width: sw(358);
            img {
                width: sw(358);
            }
        }
        .member-content-data {
            width: sw(358);
            color: #fff;
            padding-top: sw(33);
            .member-content-data-nav {
                position: absolute;
                top:sw(50);
                left:sw(17);
                opacity: 0.8;
                .member-content-data-nav-prev {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    font-size: sw(13);
                    &:after {
                        content: '';
                        width: sw(8);
                        height: sw(8);
                        border-radius: 50%;
                        background: #fff;
                        display: block;
                        margin-left: sw(16);
                    }
                    margin-bottom: sw(13);
                }
                .member-content-data-nav-next {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    font-size: sw(13);
                    &:before {
                        content: '';
                        width: sw(8);
                        height: sw(8);
                        border-radius: 50%;
                        background: #fff;
                        display: block;
                        margin-right: w(13);
                    }
                }
            }
            h1 {
                font-size: sw(56);
                opacity: 0.8;
                line-height: 1.0;
                margin-bottom: sw(15);
            }
            h3 {
                font-size: sw(16);
                opacity: 0.8;
                margin-bottom: sw(20);
            }
            h4 {
                opacity: 0.8;
                font-size: sw(16);
                &:before {
                    content: '';
                    width: sw(8);
                    height: sw(8);
                    border-radius: 50%;
                    background: #fff;
                    display: block;
                    margin-bottom: sw(20);
                }
                margin-bottom: sw(24);
            }
            .member-content-data-sns {
                //display: flex;
                li {
                    opacity: 0.8;
                    font-size: sw(20);
                    margin-bottom: sw(12);
                    img{
                        position: relative;
                        top:sw(3);
                        margin-left: sw(6);
                        width: sw(18);
                    }
                }
                margin-bottom: sw(77);
            }
            h2 {
                opacity: 0.8;
                font-size: sw(23);
                line-height: 1.2;
                margin-bottom: sw(32);
            }
            p {
                opacity: 0.8;
                font-size: sw(14);
                line-height: sw(26);
            }
        }
    }
}

.member-works {
    @media #{$mq-pc} {
    width: w(1360);
    margin: 0 auto;
    margin-bottom: w(221);
    padding-top: w(20);
    border-top: 1px solid #CBCBCB;

    .member-works-ttl {
        display: flex;
        align-items: center;
        font-size: w(14);
        color: #fff;

        &:before {
            content: '';
            width: w(8);
            height: w(8);
            border-radius: 50%;
            background: #fff;
            display: block;
            margin-right: w(19);

        }

        margin-bottom: w(56);
    }
    .member-works-content{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content:space-between;
            .member-works-large{
                width: 100%;
                img{
                    width: 100%;
                    border-radius: w(30);
                    overflow: hidden;
                }

                margin-bottom: w(48);
            }
            .member-works-small{
                width: w(672);
                img{
                    width: w(672);
                    border-radius: w(30);
                    overflow: hidden;
                }
                margin-bottom: w(48);
            }
            .member-works-info{
                padding-top:w(24);
                padding-left: w(10);
                .member-works-info-head{
                    display: flex;
                    .member-works-info-num{
                        font-size:w(14);
                        color:#fff;
                        margin-right: w(18);
                        opacity: 0.4;
                    }
                    .member-works-info-ttl{
                        font-size:w(22);
                        color:#fff;
                        font-weight: bold;
                    }
                    margin-bottom: w(12);
                }
                .member-works-info-data{
                    display: flex;
                        align-items:center;
                        opacity: 0.4;

                    .member-works-info-category{
                        font-size:w(14);
                        color:#fff;
                    }
                    .member-works-info-year{
                        font-size:w(14);
                        color:#fff;
                        display: flex;
                        align-items:center;
                        &:before{
                            content:'';
                            width:w(8);
                            height:w(8);
                            border-radius: 50%;
                            background: #fff;
                            display: block;
                            margin-left: w(14);
                            margin-right: w(14);

                        }
                    }
                }
            }
        }
    }
    @media #{$mq-sp} {
    width: sw(358);
    margin: 0 auto;
    margin-bottom: sw(221);
    padding-top:sw(20);
    border-top: 1px solid #fff;

    .member-works-ttl {
        display: flex;
            align-items:center;
            font-size: sw(10);
            color:#fff;
            &:before{
                content:'';
                width:sw(8);
                height:sw(8);
                border-radius: 50%;
                background: #fff;
                display: block;
                margin-right: sw(14);

            }
            margin-left: sw(10);
            margin-bottom: sw(30);
    }
    .member-works-content{
           display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content:space-between;
            .member-works-large{
                width: 100%;
                img{
                    width: 100%;
                    border-radius: sw(20);
                    overflow: hidden;
                }
                margin-bottom: sw(43);
            }
            .member-works-small{
                width: sw(358);
                img{
                    width: sw(358);
                    border-radius: sw(20);
                    overflow: hidden;
                }
                margin-bottom: sw(43);
            }
            .member-works-info{
                padding-top:sw(16);
                padding-left: sw(10);

                .member-works-info-head{
                    display: flex;
                    .member-works-info-num{
                        font-size:sw(14);
                        color:#999;
                        margin-right: sw(18);
                    }
                    .member-works-info-ttl{
                        font-size:sw(16);
                        color:#fff;
                        opacity: 0.8;
                        font-weight: bold;

                    }
                    margin-bottom: sw(12);
                }
                .member-works-info-data{
                    display: flex;
                        align-items:center;

                    .member-works-info-category{
                        font-size:sw(11);
                        color:#999;
                    }
                    .member-works-info-year{
                        font-size:sw(11);
                        color:#999;
                        display: flex;
                        align-items:center;
                        &:before{
                            content:'';
                            width:sw(6);
                            height:sw(6);
                            border-radius: 50%;
                            background: #999;
                            display: block;
                            margin-left: sw(11);
                            margin-right: sw(11);

                        }
                    }
                }
            }
        }
    }
}