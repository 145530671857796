
.base{
	@media #{$mq-pc} {
		
		@include clearfix();
		transform:rotate(45deg);
	}
	@media #{$mq-sp} {

	}
}
