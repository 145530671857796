.index-top{
	@media #{$mq-pc} {
		//height:w(855);
		//max-height: calc(100vh - 9vw);
		position: relative;
		margin:0 auto;
		width: w(1360);
		h1{
			//position: absolute;
			bottom: w(32px);
			width: 100%;
			padding-top: w(22px);
			img{
				width:100%;
			}
			p{
				display: inline-block;
			}
			font-size: w(196);
			letter-spacing: w(-4);
			white-space: nowrap;

		    display: flex;
		    justify-content: space-between;
		}
		.index-content{
			position: relative;
			width: 100%;
			height: 100%;
			background:url(../img/index-top.png) center 63%;
			background-size:94.44444vw 45.34722vw;
			transform:scale(1.3);
			opacity: 0;
			transition:all 1.6s ease-out;
			&.-show{
				transform:scale(1);
				opacity: 1;
			}
		}
		.stage{
			
			
			position: relative;
			margin-top:w(157);
			width:w(1360);
			height:w(653);
			position: relative;
			//max-height: calc(100vh - 24vw);
			overflow: hidden;
			border:2px solid #000;
			border-radius: w(240) w(240) 0 0;
			canvas{
				width:100%;
				height: 100%;
				position: relative;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform:translate(-50%,-50%);
			}
			.line{
				width:100%;
				position: absolute;
				//top:45vw;
				top:63%;
				height: w(2);
				background: #000;
				transform:translate3d(0,0,0);
			}
			
			/*
			.line1{
				top: 63%;
				animation:lineanim0 12.0s linear infinite;
			}
			.line2{
				top:96.3%;
				animation:lineanim1 12.0s linear infinite;
			}
			.line3{
				top:92.6%;
				animation:lineanim2 12.0s linear infinite;
				
			}
			.line4{
				top:88.9%;
				animation:lineanim3 12.0s linear infinite;
			}
			.line5{
				top:85.2%;
				animation:lineanim4 12.0s linear infinite;
				
			}
			.line6{
				top:81.5%;
				animation:lineanim5 12.0s linear infinite;
				
			}
			.line7{
				top:77.8%;
				animation:lineanim6 12.0s linear infinite;
				
			}
			.line8{
				top:74.1%;
				animation:lineanim7 12.0s linear infinite;
				
			}
			.line9{
				top:70.4%;
				animation:lineanim8 12.0s linear infinite;
				
			}
			.line10{
				top:66.7%;
				animation:lineanim9 12.0s linear infinite;
			}
			*/
			.line1{
				top: 63%;
				animation:lineanim0 20.0s ease-in infinite;
			}
			.line2{
				top: 63%;
				animation:lineanim0 20.0s -2s ease-in infinite;
			}
			.line3{
				top: 63%;
				animation:lineanim0 20.0s -4s ease-in infinite;
				
			}
			.line4{
				top: 63%;
				animation:lineanim0 20.0s -6s ease-in infinite;
			}
			.line5{
				top: 63%;
				animation:lineanim0 20.0s -8s ease-in infinite;
				
			}
			.line6{
				top: 63%;
				animation:lineanim0 20.0s -10s ease-in infinite;
				
			}
			.line7{
				top: 63%;
				animation:lineanim0 20.0s -12s ease-in infinite;
				
			}
			.line8{
				top: 63%;
				animation:lineanim0 20.0s -14s ease-in infinite;
				
			}
			.line9{
				top: 63%;
				animation:lineanim0 20.0s -16s ease-in infinite;
				
			}
			.line10{
				top: 63%;
				animation:lineanim0 20.0s -18s ease-in infinite;
			}
			
			.cloud1{
				position: absolute;
				top:w(100);
				left:w(-60);
				width:w(409);
				display: block;
				animation:cloudanim 48.0s linear infinite;
			}
			.cloud2{
				position: absolute;
				top:w(140);
				left:w(160);
				width:w(273);
				display: block;
				animation:cloudanim 45.0s linear infinite;
			}
			.cloud3{
				position: absolute;
				top:w(80);
				left:w(860);
				width:w(274);
				display: block;
				animation:cloudanim 51.0s linear infinite;
			}
			.cloud4{
				position: absolute;
				top:w(160);
				left:w(1120);
				width:w(155);
				display: block;
				animation:cloudanim 54.0s linear infinite;
			}
			.sun{
				position: absolute;
				top:w(-120);
				left:w(550);
				width:w(229);
				animation:sunanim 18.0s linear infinite;
			}

		}
	}

	@media #{$mq-sp} {
		position: relative;
		margin:0 auto sw(77);
		padding-top: sw(127);
		width: sw(358);
		.index-content{
			height:sw(337);
			//background:red;
			margin-bottom: sw(10);
			transform:scale(1);
			background:url(../img/index-top-sp.png) center center/contain;
			border-radius: sw(120) sw(120) 0 0;
			opacity: 0;
			transition:all 1.6s 0.6s ease-out;
			&.-show{
				transform:scale(1);
				opacity: 1;
			}
		}
		.stage{
			margin-top:sw(57);
			width:sw(355);
			height:sw(337);
			position: relative;
			overflow: hidden;
			border:1px solid #000;
			//border:none;
			border-radius: sw(120) sw(120) 0 0;
			#stage{
				position: relative;
				overflow: hidden;
				//border:1px solid #000;
				border-radius: sw(120) sw(120) 0 0;
				//background: red;
				transform:translateZ(1);
			}
			canvas{
				width:sw(355);
				height:sw(337);
				position: relative;
				z-index: 1;
				
			    left: 50%;
			    transform: translateX(-50%);
			}
			.line{
				width:100%;
				position: absolute;
				top:87vw;
				height: w(2);
				background: #000;

			}
			/*
			.line1{
				animation:lineanim_sp1 12.0s linear infinite;
			}
			
			.line2{
				animation:lineanim_sp2 12.0s linear infinite;
			}
			
			.line3{
				animation:lineanim_sp3 12.0s linear infinite;
				
			}

			.line4{
				animation:lineanim_sp4 12.0s linear infinite;
			}
			.line5{
				animation:lineanim_sp5 12.0s linear infinite;
				
			}

			.line6{
				animation:lineanim_sp6 12.0s linear infinite;
				
			}
			.line7{
				animation:lineanim_sp7 12.0s linear infinite;
				
			}

			.line8{
				animation:lineanim_sp8 12.0s linear infinite;
				
			}
			.line9{
				animation:lineanim_sp9 12.0s linear infinite;
				
			}
			.line10{
				animation:lineanim_sp10 12.0s linear infinite;
			}
			*/
			.line1{
				animation:lineanim_sp1 20.0s ease-in infinite;
			}
			
			.line2{
				animation:lineanim_sp1 20.0s -2s ease-in infinite;
			}
			
			.line3{
				animation:lineanim_sp1 20.0s -4s ease-in infinite;
				
			}

			.line4{
				animation:lineanim_sp1 20.0s -6s ease-in infinite;
			}
			.line5{
				animation:lineanim_sp1 20.0s -8s ease-in infinite;
				
			}

			.line6{
				animation:lineanim_sp1 20.0s -10s ease-in infinite;
				
			}
			.line7{
				animation:lineanim_sp1 20.0s -12s ease-in infinite;
				
			}

			.line8{
				animation:lineanim_sp1 20.0s -14s ease-in infinite;
				
			}
			.line9{
				animation:lineanim_sp1 20.0s -16s ease-in infinite;
				
			}
			.line10{
				animation:lineanim_sp1 20.0s -18s ease-in infinite;
			}
			
			.cloud1{
				position: absolute;
				top:sw(100);
				left:sw(-20);
				width:sw(200);
				display: block;
				animation:cloudanim 48.0s linear infinite;
			}
			.cloud2{
				position: absolute;
				top:sw(70);
				left:sw(80);
				width:sw(160);
				display: block;
				animation:cloudanim 45.0s linear infinite;
			}
			.cloud3{
				position: absolute;
				top:sw(80);
				left:sw(180);
				width:sw(160);
				display: block;
				animation:cloudanim 51.0s linear infinite;
			}
			.cloud4{
				position: absolute;
				top:sw(80);
				left:sw(320);
				width:sw(80);
				display: block;
				animation:cloudanim 54.0s linear infinite;
			}
			.sun{
				position: absolute;
				top:sw(-60);
				left:sw(120);
				width:sw(120);
				animation:sunanim 18.0s linear infinite;
			}
		}

		h1{
			width: 100%;
			img{
				width:100%;
			}

			font-size: sw(93);
			letter-spacing: sw(-1);
			white-space: nowrap;
			display: flex;
			flex-wrap:wrap;
			//justify-content:space-between;
			line-height: 1;
			p{
				margin-bottom: sw(-36);
				&:first-child{
					margin-right: sw(28);
				}
			}
		}
	}
}

.index-about{
	@media #{$mq-pc} {
		margin:w(60) auto 0;
		width: w(1360);
		display: flex;
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		padding-bottom: w(183);
		display:flex;
		.index-about-data{
			width:w(675);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: w(19);

				}
				margin-bottom: w(22);
			}
			h3{
				position: relative;
				img{
					width: w(310);
					position: absolute;
				}
				font-size:w(108);
				line-height: 0.8;
				margin-top: w(10);
				margin-bottom: w(40)
			}
			a{
				font-size: w(20);
				border-bottom: 1px solid #CBCBCB;
				display: inline-block;
				padding-bottom: w(6);
				padding-left: w(4);
				&:after{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: inline-block;
					margin-left: w(60);
					position: relative;
					top:w(-3);
					left: w(-6);

				}
				/*
				width: w(104);
				height:w(104);
				border:1px solid #CBCBCB;
				display: flex;
				justify-content:center;
				align-items:center;
				border-radius: 50%;
				font-size: w(14);
				position: relative;
				a:after{
					display: block;
					content:"";
					position: absolute;
					top:0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				svg{
					width: 100%;
					height: 100%;
					transform:scale(1.06) rotate(-90deg);
			          position: absolute;
			          top: 0;
			          left: 0;
			          pointer-events:none;
				}
		        circle {
		          fill: transparent;
		          stroke: rgba(0,0,0,1);
		          stroke-width: 1;
		          stroke-dasharray: 0 366;
		        }
		        
				transition:0.1s linear all;
				&:hover{
					circle{
						animation: circle 2s 1 forwards;
					}
				}
				*/
			
			}
		}
		.index-about-content{
			p{
				font-size:w(24);
				line-height: w(52);
				font-weight: bold;
			}
			padding-top: w(34)
		}
	}

	@media #{$mq-sp} {
		margin:0 auto;
		width: sw(358);
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		padding-bottom: sw(90);
		//display:flex;
		.index-about-data{
			//width:w(675);
			h2{
				margin-left: sw(8);
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: sw(14);

				}
				font-size: sw(10);
				margin-bottom: sw(18);
			}
			h3{
				margin-left: sw(8);
				img{
					width: sw(203);
				}
				font-size:sw(66);
				line-height: 0.8;
				margin-bottom: sw(38);
			}
		}
		.index-about-content{
			p{
				margin-left: sw(8);
				font-size:sw(16);
				line-height: sw(35);
				font-weight: bold;
				margin-bottom: sw(40);
			}
			.index-about-more{

				font-size: sw(14);
				border-bottom: 1px solid #CBCBCB;
				display: inline-block;
				padding-bottom: sw(6);
				padding-left: sw(4);
				line-height: 1;
				&:after{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: inline-block;
					margin-left: sw(37);
					position: relative;
					left: sw(-4);
				}
			}
		}
	}
}
.index-works{
	@media #{$mq-pc} {
		padding-top: w(20);
		margin:0 auto;
		width: w(1360);
		border-top:1px solid #CBCBCB;
		padding-bottom: w(72);
		.index-works-data{
			width:w(675);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: w(19);

				}
				margin-bottom: w(24);
			}

			h3{
				img{
					width: w(326);
				}
				font-size:w(108);
				line-height: 0.8;
			}
		}
		.index-works-content{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content:space-between;
			.index-works-large{
				&:hover{
					.index-works-img{
						&:after{
							border:w(4) solid #B1AAA2;
						}
						img{
							transform:scale(1);
						}
					}
				}

				.index-works-img{
					position: relative;
					border-radius: w(30);
					overflow: hidden;
					&:after{
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
						content:'';
						transition:0.2s ease-out all;
						border:w(0) solid #B1AAA2;
						border-radius: w(30);
					}
				}
				width: 100%;
				img{
					width: 100%;
					border-radius: w(30);
					overflow: hidden;
					transition:all 0.4s ease-out;
					transform:scale(1.05);
					//border:w(10) solid #000;
				}

				margin-bottom: w(48);
			}
			.index-works-small{
				&:hover{
					.index-works-img{
						&:after{
							border:w(4) solid #B1AAA2;
						}
						img{
							transform:scale(1.05);
						}
					}
				}

				.index-works-img{
					position: relative;
					border-radius: w(30);
					overflow: hidden;
					&:after{
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
						content:'';
						transition:0.2s ease-out all;
						border:w(0) solid #B1AAA2;
						border-radius: w(30);
					}
				}
				width: w(672);
				img{
					width: w(672);
					border-radius: w(30);
					overflow: hidden;
					transition:all 0.4s ease-out;
				}
				margin-bottom: w(50);
			}
			.index-works-info{
				padding-top:w(24);
				padding-left: w(10);
				.index-works-info-head{
					display: flex;
					.index-works-info-num{
						font-size:w(14);
						color:#999;
						margin-right: w(18);
					}
					.index-works-info-ttl{
						font-size:w(22);
						color:#000;
						font-weight: bold;
					}
					margin-bottom: w(18);
				}
				.index-works-info-data{
					display: flex;
						align-items:center;

					.index-works-info-category{
						font-size:w(14);
						color:#999;
					}
					.index-works-info-year{
						font-size:w(14);
						color:#999;
						display: flex;
						align-items:center;
						&:before{
							content:'';
							width:w(8);
							height:w(8);
							border-radius: 50%;
							background: #999;
							display: block;
							margin-left: w(14);
							margin-right: w(14);

						}
					}
				}
			}
		}
		.index-works-btn{
			border:1px solid rgba(0,0,0,0.3);
			text-align: center;
			line-height: w(128);
			border-radius: w(30);
			margin-bottom: w(36);
			display: block;
			transition:all 0.2s ease-out;
			&:hover{
				color:#fff;
				background:rgba(0,0,0,1);
			}
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				height:w(128);
				font-size: w(28);
				img{
					width: w(131);
				}
			}
		}
		position: relative;
		.index-works-btn2{
			position: absolute;
			top:w(100);
			right: 0;
			text-align: center;
			display: block;
			transition:all 0.2s ease-out;
			border-bottom: 1px solid #CBCBCB;
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				font-size: w(20);
				line-height: w(30);
				&:after{
					content:'';
					height: w(8);
					width:w(8);
					background: #000;
					display: block;
					margin-left: w(16);
					border-radius: 50%;
				}
			}
		}
	}

	@media #{$mq-sp} {
		padding-top: sw(16);
		margin:0 auto;
		width: sw(358);
		border-top:1px solid #CBCBCB;
		padding-bottom: sw(36);
		.index-works-data{
			width:sw(358);

			h2{
				margin-left: sw(8);
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: sw(14);

				}
				font-size: sw(10);
				margin-bottom: sw(24);
			}
			h3{
				margin-left: sw(8);
				img{
					width: sw(212);
				}
				font-size:sw(66);
				line-height: 0.8;
				margin-bottom: sw(32);
			}
		}
		.index-works-content{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content:space-between;
			.index-works-large{
				width: 100%;
				img{
					width: 100%;
					border-radius: sw(20);
					overflow: hidden;
				}
				margin-bottom: sw(43);
			}
			.index-works-small{
				width: sw(358);
				img{
					width: sw(358);
					border-radius: sw(20);
					overflow: hidden;
				}
				margin-bottom: sw(43);
			}
			.index-works-info{
				padding-top:sw(16);
				padding-left: sw(10);
				.index-works-info-head{
					display: flex;
					.index-works-info-num{
						font-size:sw(14);
						color:#999;
						margin-right: sw(18);
					}
					.index-works-info-ttl{
						font-size:sw(16);
						color:#000;
						font-weight: bold;
					}
					margin-bottom: sw(12);
				}
				.index-works-info-data{
					display: flex;
						align-items:center;

					.index-works-info-category{
						font-size:sw(11);
						color:#999;
					}
					.index-works-info-year{
						font-size:sw(11);
						color:#999;
						display: flex;
						align-items:center;
						&:before{
							content:'';
							width:sw(6);
							height:sw(6);
							border-radius: 50%;
							background: #999;
							display: block;
							margin-left: sw(11);
							margin-right: sw(11);

						}
					}
				}
			}
		}
		.index-works-btn{
			border:1px solid rgba(0,0,0,0.3);
			text-align: center;
			line-height: sw(66);
			border-radius: sw(20);
			margin-bottom: sw(24);
			display: block;
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				height:sw(66);
				font-size:sw(16);
			}
		}
		position: relative;
		.index-works-btn2{
			position: absolute;
			top:sw(75);
			right: 0;
			text-align: center;
			display: block;
			transition:all 0.2s ease-out;
			border-bottom: 1px solid #CBCBCB;
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				font-size: sw(14);
				line-height: sw(24);
				padding-left: sw(8);
				padding-right: sw(4);
				&:after{
					content:'';
					height: sw(8);
					width:sw(8);
					background: #000;
					display: block;
					margin-left: sw(8);
					border-radius: 50%;
				}
			}
		}
	}
}

.index-company{
	@media #{$mq-pc} {
		margin:0 auto;
		width: w(1360);
		display: flex;
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.index-company-data{
			width:w(590);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: w(19);

				}
				margin-bottom: w(22);
			}

			h3{
				img{
					width: w(500);
				}
				letter-spacing: w(-2);
				font-size:w(108);
				line-height: 0.8;
			}
		}
		.index-company-content{
			width:w(772);
			padding-bottom: w(154);
			padding-top: w(48);
			dl{
				width:w(772);
				border-bottom:1px solid #CBCBCB;

				&:last-child{
					//border-bottom:none;
				}
				display: flex;
				line-height: w(82);
				font-size: w(14);
				dt{
					padding-left: w(20);
					width:w(155);
					padding-top: w(16);
					padding-bottom: w(16);

					line-height: w(47);
				}
				dd{
					line-height: w(47);
					padding-top: w(16);
					padding-bottom: w(16);
				}

			}

		}
	}
	
	@media #{$mq-sp} {
		padding-top: sw(16);
		margin:0 auto;
		width: sw(358);
		border-top:1px solid #CBCBCB;
		//padding-bottom: sw(136);
		.index-company-data{
			width:sw(358);
			h2{
				margin-left: sw(8);
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: sw(14);

				}
				font-size: sw(10);
				margin-bottom: sw(24);
			}
			h3{
				margin-left: sw(8);
				img{
					width: sw(325);
				}
				font-size:sw(66);
				line-height: 0.8;
				margin-bottom: sw(65);
			}
		}
		.index-company-content{
			//width:w(772);
			padding-bottom: sw(154);
			border-top:1px solid #CBCBCB;
			dl{
				//width:w(772);
				
				border-bottom:1px solid #CBCBCB;

				&:last-child{
					//border-bottom:none;
				}
				//display: flex;
				line-height: sw(46);
				font-size: sw(12);
				padding-top: sw(20);
				padding-bottom: sw(20);
				dt{
					padding-left: sw(20);
					width:sw(155);

					line-height: sw(50);
				}
				dd{
					padding-left: sw(20);
					line-height: sw(42);
				}

			}

		}
	}
}