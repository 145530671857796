.about-top{
	@media #{$mq-pc} {
		padding-top: w(182);
		text-align: center;
		padding-bottom: w(90);
		position: relative;
		h1{
			img{
				width: w(486);
			}
			margin-bottom: w(-20);
			position: relative;
			z-index: 1;
			font-size: w(166);
			line-height: 0.8;
		}
		p{
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			z-index: 1;
		}
		height: 100vh;
		&:after{
			content:'';
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			display: block;
			background:url(../img/about-top.png) bottom center/cover;
			animation:bgloop 160s linear infinite;
		}
		background:url(../img/about-top-bg.png) bottom center/cover;
		margin-bottom: w(62);
	}
	@media #{$mq-sp} {
		padding-top: sw(234);
		text-align: center;
		padding-bottom: w(90);
		position: relative;
		h1{
			img{
				width: sw(241);
			}
			margin-bottom: sw(-10);
			position: relative;
			z-index: 1;
			font-size: sw(82);
			line-height: 0.8;
		}
		p{
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			position: relative;
			z-index: 1;
		}
		height: sw(600);

		&:after{
			content:'';
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			display: block;
			background:url(../img/about-top.png) bottom center/cover;
			animation:bgloop 160s linear infinite;
			transform:translateZ(0);
		}
		background:url(../img/about-top-bg.png) bottom center/cover;

		//background:url(../img/about-top.png) bottom center/cover;
		//animation:bgloop 160s linear infinite;
		margin-bottom: sw(20);
	}
}
.about-vision{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto;
		margin-bottom: w(184);
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.about-vision-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);

			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(40);
		}
		.about-vision-content{
			display: flex;
			.about-vision-content-ttl{
				width:w(683);
				h1{
					font-size:w(0);
					margin-left:w(127);
					span{
						font-size:w(76);
						line-height: 1.1;
					}
				}
			}
			.about-vision-content-text{
				p{
					font-size:w(14);
					line-height: 2;
					width:w(472);
				}
			}
		}
	}

	@media #{$mq-sp} {
		width:sw(358);
		margin:0 auto;
		margin-bottom: sw(70);
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		.about-vision-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-bottom: sw(20);
		}
		.about-vision-content{
			.about-vision-content-ttl{
				h1{
					font-size:sw(0);
					white-space: nowrap;
					margin-bottom: sw(76);
					span{
						line-height: 1.1;
						font-size:sw(52);
					}
				}
			}
			.about-vision-content-text{
				p{
					font-size:sw(12);
					line-height: sw(22);
				}
			}
		}
	}
}
.about-mind{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto;
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		//height:400vh;
		.about-mind-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(40);
		}
		.about-mind-container{
			display: flex;
		}
		.about-mind-content{
			width:w(501);
			margin-left: w(143);
			h1{
				margin-bottom: w(12);
				position: relative;
				span.-no{
					opacity: 0.3;
					position: absolute;
					top:w(-18);
					left: w(-22);
					font-size: w(16);
				}
			}
			h4{
				font-size: w(24);
				line-height: 2;
			}
			p{
				font-size: w(15);
				line-height: 2;
			}
		}
		position: relative;
		#stage{
			margin-top:35vh;
			margin-bottom:0vh;

			canvas{
				position: absolute;
				top:0;
				transform:translate(0,calc(-50% + 50vh));
			}
			ul{
				position: sticky;
				top:50%;
				margin-left:w(697);
				transform:translate(0,-50%);
				li{
					width:w(8);
					height:w(8);
					opacity: 0.2;
					background:#000;
					display: block;
					margin-bottom: w(20);
					border-radius: 50%;
					img{
						opacity: 0;
						position: relative;
						top: w(-1);
						left:w(-2);
						width:w(12);
						//height: w(10);
					}
					&.-current{
						opacity: 1;
						img{
							opacity: 1;
						}
					}
				}
			}
		}
		.about-mind-1{
			height:100vh;
			display: flex;
			align-items:center;
			.about-mind-content{
				h1{
					img{
						width: w(220);
					}
					font-size: w(92);
					line-height: 0.5;
				}
			}
		}
		.about-mind-2{
			height:100vh;
			display: flex;
			align-items:center;
			.about-mind-content{
				h1{
					img{
						width: w(274);
					}
					font-size: w(92);
					line-height: 0.5;
				}
			}
			
		}
		.about-mind-3{
			height:100vh;
			display: flex;
			align-items:center;
			.about-mind-content{
				h1{
					img{
						width: w(240);
					}
					font-size: w(92);
					line-height: 0.5;
				}
			}
			
		}
		.about-mind-4{
			height:100vh;
			display: flex;
			align-items:center;
			.about-mind-content{
				h1{
					img{
						width: w(243);
					}
					font-size: w(92);
					line-height: 0.5;
				}
			}
			
		}
	}
	@media #{$mq-sp} {
		width:sw(358);
		margin:0 auto;
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		//height:400vh;
		.about-mind-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-left: sw(10);
			margin-bottom: sw(100);
		}
		.about-mind-content{
			width:sw(358);
			margin-left: sw(20);
			h1{
				margin-bottom: sw(14);
				
				position: relative;
				span.-no{
					opacity: 0.3;
					position: absolute;
					top:sw(-16);
					left: sw(-16);
					font-size: sw(10);
				}
				span{
					margin-bottom: sw(-16);
				}
			}
			h4{
				font-size: sw(24);
				line-height: 2;
				margin-bottom: sw(5);
			}
			p{
				font-size: sw(12);
				line-height: 2;
				width: sw(300);
			}
		}
		position: relative;
		#stage{
			position: absolute;
			height: calc(100%);
			top:0;
			//margin-top:20vw;

			canvas{
				position: sticky;
				top:100vw;
			}

			ul{
				position: sticky;
				top:50%;
				margin-left:sw(340);
				transform:translate(0,-50%);
				margin-bottom: sw(200);
				li{
					width:sw(8);
					height:sw(8);
					opacity: 0.2;
					background:#000;
					display: block;
					margin-bottom: sw(20);
					border-radius: 50%;
					img{
						opacity: 0;
						position: relative;
						top: sw(-1);
						left:sw(-2);
						width:sw(12);
						//height: sw(10);
					}
					&.-current{
						opacity: 1;
						img{
							opacity: 1;
						}
					}
				}
			}
		}
		.about-mind-container-data{
			position: relative;
			z-index: 1;
		}
		.about-mind-1{
			height:100vh;
			display: flex;
			//align-items:center;
			.about-mind-content{
				h1{
					img{
						width: sw(129);
					}
					font-size: sw(54);
					line-height: 0.9;
				}
				h4{
					font-size: sw(12);
					line-height: 2;
				}
			}
		}
		.about-mind-2{
			height:100vh;
			display: flex;
			//align-items:center;
			.about-mind-content{
				h1{
					font-size: sw(54);
					line-height: 0.9;
					img{
						width: sw(269);
					}
				}
			}
			
		}
		.about-mind-3{
			height:100vh;
			display: flex;
			//align-items:center;
			.about-mind-content{
				h1{
					font-size: sw(54);
					line-height: 0.9;
					img{
						width: sw(256);
					}
				}
			}
			
		}
		.about-mind-4{
			height:100vh;
			display: flex;
			//align-items:center;
			.about-mind-content{
				h1{
					font-size: sw(54);
					line-height: 0.9;
					img{
						width: sw(247);
					}
				}
			}
			
		}
	}
}

.about-service{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto;
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.about-service-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(20);
		}
		.about-service-head{
			display: flex;
			margin-bottom: w(76);
			h1{
				width:w(686);
				img{
					width: w(382);
				}

				font-size: w(106);
				line-height: 0.8;
			}
			p{
				font-size: w(15);
				line-height: 2;
				width:w(579);
			}
		}
		.about-service-list{
			display: flex;
			justify-content:space-between;
			margin-bottom: w(276);
			.about-service-card{
				display: flex;
				flex-flow: column;
				background: #000;
				border-radius: w(30);
				width:w(452);
				height: w(668);
				padding: w(28);
				color:#fff;
				h6{
					text-align: right;
					font-size: w(16);
					margin-bottom: w(78);
					opacity: 0.8;
					transition:all 0.1s linear;
				}
				h2{
					img{
						height: w(110);
					}

					font-size: w(56);
					line-height: 1;

					margin-bottom: w(10);
					opacity: 0.8;
					transition:all 0.1s linear;
				}
				h5{
					font-size: w(14);
					line-height: 1.4;
					flex-grow: 1;
					opacity: 0.8;
					transition:all 0.1s linear;
				}
				p{
					font-size: w(14);
					line-height: 2;
					margin-bottom: w(32);
					opacity: 0.8;
					transition:all 0.1s linear;
				}
				div{
					border-top:1px solid rgba(255,255,255,0.8);
					padding-top: w(10);
					text-align: right;
					display: flex;
					justify-content:right;
					align-items:center;
					font-size: w(16);
					&:after{
						content:'';
						width:w(8);
						height:w(8);
						border-radius: 50%;
						background: #fff;
						display: block;
						margin-left: w(14);
					}
					opacity: 0.8;
					transition:all 0.1s linear;
				}
						cursor: pointer;
				&:hover{
					*{
						opacity: 1;
					}
				}
			}
		}
	}
	@media #{$mq-sp} {
		width:sw(358);
		margin:0 auto;
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		.about-service-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-left: sw(10);
			margin-bottom: sw(20);
		}
		.about-service-head{
			//display: flex;
			//margin-bottom: w(80);
			h1{
				width:sw(252);
				img{
					width: sw(252);
				}

				font-size: sw(66);
				line-height: 0.8;
				margin-bottom: sw(12);

			}
			p{
				font-size: sw(12);
				line-height: sw(22);
				width:sw(332);
				margin-bottom: sw(30);
			}
		}
		.about-service-list{
			//display: flex;
			//justify-content:space-between;
			margin-bottom: sw(137);
			.about-service-card{
				display: flex;
				flex-flow: column;
				background: #000;
				border-radius: sw(30);
				width:sw(358);
				//height: sw(340);
				padding: sw(25) sw(20);
				margin-bottom: sw(16);
				color:#fff;
				h6{
					text-align: right;
					font-size: sw(11);
					margin-bottom: sw(57);
					opacity: 0.8;
				}
				h2{
					img{
						height: sw(77);
					}
					font-size: sw(46);
					line-height: 0.9;
					margin-bottom: sw(15);
					opacity: 0.8;
					span{
						margin-bottom: sw(-10);
					}
				}
				h5{
					font-size: sw(12);
					line-height: 1.4;
					margin-bottom: sw(20);
					opacity: 0.8;
				}
				p.about-service-card-text{
					font-size: sw(11);
					line-height: sw(21);
					margin-bottom: sw(13);
					opacity: 0.8;
					display: none;
				}

				p.about-service-card-btn{
					border-top:1px solid rgba(255,255,255,1);
					text-align: center;
					font-size: sw(14);
					line-height: sw(21);
					padding-top: sw(9);
					margin-bottom: sw(11);
					opacity: 0.8;
					display: flex;
					align-items:center;
					justify-content:center;
					img{
						margin-right: sw(8);
						width: sw(8);
					}
				}

				div{
					border-top:1px solid rgba(255,255,255,1);
					opacity: 0.8;
					padding-top: sw(14);
					text-align: right;
					display: flex;
					justify-content:right;
					align-items:center;
					font-size: sw(14);
					&:after{
						content:'';
						width:sw(8);
						height:sw(8);
						border-radius: 50%;
						background: #fff;
						display: block;
						margin-left: sw(8);
					}
				}
			}
		}
	}
}
.about-project{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto w(291);
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.about-project-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(20);
		}
		.about-project-head{
			display: flex;
			h1{
				width:w(686);
				img{
					width: w(369);
				}

				font-size: w(106);
				line-height: 0.8;
			}
			p{
				font-size: w(15);
				line-height: 2;
				width:w(579);
			}
			margin-bottom: w(62);
		}
		.about-project-card{
			display: flex;
			align-items:center;
			background: #fff;
			border-radius: w(30);
			overflow: hidden;
			margin-bottom: w(30);
			position: relative;
			border-radius: w(30);
			overflow: hidden;
			&:after{
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				content:'';
				transition:0.2s ease-out all;
				border:w(0) solid #000;
				border-radius: w(30);
			}
			&:hover{
				&:after{
					border:w(4) solid #B1AAA2;
				}
				.about-project-card-img img{
					transform:scale(1.05);
				}
			}
			.about-project-card-img{
				overflow: hidden;
				img{
					transition:0.2s all ease-out;
					//transform:scale(1.05);
					width: w(640);
				}
			}
			.about-project-card-data{
				margin-left: w(48);
				h2{
					img{
						height: w(32);
					}
					font-size: w(42);
					
					margin-bottom: w(5);
				}
				.about-project-card-data-url{
					opacity: 0.6;
					font-size: w(16);
					line-height: 1.4;
					margin-bottom: w(25);
				}
				.about-project-card-data-text{
					font-size: w(14);
					line-height: w(26);
					width: w(579);
				}
			}
		}
	}
	@media #{$mq-sp} {
		width:sw(358);
		margin:0 auto;
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		.about-project-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-left: sw(10);
			margin-bottom: sw(20);
		}
		.about-project-head{
			//display: flex;
			//margin-bottom: w(80);
			margin-left: sw(10);
			h1{
				width:sw(252);
				img{
					width: sw(252);
				}
				margin-bottom: sw(10);
					font-size: sw(66);
					line-height: 0.8;
			}
			p{
				font-size: sw(12);
				line-height: sw(22);
				width:sw(332);
				margin-bottom: sw(30);
			}
		}
		.about-project-card{
			display: flex;
			    flex-direction: column;
			align-items:center;
			background: #fff;
			border-radius: sw(20);
			padding-bottom: sw(20);
			overflow: hidden;
			margin-bottom: sw(16);
			.about-project-card-img{
				img{
					width: sw(358);
				}
				margin-bottom: sw(25);
			}
			.about-project-card-data{
				//margin-left: sw(48);
				h2{
					font-size: sw(42);
					img{
						height: sw(32);
					}
					margin-bottom: sw(15);
				}
				.about-project-card-data-url{
					opacity: 0.6;
					font-size: sw(16);
					line-height: 1.4;
					margin-bottom: sw(25);
				}
				.about-project-card-data-text{
					font-size: sw(12);
					line-height: sw(22);
					width: sw(322);
				}
			}
		}
	}
}
.about-group{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto;
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.about-group-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(20);
		}

		.about-group-head{
			display: flex;
			h1{
				img{
					width: w(854);
				}

				font-size: w(106);
				line-height: 0.8;
			}
			margin-bottom: w(55);
		}
		.about-group-list{
			display: flex;
			margin-bottom: w(276);
			.about-group-card{
				width: w(290);
				height: w(378);
				border:1px solid #CBCBCB;
				border-radius: w(30);
				text-align: center;
				display: flex;
				    flex-flow: column;
				.about-group-card-img{
					img{
						width: w(195);
					}
					flex-grow: 1;
					display: flex;
					align-items:center;
					justify-content:center;
				}
				.about-group-card-data{

					.about-group-card-data-name{
						font-size: w(18);
						margin-bottom: w(20);
					}	
					.about-group-card-data-more{
						font-size: w(16);
						display: flex;
						align-items:center;
						justify-content:center;
						width: w(254);
						margin:0 auto;
						height: w(44);
						&:after{
							content:'';
							width:w(8);
							height:w(8);
							border-radius: 50%;
							background: #000;
							display: block;
							margin-left: w(16);
							position: relative;
							top:w(2);

						}
						border-top:1px solid #CBCBCB;
					}

				}
			}
		}
	}
	@media #{$mq-sp} {
		width:sw(358);
		margin:0 auto;
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		.about-group-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-left: sw(10);
			margin-bottom: sw(20);
		}

		.about-group-head{
			display: flex;
			margin-left: sw(10);
			h1{
				img{
					width: sw(322);
				}
				font-size: sw(66);
				line-height: 0.2;
				span{
					margin-bottom: sw(-5);
				}
			}
			margin-bottom: sw(50);
		}
		.about-group-list{
			display: flex;
			margin-bottom: sw(113);
			.about-group-card{
				width: sw(175);
				height: sw(233);
				border:1px solid #CBCBCB;
				border-radius: sw(20);
				text-align: center;
				display: flex;
				    flex-flow: column;
				.about-group-card-img{
					img{
						width: sw(118);
					}
					flex-grow: 1;
					display: flex;
					align-items:center;
					justify-content:center;
				}
				.about-group-card-data{
					.about-group-card-data-name{
						font-size: sw(11);
						margin-bottom: sw(15);
					}	
					.about-group-card-data-more{
						font-size: sw(10);
						display: flex;
						align-items:center;
						justify-content:center;
						width: sw(153);
						margin:0 auto;
						height: sw(28);
						&:after{
							content:'';
							width:sw(5);
							height:sw(5);
							border-radius: 50%;
							background: #000;
							display: block;
							margin-left: sw(14);
						}
						border-top:1px solid #CBCBCB;
					}

				}
			}
		}
	}
}
.about-member{
	@media #{$mq-pc} {
		width:w(1360);
		margin:0 auto w(240);
		padding-top: w(20);
		border-top:1px solid #CBCBCB;
		.about-member-ttl{
			display: flex;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
			margin-bottom: w(20);
		}

		.about-member-head{
			display: flex;
			h1{
				img{
					width: w(432);
				}
				font-size: w(106);
				line-height: 0.8;
			}
			margin-bottom: w(40);
		}
		.about-member-list{
			display: flex;
			flex-wrap:wrap;
			gap: w(24) w(16);
			.about-member-card{
				position: relative;
				//filter:grayscale(100%);
				cursor: pointer;
				transition:all 0.2s linear;

				border-radius: w(30);
			overflow: hidden;
			&:before{
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				content:'';
				transition:0.2s ease-out all;
				border:w(0) solid #B1AAA2;
				border-radius: w(30);
				background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
				z-index: 1;

			}

				&:hover{
					&:before{
					border:w(4) solid #B1AAA2;
					}
					//filter:grayscale(0%);
					.about-member-card-img img{
						transform:scale(1.06);
					}
				}
				.about-member-card-img{
					height: w(513);
					width:w(328);
					border-radius: w(30);
					overflow: hidden;
					img{
						height: w(513);
						position: relative;
						left: w(-100);
						transition:0.3s ease-out all;
						transform:scale(1.02);
					}
				}
				.about-member-card-data{
					position: absolute;
					bottom:w(25);
					margin:0 w(25);
					color:#fff;
					h4{
						font-size: w(14);
						margin-bottom: w(6);
					}
					p{
						font-size: w(20);
					}
					z-index: 1;
				}
				&:after{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #fff;
					display: block;
					margin-left: sw(14);
					position: absolute;
					right:w(25);
					bottom:w(25);
				}
			}
		}
		.about-member-transform{
			height: w(513);
			width:w(328);
			border-radius: w(30);
			overflow: hidden;
			transition:0.4s ease-in width,0.4s ease-in height;
			position: absolute;
			opacity: 0;
			z-index: 3;
			pointer-events:none;

			transform:translate(-50%,-50%);

			img{
				width:w(528);
				border-radius: w(30);
				overflow: hidden;
				transition:0.4s ease-out width,0.4s ease-out height,0.4s ease-out left;
				height: w(513);
				position: relative;
				//left: w(-100);
				left:50%;
				transform:translateX(-50%);
			}
			&.-show{
				border-radius: w(30);
		        overflow: hidden;
		        width: w(738);
		        height: w(716);

		        img {
		            width: w(738);
		            height: w(716);
		            //left: 0;
		        }
			}
		}
	}
	@media #{$mq-sp} {
		width:sw(358);
		overflow-x: hidden;
		overflow-y:hidden;
		margin:0 auto sw(200);
		padding-top: sw(20);
		border-top:1px solid #CBCBCB;
		.about-member-ttl{
			display: flex;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
			margin-left: sw(10);
			margin-bottom: sw(20);
		}

		.about-member-head{
			display: flex;
			margin-left: sw(10);
			h1{
				img{
					
					width: sw(274);

					//height: w(513);
					position: relative;
					//left: w(-100);
				}
					font-size: sw(66);
					line-height: 0.9;
			}
			margin-bottom: sw(40);
		}
		.about-member-list{
			display: flex;
			flex-wrap:wrap;
			gap: sw(14) sw(8);
			.about-member-card{
				position: relative;
				.about-member-card-img{
					width:sw(175);
					height: sw(270);
					border-radius: sw(20);
					overflow: hidden;
					img{
						height: sw(270);
						position: relative;
						left:sw(-50);
					}
				}
				.about-member-card-data{
					position: absolute;
					bottom:sw(10);
					margin:0 sw(10);
					color:#fff;
					h4{
						font-size: sw(11);
						margin-bottom: sw(4);
					}
					p{
						font-size: sw(13);
					}
					z-index: 1;
				}
				&:before{
					position: absolute;
					top:0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					content:'';
					transition:0.2s ease-out all;
					border:w(0) solid #000;
					border-radius: w(30);
					background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
					z-index: 1;

				}
				&:after{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #fff;
					display: block;
					margin-left: sw(14);
					position: absolute;
					right:sw(13);
					bottom:sw(13);
				}
			}
		}

		.about-member-transform{
			width:sw(175);
			height: sw(270);
			border-radius: sw(20);
			overflow: hidden;
			transition:0.4s 0.1s ease-in width,0.4s 0.1s ease-in height;
			position: absolute;
			opacity: 0;
			z-index: 3;
			pointer-events:none;
			transform:translate(-50%,-50%);
			img{
				width:sw(277);
				border-radius: sw(20);
				overflow: hidden;
				transition:0.4s ease-out width,0.4s ease-out height,0.4s ease-out left;
				height: sw(270);
				position: relative;
				//left: sw(-50);


				left:50%;
				transform:translateX(-50%);
			}
			&.-show{
				border-radius: w(30);
		        overflow: hidden;
		        width: sw(358);
		        height: sw(348);

		        img {
		            width: sw(358);
		            height: sw(348);
		            //left: 0;
		        }
			}
		}
	}
}

/*
<div class="">
			<div class="about-member-card-img">
				<img src="/assets/img/member1.png">
			</div>
			<div class="about-member-card-data">
				<h4>Architect / Designer</h4>
				<p class="about-member-card-data-more"><span>Yusuke Daimon</span></p>
			</div>
		</div>
*/