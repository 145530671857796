
html{
	background: #F7F2EC;
}

body{
	@include font-smoothing()
	line-height:1;
	-webkit-text-size-adjust: 100%;
	font-size:16px;
	font-family: $font;
	font-weight: bold;
}
main{
	//transition:0.1s linear all;
	position: relative;
    width: 100%;
    //overflow-x: hidden;
}
*,*::before,*::after {
	box-sizing: border-box;
}
.will-change{
	will-change: transform;
}
a{
	text-decoration: none;
	color:inherit;
}

.-font{
	font-family: 'Inter', 'Noto Sans JP',sans-serif;
}

.-ttl{
	font-family: 'myfont', sans-serif; 
}

@font-face {
  font-family: 'myfont';
  src: url('../font/font.woff') format('woff'),
       url('../font/font.otf') format('truetype');
}

.prlx{
    transform:translate(0,2vw);
    opacity:0;
    transition:all 0.6s cubic-bezier(.23, 1, .32, 1),opacity 0.2s linear;
    &.footer-content{
    	transition:all 0.8s 0.6s cubic-bezier(.23, 1, .32, 1),opacity 0.1s 0.6s linear;
    	transform:translate(0,10vh);
    }
    &.header{
    	transition:all 0.6s cubic-bezier(.23, 1, .32, 1),opacity 0.6s linear;
    	transform:none !important;
    	opacity: 0;
    }
    &.-show{
        transform:translate(0,0);
        opacity:1;
    }
}
#container{
	&:after{
		content:'';
		display: block;
		width: 100%;
		height: 20vw;
		background: #F7F2EC;
		position: absolute;
		bottom:0vw;
		left: 0;
		z-index: -1;
	}
}
.prlxf{
	display: inline-block;
	overflow-x: visible;
	overflow-y: hidden;
	line-height: 1;
	span{
		opacity: 1;
		display: inline-block;
		transform:translateY(100%);
		transition:all 0.6s cubic-bezier(.23, 1, .32, 1);
	}
    //transition:all 0.6s cubic-bezier(.23, 1, .32, 1),opacity 0.2s linear;
    &.-show{
        span{
        	opacity: 1;
			transform:translateX(-0.2vw);
		}
    }
}

/*
LANGUAGE or DEVICE
*/
.lang-jp [data-lang="en"]{
	display: none!important;
}
.lang-en [data-lang="jp"]{
	display: none!important;
}

@media #{$mq-pc} {
	[data-device="sp"]{
		display: none!important;
	}
}
@media #{$mq-sp} {
	[data-device="pc"]{
		display: none!important;
	}
}