.works-top{
	@media #{$mq-pc} {
		padding-top: w(184);
		text-align: center;
		padding-bottom: w(93);
		h1{
			img{
				width: w(511);
			}
			font-size: w(166);
			line-height: 0.8;
			margin-bottom: w(-24);
		}
		p{
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: w(16);
			&:before{
				content:'';
				width:w(8);
				height:w(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: w(19);

			}
		}
	}
	@media #{$mq-sp} {
		padding-top: sw(200);
		text-align: center;
		padding-bottom: sw(50);
		h1{
			img{
				width: sw(253);
			}
			margin-bottom: sw(-10);
			font-size: sw(82);
			line-height: 0.8;
		}
		p{
			display: flex;
			justify-content:center;
			align-items:center;
			font-size: sw(10);
			&:before{
				content:'';
				width:sw(8);
				height:sw(8);
				border-radius: 50%;
				background: #000;
				display: block;
				margin-right: sw(14);

			}
		}
	}
}

.works{
	@media #{$mq-pc} {
		padding-top: w(20);
		margin:0 auto;
		width: w(1360);
		border-top:1px solid #CBCBCB;
		padding-bottom: w(180);
		.works-header{
			display: flex;
		}
		.works-category{
			padding-top: w(20);
			display: flex;
			li{
				font-size: w(16);
				line-height: w(38);
				border-radius: w(19);
				background:#D9D9D9;
				margin-right: w(8);
				color:#fff;
				padding:0 w(17);
				&.-current{
					background:#000;
				}
			}
			margin-bottom: w(16);
		}
		.works-tags{
			display: flex;
			flex-wrap:wrap;
			li{
				font-size: w(16);
				line-height: w(28);
				color:#B3B3B3;
				&:after{
					content:'/';
					display: inline-block;
					margin: 0 w(6);
				}
				&:last-child:after{
					display: none;
				}
				
				&.-current{
					color:#000;
					text-decoration: underline;
				}
			}
		}
		.works-data{
			width:w(675);

			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:w(8);
					height:w(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: w(19);

				}
				font-size: w(16);
				margin-bottom: w(22);
			}
			h3{
				font-size: w(106);
			}
			margin-bottom: w(30);
		}
		.works-content{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content:space-between;
			.works-large{
				width: 100%;
				img{
					width: 100%;
					border-radius: w(30);
					overflow: hidden;
					transition:all 0.4s ease-out;
				}
				margin-bottom: w(48);
				&:hover{
					.works-img{
						&:after{
							
					border:w(4) solid #B1AAA2;
						}
						img{
							transform:scale(1.05);
						}
					}
				}
				.works-img{
					position: relative;
					border-radius: w(30);
					overflow: hidden;
					&:after{
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
						content:'';
						transition:0.2s ease-out all;
						border:w(0) solid #000;
						border-radius: w(30);
					}
				}
			}
			.works-small{
				width: w(672);
				img{
					width: w(672);
					border-radius: w(30);
					overflow: hidden;
					transition:all 0.4s ease-out;
				}
				&:hover{
					.works-img{
						&:after{
							
					border:w(4) solid #B1AAA2;
						}
						img{
							transform:scale(1.05);
						}
					}
				}
				.works-img{
					position: relative;
					border-radius: w(30);
					overflow: hidden;
					&:after{
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block;
						content:'';
						transition:0.2s ease-out all;
						border:w(0) solid #000;
						border-radius: w(30);
					}
				}
				margin-bottom: w(48);
			}
			.works-info{
				padding-top:w(24);
				padding-left: w(10);
				.works-info-head{
					display: flex;
					.works-info-num{
						font-size:w(14);
						color:#999;
						margin-right: w(18);
					}
					.works-info-ttl{
						font-size:w(22);
						color:#000;
						font-weight: bold;
					}
					margin-bottom: w(18);
				}
				.works-info-data{
					display: flex;
						align-items:center;

					.works-info-category{
						font-size:w(14);
						color:#999;
					}
					.works-info-year{
						font-size:w(14);
						color:#999;
						display: flex;
						align-items:center;
						&:before{
							content:'';
							width:w(8);
							height:w(8);
							border-radius: 50%;
							background: #999;
							display: block;
							margin-left: w(14);
							margin-right: w(14);

						}
					}
				}
			}
		}
		.works-btn{
			border:1px solid rgba(0,0,0,0.3);
			text-align: center;
			line-height: w(128);
			border-radius: w(30);
			transition:all 0.2s ease-out;
			&:hover{
				color:#fff;
				background:rgba(0,0,0,1);
			}
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				font-size: w(28);
				height:w(128);
				img{
					width: w(69);
				}
			}
		}
	}
	@media #{$mq-sp} {
		padding-top: sw(20);
		margin:0 auto;
		width: sw(358);
		border-top:1px solid #CBCBCB;
		//padding-bottom: sw(116);
		padding-bottom: sw(160);
		.works-header{
			//display: flex;
		}
		.works-category{
			padding-top: sw(6);
			display: flex;
			flex-wrap:wrap;
			li{
				font-size: sw(14);
				line-height: sw(33);
				border-radius: sw(16.5);
				background:#D9D9D9;
				margin-right: sw(8);
				color:#fff;
				padding:0 sw(15);
				margin-bottom: sw(10);

				&.-current{
					background:#000;
				}
			}
			margin-bottom: sw(12);
		}
		.works-tags{
			display: flex;
			flex-wrap:wrap;
			li{
				font-size: sw(13);
				line-height: sw(28);
				color:#B3B3B3;
				&:after{
					content:'/';
					display: inline-block;
					margin: 0 sw(6);
				}
				&:last-child:after{
					display: none;
				}
				&.-current{
					color:#000;
					text-decoration: underline;
				}
			}
			margin-bottom: sw(70);
		}
		.works-data{
			width:sw(358);
			h2{
				display: flex;
				align-items:center;
				&:before{
					content:'';
					width:sw(8);
					height:sw(8);
					border-radius: 50%;
					background: #000;
					display: block;
					margin-right: sw(14);

				}
				font-size: sw(10);
				margin-bottom: sw(22);
			}
			h3{
				img{
					width: sw(84);
				}
				font-size: sw(48);
			}
			margin-bottom: sw(35);
		}
		.works-content{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content:space-between;
			.works-large{
				width: sw(358);
				img{
					width: sw(358);
					border-radius: sw(20);
					overflow: hidden;
				}
				margin-bottom: sw(43);
			}
			.works-small{
				width: sw(358);
				img{
					width: sw(358);
					border-radius: sw(20);
					overflow: hidden;
				}
				margin-bottom: sw(43);
			}
			.works-info{
				padding-top:sw(16);
				padding-left: sw(10);
				.works-info-head{
					display: flex;
					.works-info-num{
						font-size:sw(14);
						color:#999;
						margin-right: sw(18);
					}
					.works-info-ttl{
						font-size:sw(16);
						color:#000;
						font-weight: bold;
					}
					margin-bottom: sw(12);
				}
				.works-info-data{
					display: flex;
						align-items:center;

					.works-info-category{
						font-size:sw(11);
						color:#999;
					}
					.works-info-year{
						font-size:sw(11);
						color:#999;
						display: flex;
						align-items:center;
						&:before{
							content:'';
							width:sw(6);
							height:sw(6);
							border-radius: 50%;
							background: #999;
							display: block;
							margin-left: sw(11);
							margin-right: sw(11);

						}
					}
				}
			}
		}
		.works-btn{
			border:1px solid rgba(0,0,0,0.3);
			text-align: center;
			line-height: sw(66);
			border-radius: sw(20);
			p{
				display: flex;
				align-items:center;
				justify-content:center;
				height:sw(66);
				font-size:sw(16);
			
			}
		}
	}
}
