
.menu-layer{
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 0%;
	background: #000;
	z-index: 101;
	transition:cubic-bezier(0.23, 1, 0.32, 1) 0.4s all;
	&.-on{
		height: 100%;
	}
}
.menu{
	@media #{$mq-pc} {
		position: fixed;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		z-index: 102;
		opacity: 0;
		transition:ease-out 0.4s all;
		pointer-events:none;
		transform:translateY(1vh);
		filter:blur(1vh);
		&.-on{
			opacity: 1;
			transition:ease-out 0.4s 0.2s all;
			transform:translateY(0vh);
			filter:blur(0vh);
			pointer-events:auto;
		}
		.menu-header{
			position: absolute;
			top:0;
			width: 100%;
			display: flex;
			justify-content:right;

			padding-top: w(32);
			padding-left: w(40);
			padding-right: w(40);
			.menu-header-menubtn{
				width: w(108);
				height:w(108);
				border-radius: 50%;
				border:1px solid #F7F2EC;
				text-align: center;
				line-height: w(108);
				color:#F7F2EC;
				position: relative;
				span{
					font-size: w(14);
					font-weight: bold;
				}
				cursor: pointer;
				transition:0.1s linear all;
				
				svg{
					width: 100%;
					height: 100%;
					transform:scale(1.06) rotate(-90deg);
			          position: absolute;
			          top: 0;
			          left: 0;
				}
		        circle {
		          fill: transparent;
		          stroke: rgba(255,255,255,1);
		          stroke-width: 1;
		          stroke-dasharray: 0 366;
		        }
		        
				transition:0.1s linear all;
				&:hover{
					circle{
						animation: circle 2s 1 forwards;
					}
				}
			}
		}
		.menu-content{
			width: w(1060);
			//height:w(500);
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			display: flex;
			line-height: 0.8;
			a{
				color:#F7F2EC;
			}
			color:#F7F2EC;
			.menu-content-left{
				width: w(660);
			}
			.menu-content-right{
				width: w(400);

			    display: flex;
			    flex-flow: column;
			}
			/*
			.menu-content-ttl{
				font-size:w(106);
				font-weight: bold;
			}*/
			.menu-content-ttl-about{
				img{
					width: w(310);
				}
				opacity: 0.5;

				transition:0.1s linear all;
				&:hover{
					opacity: 1;
				}

				&.-current{
					opacity: 1;
					&:before{
					content:'';
					width:w(8);
					height: w(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:w(-30);
					top:w(3);
				}
				position: relative;
				}
				
			}
			.menu-content-ttl-works{
				img{
					width: w(326);
				}
				opacity: 0.5;
				transition:0.1s linear all;
				&:hover{
					opacity: 1;
				}
				&.-current{
					opacity: 1;
					position: relative;
				&:before{
					content:'';
					width:w(8);
					height: w(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:w(-30);
					top:w(3);
				}
				}
				
			}
			.menu-content-topics{
				padding-top: w(45);
				li{
					padding-bottom: w(20);
					a{
						font-size:w(23);
						font-weight: bold;
					}
					opacity: 0.5;

					transition:0.1s linear all;
					&:hover{
						opacity: 1;
					}
				}
				padding-bottom: w(60);
			}
			.menu-content-logo{
				width:w(112);
				height: w(118);
				//margin-bottom: w(134);
				flex-grow:1;
				a{
					opacity: 0.5;
					transition:0.1s linear all;
					&:hover{
						opacity: 1;
					}
				}
			}
			.menu-content-contact{
				font-weight: bold;
				font-size:w(16);
				padding-left: w(30);
				position: relative;
				&:before{
					content:'';
					width:w(8);
					height: w(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:0;
					top:w(3);
				}
				margin-bottom: w(25);
				opacity: 0.3;
			}
			.menu-content-mail{
				img{
					width: w(400);
				}

				font-size: w(51);
				font-weight: normal;
				opacity: 0.6;

				transition:0.1s linear all;
				&:hover{
					opacity: 1;
				}
				margin-bottom: w(30);
			}
			.menu-content-address{
				font-size: w(14);
				font-weight: bold;
				opacity: 0.3;
				white-space: nowrap;
				line-height: 1.4;
				letter-spacing: w(-0.4);
				margin-bottom: w(24);


			}
			.menu-content-sns{
				display: flex;
				margin-bottom: w(35);
				.menu-content-sns-ttl{
					font-size: w(14);
					font-weight: bold;
					opacity: 0.3;
					margin-right: w(23);
				}
				.menu-content-sns-list{
				font-size: w(14);
				font-weight: bold;
					display: flex;
					li{
						a{
							opacity: 0.5;
							transition:0.1s linear all;
							&:hover{
								opacity: 1;
							}
						}
						margin-right: w(10);
					}
				}
			}
			.menu-content-copy{
				font-size: w(14);
				font-weight: bold;
				opacity: 0.3;
			}
		}
	}
	@media #{$mq-sp} {
		position: fixed;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		overflow: auto;
		//display: none;
		z-index: 102;

		opacity: 0;
		transition:ease-out 0.4s all;
		pointer-events:none;
		transform:translateY(1vh);
		filter:blur(1vh);
		&.-on{
			opacity: 1;
			transition:ease-out 0.4s 0.2s all;
			transform:translateY(0vh);
			filter:blur(0vh);
			pointer-events:auto;
		}

		.menu-header{
			position: absolute;
			top:0;
			width: 100%;
			display: flex;
			justify-content:right;

			padding-top: sw(27);
			padding-left: sw(17);
			padding-right: sw(17);
			.menu-header-menubtn{
				width: sw(88);
				height:sw(88);
				border-radius: 50%;
				border:1px solid #F7F2EC;
				text-align: center;
				line-height: sw(88);
				color:#F7F2EC;
				span{
					font-size: sw(11);
					font-weight: bold;
				}
			}
		}
		.menu-content{
			//position: absolute;
			margin-top: sw(183);
			line-height: 0.8;
			a{
				color:#F7F2EC;
			}
			color:#F7F2EC;
			/*
			.menu-content-left{
				width: w(660);
			}
			.menu-content-right{
				width: w(400);
			}*/
			.menu-content-ttl-about{
				img{
					width: sw(257);
				}
				margin-left: sw(40);

				opacity: 0.5;
				&.-current{
					opacity: 1;
					position: relative;
				&:before{
					content:'';
					width:sw(8);
					height: sw(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:sw(-20);
					top:sw(3);
				}
				}
			}
			.menu-content-ttl-works{
				img{
					width: sw(270);
				}
				margin-left: sw(40);
				margin-bottom: sw(100);

				opacity: 0.5;
				&.-current{
					opacity: 1;
					position: relative;
				&:before{
					content:'';
					width:sw(8);
					height: sw(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:sw(-20);
					top:sw(3);
				}
				}

				
			}
			.menu-content-topics{
				padding-top: sw(40);
				li{
					padding-bottom: sw(18);
					opacity: 0.5;
					a{
						font-size:sw(18.84);
						font-weight: bold;
					}
				}
				margin-left: sw(57);
				padding-bottom: sw(60);
			}
			.menu-content-logo{
					width: sw(88);
					height:sw(91);
				img{
					width: sw(88);
					height:sw(91);
				}
				opacity: 0.5;
				position: absolute;
				top:sw(27);
				left: sw(17);
			}
			.menu-content-contact{
				font-weight: bold;
				font-size:sw(16);
				margin-left: sw(22);
				padding-left: sw(20);
				position: relative;
				&:before{
					content:'';
					width:sw(8);
					height: sw(8);
					border-radius: 50%;
					background:#F7F2EC;
					display: inline-block;
					position: absolute;
					left:0;
					top:sw(3);
				}
				margin-bottom: sw(20);
				opacity: 0.3;
			}
			.menu-content-mail{
				margin-left: sw(22);
				img{
					width: sw(337);
				}

				font-size: sw(51);
				font-weight: normal;
				opacity: 0.6;

				margin-bottom: sw(50);
			}
			.menu-content-address{
				color:#D9D9D9;
				margin-left: sw(22);
				font-size: sw(14);
				font-weight: bold;
				display: inline-block;
				opacity: 0.5;
				white-space: nowrap;
				line-height: 1.4;
				//letter-spacing: sw(-1.4);
				margin-bottom: sw(40);
			}
			.menu-content-sns{
				display: flex;
				margin-bottom: sw(15);
				margin-left: sw(22);
				.menu-content-sns-ttl{
					font-size: sw(16);
					font-weight: bold;
				color:#D9D9D9;
					opacity: 0.5;
					margin-right: sw(30);
				}
				.menu-content-sns-list{
					font-size: sw(16);
					font-weight: bold;
					display: flex;
					width:sw(240);
					justify-content:space-between;
					li{
						opacity: 0.3;
						margin-right: w(10);
					}
				}
			}
			.menu-content-copy{
				font-size: sw(14);
				margin-left: sw(22);
				font-weight: bold;
				opacity: 0.3;
				padding-bottom: sw(50);
			}
		}
	}
}
