
.loader{
	@media #{$mq-pc} {
		
		position: fixed;
		top:0;
		left: 0;
		width:100%;
		height: 100%;
		z-index: 9999;
		background:#F7F2EC;
		pointer-events:auto;
		opacity: 1;
		transition:all 0.4s 0.8s ease-out;
		display: flex;
		justify-content:center;
		align-items:center;
		&.-hide{
			opacity: 0;
				pointer-events:none;
		}
		text-align: center;
		.loader-content{
			position: relative;
			width: s(108);
			height: s(108);
			img{
				width:s(18);
				height: s(15);
				margin-bottom: s(20);
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				//animation: heartbeat 1.5s ease-in-out infinite both;
			}
			svg{
				width: s(108);
				height: s(108);
				transform:scale(1.06) rotate(-90deg);
		        position: absolute;
		        top: 0;
		        left: 0;
			}
	        circle.-progress {
	          fill: transparent;
	          stroke: rgba(0,0,0,1);
	          stroke-width: 1;
	          stroke-dasharray: 0 366;
	          transition:0.6s ease-out all;
	          &.-end{
	          	stroke-dasharray: 366 366;
	          }
	        }
	        circle.-base {
	          fill: transparent;
	          stroke: rgba(0,0,0,0.3);
	          stroke-width: 1;
	          stroke-dasharray: 366 366;
	        }
		}
		
			p{
				margin-top: s(20);
				font-size:s(20);
			}
		.loader-content-2{
			display: flex;
			gap:s(28);
			img{
				width:s(18);
				height: s(15);
				opacity: 0.1;
				&.-end{
					&:nth-child(1){
						transition:all 0.2s 0.0s ease-out;
					}
					&:nth-child(2){
						transition:all 0.2s 0.2s ease-out;
					}
					&:nth-child(3){
						transition:all 0.2s 0.4s ease-out;
					}

		          	opacity: 1;
		        }
			}
		}
	}
	@media #{$mq-sp} {
		position: fixed;
		top:0;
		left: 0;
		width:100%;
		height: 100%;
		z-index: 9999;
		background:#F7F2EC;
		pointer-events:auto;
		opacity: 1;
		transition:all 0.4s 1.6s ease-out;
		display: flex;
		justify-content:center;
		align-items:center;
		&.-hide{
			opacity: 0;
				pointer-events:none;
		}
		text-align: center;
		.loader-content{
			position: relative;
			width: sw(108);
			height: sw(108);
			img{
				width:sw(18);
				height: sw(15);
				margin-bottom: sw(20);
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				//animation: heartbeat 1.5s ease-in-out infinite both;
			}
			svg{
				width: sw(108);
				height: sw(108);
				transform:scale(1.06) rotate(-90deg);
		        position: absolute;
		        top: 0;
		        left: 0;
			}
	        circle.-progress {
	          fill: transparent;
	          stroke: rgba(0,0,0,1);
	          stroke-width: 1;
	          stroke-dasharray: 0 366;
	          transition:0.6s ease-out all;
	          &.-end{
	          	stroke-dasharray: 366 366;
	          }
	        }
	        circle.-base {
	          fill: transparent;
	          stroke: rgba(0,0,0,0.3);
	          stroke-width: 1;
	          stroke-dasharray: 366 366;
	        }
			
		}
		p{
				margin-top: sw(20);
				font-size:sw(20);
			}
		.loader-content-2{
			display: flex;
			gap:sw(28);
			img{
				width:sw(18);
				height: sw(15);
				opacity: 0.1;
				&.-end{
					&:nth-child(1){
						transition:all 0.2s 0.0s ease-out;
					}
					&:nth-child(2){
						transition:all 0.2s 0.2s ease-out;
					}
					&:nth-child(3){
						transition:all 0.2s 0.4s ease-out;
					}

		          	opacity: 1;
		        }
			}
		}
	}
}
