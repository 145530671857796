
.header{
	@media #{$mq-pc} {
		top:0;
		width: 100%;
		display: flex;
		justify-content:space-between;
		.header-ttl{
			position: absolute;
			left:w(40);
			top: w(32);
			img{
				width: w(104);
				height:w(108);
			}
			pointer-events:auto;
			z-index: 2;
			&:hover{
				animation: simplebeat 1.0s ease-in-out 1 both;
			}
		}
		.header-menubtn{
			position: fixed;
			z-index: 11;
			right:w(40);
			top: w(32);
			width: w(108);
			height:w(108);
			border-radius: 50%;
			border:1px solid rgba(255,255,255,.3);
			mix-blend-mode: difference;
			color:#fff;
			text-align: center;
			line-height: w(108);
			cursor: pointer;
			display: flex;
			align-items:center;
			justify-content:center;
			font-size: w(14);
			img{
				width:w(39)
			}

			svg{
				width: 100%;
				height: 100%;
				transform:scale(1.06) rotate(-90deg);
		          position: absolute;
		          top: 0;
		          left: 0;
			}
	        circle {
	          fill: transparent;
	          stroke: rgba(255,255,255,1);
	          stroke-width: 1;
	          stroke-dasharray: 0 366;
	          transition:0.2s ease-out all;
	        }
	        
			transition:0.1s linear all;
			&:hover{
				circle{
					animation: circle 1s 1 forwards;
				}
			}
			
			pointer-events:auto;
		}
	}
	@media #{$mq-sp} {
		z-index: 11;
		position: absolute;
		top:0;
		width: 100%;
		display: flex;
		justify-content:space-between;
		padding-top: sw(27);
		padding-left: sw(17);
		padding-right: sw(17);
		.header-ttl{

		position: absolute;
		top: sw(27);
		left: sw(17);
			img{
				width: sw(88);
				height:sw(91);
			}
			z-index: 2;
		}
		.header-menubtn{

		position: fixed;
		top: sw(27);
		right: sw(17);
			width: sw(88);
			height:sw(88);
			border-radius: 50%;
			border:1px solid #000;
			text-align: center;
			line-height: sw(88);
			cursor: pointer;
			display: flex;
			align-items:center;
			justify-content:center;
			font-size: sw(11);
			&:hover{
				opacity: 0.6;
			}
		}
	}
}
